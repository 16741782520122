import React from "react";
import ReactDOM from "react-dom";
// import "./all.css"
import "./styles.css";
import _words from "./words";
import axios from 'axios';

Array.prototype.randomElement = function () {
    return this[Math.floor(Math.random() * this.length)];
};

class Card extends React.Component {
    state = {};

    showTranslate = () => {
        this.setState({showTranslate: true});
    };

    confirm = () => {
        this.setState({showTranslate: false}, () => {
            this.props.update(this.props.word[0]);
        });
    };

    ignore = () => {
        this.setState({showTranslate: false}, () => {
            this.props.update();
        });
    };

    render() {
        const word = this.props.word;
        if(!(Array.isArray(word) && word.length > 0)){
            return 'אין מידע'
        }
        return (
            <React.Fragment>
                <span className="leftWords">{this.props.totalWords} / <b>2046</b></span>
                <span className="trophy">{2046-this.props.totalWords}<i className="fas fa-trophy"/></span>
                <h1>{word[1]}</h1>
                {!this.state.showTranslate ? (
                    <i className="fa fa-book" onClick={this.showTranslate}/>
                ) : (
                    <React.Fragment>
                        <div className="mb10">{word[2]}</div>
                        <i className="fa fa-thumbs-up" onClick={this.confirm}/>
                        <a
                            target="_blank"
                            href={`https://www.google.com/search?q=${encodeURI(
                                word[1]
                            )}&btnI=`}
                        >
                            <i className="fa fa-question"/>
                        </a>

                        <i className="fa fa-thumbs-down" onClick={this.ignore}/>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}


const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));


const getWords = async () => {
    let words = null;
    let count = 0;
    while(words === null && count < 100){
        try{
            let res = await axios.get('https://pimusicgroup.com/lomda');
            words = res.data.map(elem=>elem.data)
        }catch(err){
            count++;
            await timeout(500);
            console.log("retry")
        }
    }

    return words

};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _words: [],
            currentWord: []
            // currentWord: [].randomElement()
        };

        this.confirmOrIgnore = this.confirmOrIgnore.bind(this);
    }

    componentDidMount(){
        getWords().then(words=> {
            this.setState({
                _words: words,
                currentWord: words.randomElement()
            })
        })
    }


    getRandomWord() {
        return this.state._words.randomElement();
    }

    confirmOrIgnore(id) {
        if (!id) {
            let _random = this.state._words.randomElement();

            this.setState({
                currentWord: _random
            });
        } else {
            let _newArray = this.state._words.filter(elem => elem[0] !== id);
            this.setState({
                currentWord: _newArray.randomElement(),
                _words: _newArray
            });
            axios.get(`https://pimusicgroup.com/lomda/${id}`).then(res=>{
                console.log("id", id, 'removed');
            })
            // need to remove
        }
    }

    render() {
        return (
            <div className="App">
                <Card word={this.state.currentWord} update={this.confirmOrIgnore}
                      totalWords={this.state._words.length}/>
            </div>
        );
    }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);
