var words = [
  ["10452", "אָבְנַיִים", "מתקן מסתובב להכנת כלי חרס", ""],
  ["10454", "אוֹצֵר", "שומר על משהו יקר ערך", ""],
  ["10453", "אוֹצֵר", "הממונה על המוצגים במוזיאון", ""],
  ["10455", "אָחוּ", "כר דשא, שדה מרעה", ""],
  ["10456", "אִיחוּי", "חיבור חלקים, הכלבה", ""],
  ["10457", "אִלוּלֵא", "אם לא, אלמלא, לולי, לולא", ""],
  [
    "10459",
    "אֲלוּמָּה",
    "גלים המשודרים בטווח צר, קרן (אלומת אור - קרן אור)",
    ""
  ],
  ["10458", "אֲלוּמָּה", "חבילת שיבולים קצורות", ""],
  ["10460", "אֲלוּמָּת אוֹר", "קרן אור", ""],
  ["10461", "אָמוֹדַאי", "צוללן", ""],
  ["10462", "אָמִיר", "צמרת, הענפים העליונים בעץ", ""],
  ["10463", "אֲמַתְלָה", "תרוץ, סיבה לא אמיתית", ""],
  ["10464", "אָסָם", "מחסן תבואה", ""],
  ["10465", "אֶצְבָּעוֹן", "כיסוי המהווה הגנה לאצבע בשעת תפירה", ""],
  ["10466", "אֶקְרָן", "מסך, מִרְקע", ""],
  ["10467", "אָרִיג", "בד", ""],
  ["10468", "אַרְכִיב / אַרְכִיּוֹן", "מקום אחסון למסמכים", ""],
  ["10469", "אֲרָעִי", "זמני", ""],
  [
    "10470",
    "אֶשְׁכּוֹל",
    "מספר פירות או פרחים המחוברים יחדיו לאותו הגבעול",
    ""
  ],
  ["10471", "אֶשְׁכּוֹל", "מספר דברים קטנים המחוברים יחד, חבילה", ""],
  ["10472", "בֵּאוּר", "הסבר של נושא לא מובן או ההסבר עצמו, פרשנות", ""],
  ["10473", "בְּגִלּוּפִין", "במצב של שכרות קלה, 'בראש טוב'", ""],
  ["10474", "בְּגַפּוֹ", "לבדו, ללא בן זוג", ""],
  ["10475", "בּוּרְסְקִי / בּוּרְסְקַאי / בּוּרְסִי", "מעבד עורות", ""],
  ["10476", "בּוֹשׁ", "התבייש", ""],
  ["10477", "בּוֹשֵׁשׁ", "התמהמה בעשיית דבר מה (בושש לבוא - איחר, התעכב)", ""],
  ["10478", "בִּיּוּת", "אילוף, הפיכת דבר פראי לביתי", ""],
  ["10479", "בִּיּוּת", "הנחיית פצצה או טיל אל עבר מטרה", ""],
  ["10481", "בֵּית בַּד", "מקום בו מייצרים שמן מזיתים", ""],
  ["10482", "בֵּית מַרְזֵחַ", "בר, פאב, מסבאה", ""],
  ["10483", "בַּלָּן", "עובד בבית המרחץ", ""],
  ["10484", "בְּמַפְגִּיעַ", "באופן נמרץ, באסרטיביות, בהחלטיות, בפסקנות", ""],
  ["10485", "בְּמַפְגִּיעַ", "בהתרסה, באופן בוטה", ""],
  ["10486", "בַּעֲלִיל", "באופן ברור, ללא ספק", ""],
  ["10487", "בְּפוּמְבִּי / בְּפוּמְבֵּי", "באופן גלוי ולעיני כל", ""],
  ["10488", "בָּצִיר", "קטיף ענבים", ""],
  ["10489", "בָּצַר", "קטף ענבים", ""],
  ["10480", "בֵּרָא", "כָּרַת, עקר עצים", ""],
  ["10490", "גָּדָה", "שפת הנהר, חוף", ""],
  [
    "10491",
    "גָּדָה",
    "קצה כלי, שפת הכלי (מלא עד גדותיו - מלא עד הסוף, עלה על מידותיו)",
    ""
  ],
  ["10492", "גָהַר", "התכופף, השתופף", ""],
  ["10493", "גּוּר", "הִזָּהֵר", ""],
  ["10494", "גְזוּזְטְרָה", "מרפסת חיצונית (ללא גג)", ""],
  ["10495", "גְּזִיר", "חתיכה שנגזרה ממשהו", ""],
  ["10496", "גִּלְעֵן", "הוציא את גלעין הפרי", ""],
  ["10497", "גַּרְגְרָן", "אכלן, זללן, להוט אחר אוכל", ""],
  ["10498", "גַּת", "מקום המשמש לדריכת ענבים והכנת יין", ""],
  ["10499", "דָּגָה", "שם כולל לכל הדגים למיניהם", ""],
  ["10500", "דּוֹבֵב", "גרם למישהו אחר לדבר", ""],
  [
    "10501",
    "דִּידַקְטִי",
    "שקשור בתחום תורת ההוראה (דידקטיקה - תורת ההוראה)",
    ""
  ],
  ["10502", "דִּרְבּוּן", "עידוד לפעולה מסוימת, המרצה", ""],
  ["10504", "הִבְלִיג", "נמנע מלבטא רגש כלשהו", ""],
  ["10503", "הִבְלִיג", "ריסן את עצמו, התאפק", ""],
  ["10505", "הִגֵּר", "עבר מארץ אחת לאחרת והשתקע בה לפרק זמן ממושך", ""],
  ["10506", "הֲדוֹם", "שרפרף נמוך, ספסל נמוך שהיושב מניח עליו את רגליו", ""],
  ["10507", "הֶדְיוֹט ", "נטול ידיעות בתחום מסוים", ""],
  [
    "10508",
    "הִטְמִיעַ",
    "ספג, הפנים, גרם למשהו להפוך חלק בלתי נפרד ממשהו אחד",
    ""
  ],
  ["10509", "הִיסָּה", "השתיק, השקיט, החריש", ""],
  ["10510", "הִכְבִּיר בְּמִילִים", "דבר רבות באריכות יתרה, האריך בדברים", ""],
  ["10511", "הִכְחִישׁ", "טען שמשהו אינו נכון, שלל", ""],
  ["10512", "הַס", "מילת ציווי הדורשת שקט", ""],
  ["10513", "הִסְכִּית", "שתק והקשיב בריכוז, האזין, הטה אוזן", ""],
  ["10514", "הִסְלִים", "החריף, הקצין, הידרדר", ""],
  ["10515", "הִסְמִיךְ", "  קֵרֵב, גרם להם להיות סמוכים (קרובים / קשורים)", ""],
  ["10516", "הִסְמִיךְ", "הפך משהו לפחות נוזלי", ""],
  ["10517", "הִסְמִיךְ", "נתן לו סמכות, אישר לו לבצע משהו, ייפה את כוחו", ""],
  [
    "10518",
    "הֶעֱלָה אֲרוּכָה",
    "הגליד (ארוכה = גלד, הרקמה הנוצרת תוך כדי תהליך הריפוי של הפצע)",
    ""
  ],
  ["10519", "הִפְלִיא בּוֹ אֶת מַכּוֹתָיו", "הִכָּה אותו", ""],
  [
    "10520",
    "הִפְלִיג בְּ-",
    "הִרְבָּה, הפריז, הגזים, עשה משהו בשפע (הפליג בשבחו - שיבח אותו מאוד)",
    ""
  ],
  ["10521", "הִצְפִּין", "העלים, החביא", ""],
  ["10522", "הִצְפִּין", "נע צפונה", ""],
  ["10523", "הֵקִיץ", "התעורר משנתו, נֵעוֹר", ""],
  ["10524", "הִשְׁחִיז", "ליטש וחידד את הלהב", ""],
  ["10525", "הִשְׁתָּאוּת", "פליאה", ""],
  ["10526", "הִתְבַּשֵּׂר", "קיבל או שמע בשורה (בדרך כלל בשורה טובה)", ""],
  ["10527", "הִתְגּוֹדֵד", "התקהל, התאסף", ""],
  ["10528", "הִתְחַלָּה", "העמיד פני חולה, התחזה לחולה", ""],
  ["10529", "הִתְחַסֵּד", "העמיד פני צדיק", ""],
  ["10531", "הִתִּיר", "שחרר (התיר את הקשר - פתח את הקשר)", ""],
  ["10530", "הִתִּיר", "אִפְשֵר, הסכים, אישר (מהמילה מותר)", ""],
  ["10532", "הִתְכַּתֵּשׁ", "נאבק", ""],
  ["10533", "הִתְמוֹגֵג", "התענג, נהנה, רווה נחת", ""],
  ["10534", "הִתַּמֵּר", "התרומם כלפי מעלה, התנשא", ""],
  ["10535", "הִתְנַבֵּא", "השמיע ניבוי או חיזוי כלשהו", ""],
  [
    "10536",
    "הִתְנַכֵּר",
    "העמיד פנים כאילו אין לו קשר אליו, התעלם ממנו במכוון",
    ""
  ],
  ["10537", "הִתְנַצֵּחַ", "התווכח", ""],
  ["10538", "הִתְעַמֵּר", "התעלל, התאכזר", ""],
  ["10539", "הִתְעַרְטֵל", "פשט את בגדיו", ""],
  ["10540", "הִתְרָה", "הזהיר", ""],
  ["10541", "הִתְרַצָּה", "נענה לבקשה לאחר שכנוע רב, הסכים", ""],
  ["10542", "זַבָּן", "מוכר בחנות", ""],
  ["10543", "זֶרֶד", "ענף", ""],
  [
    "10544",
    "זָרָה מֶלַח עַל הַפְּצָעִים",
    "הוסיף על צערו, דרך לו על היבלות, נגע בעצב רגיש (זרה - פיזר)",
    ""
  ],
  ["10545", "חַס", "ריחם, מחל, חנן", ""],
  ["10546", "חֲרִי (אַף)", "כעס, חֵמה, זעם", ""],
  ["10547", "חָרִישׁ", "ביתור האדמה במחרשה כדי שתהיה נוחה לזריעה", ""],
  ["10548", "חָרִישׁ", "העונה שחורשים ומפלחים בה את האדמה", ""],
  ["10549", "חֲרִישִׁי", "שָׁקֵט", ""],
  ["10550", "חָרָךְ ", "חריץ או חור בקיר", ""],
  ["10551", "חָרָךְ ", "שרף קלות באש, צרב", ""],
  ["10553", "טָוָה", "חיבר, צירף", ""],
  ["10552", "טָוָה", "שָׁזַר, יצר חוטים מחומר גולמי", ""],
  ["10554", "טְלַאי", "חתיכת בד שתופרים על בגד לצורך תיקון קרע", ""],
  ["10555", "טְרָשִׁים", "אדמה סלעית שקשה לעבד", ""],
  ["10556", "יָגֵעַ", "עייף מרוב עבודה, תשוש", ""],
  [
    "10557",
    "יָד רוֹחֶצֶת יָד",
    "אחד עוזר לשני, שמור לי ואשמור לך (בדרך כלל בהקשר שלילי)",
    ""
  ],
  ["10558", "יְקִיצָה", "התעוררות משינה, חוסר שינה, ערנות", ""],
  ["10559", "יַרְכָתַיִים", "החלק האחורי של הספינה", ""],
  ["10560", "כְּבַד לָשׁוֹן / פֶּה", "מגמגם, מתקשה בדיבור", ""],
  ["10561", "כִּיחֵד", "הסתיר, העלים, לא גילה את כל האמת, שיקר", ""],
  ["10562", "כִּילָּה ", "סיים, גמר", ""],
  ["10563", "כִּילָּה ", "השמיד, חיסל", ""],
  [
    "10564",
    "כִּילָּה ",
    "רשת שפורשים מעל למיטה להגן על השוכבים בה מיתושים וזבובים",
    ""
  ],
  ["10565", "כַּן / כַּנָּה", "בסיס שעליו מעמידים דבר כלשהו", ""],
  ["10566", "כְּסָיָה", "כפפה", ""],
  ["10567", "כְּרַךְ", "עיר גדולה", ""],
  ["10568", "לֵאוּת", "יגעות, עייפות, תשישות", ""],
  ["10569", "לַהַג", "פטפוט, ריבוי דברים בלא טעם", ""],
  ["10570", "לַהֲגָן", "פטפטן, שמדבר בלי סוף", ""],
  ["10571", "לוּלֵא", "אִם לֹא", ""],
  ["10572", "לִיבָּה", "הגדיל את הלהבה", ""],
  ["10574", "לִיבָּה", "החלק הפנימי של הפרי בו מרוכזים הזרעים", ""],
  ["10573", "לִיבָּה", "החלק המרכזי והחשוב ביותר במשהו, גרעין", ""],
  ["10575", "לִיבָּה", "הגביר, הסעיר", ""],
  ["10576", "לִיטֵּשׁ", "נתן מראה חלק או מבריק באמצעות שפשוף/החלקה", ""],
  ["10577", "לִיטֵּשׁ", "שיפר, עיבד, עשה 'פִינִיש'", ""],
  ["10578", "לִמְגִינַּת לִבּוֹ", "לצערו, למורת רוחו", ""],
  ["10579", "מַאֲכֶלֶת", "סכין לשחיטה, חַלָף", ""],
  ["10580", "מֵאֵן", "לא הסכים, התנגד, סרב", ""],
  ["10581", "מְבוּסָם", "שיכור קלות, שתוי", ""],
  ["10582", "מַגָּל", "כלי עבודה לקצירת תבואה (עשוי מסכין בצורת חצי ירח)", ""],
  ["10583", "מָהוּהַּ", "בלוי, מרופט, שחוק", ""],
  ["10584", "מַזְמֵרָה", "כלי גינה המשמש לקיצוץ הענפים", ""],
  [
    "10585",
    "מִכְמוֹרֶת",
    "רשת גדולה המשמשת בעיקר לדייג, מצודה (רשת דייגים)",
    ""
  ],
  ["10586", "מֵלִיץ יוֹשֶר", "סנגור, מי שטוען לזכותו של מישהו", ""],
  ["10587", "מָסִיק", "קטיף זיתים, עונת קטיף הזיתים", ""],
  ["10588", "מִסְעָד", "תמיכה, משענת", ""],
  ["10589", "מַפַּח נֶפֶשׁ", "אכזבה גדולה", ""],
  ["10590", "מִצְנֶפֶת", "כובע בד, מגבעת", ""],
  [
    "10591",
    "מִרְבָּץ",
    "רובד קרקע שמצויים בו מחצבים (מרבץ יהלומים - אזור בו יש יהלומים רבים לכרייה)",
    ""
  ],
  ["10592", "מְשׁוּנָּן", "בעל שיניים או בעל צורה של שיניים", ""],
  [
    "10593",
    "מְתַאֲבֵן",
    "מאכל המוגש בתחילת הארוחה על-מנת להגביר את התאבון",
    ""
  ],
  ["10594", "נִגַף", "הובס, נוצח", ""],
  ["10595", "נֶגֶף", "תקלה, מכשול (אֶבֶן נֶגֶף - מכשול)", ""],
  ["10596", "נֶגֶף", "מחלה קשה המפילה חללים רבים", ""],
  ["10597", "נָדָן", "נרתיק החרב", ""],
  ["10598", "נָהִיר", "ברור, מובן", ""],
  ["10599", "נֶהֶנְתָן ", "רודף תענוגות", ""],
  ["10600", "נוֹטֵר", "שומר, מאבטח", ""],
  ["10601", "נוֹטֵר טִינָה", "לא סולח, שומר כעס", ""],
  ["10602", "נוּל", "מכונת אריגה", ""],
  ["10603", "נְחִיל", "להקת חרקים", ""],
  ["10608", "נִחַם", "התחרט על מה שעשה, הצטער", ""],
  ["10604", "נֶחָמָה פּוּרְתָּא", "נחמה כלשהי, נחמה מועטה", ""],
  ["10605", "נַחְשׁוֹל", "גל גדול", ""],
  ["10606", "נִטְמַע", "נקלט והפך לחלק מקבוצה מסוימת, נבלע", ""],
  ["10607", "נִיחָא", "מילא, אין דבר", ""],
  ["10609", "נִיפָּה", "סינן, ניקה, ברר", ""],
  ["10610", "נִכּוּשׁ", "עקירת עשבים שוטים", ""],
  [
    "10611",
    "נִכְלָם",
    "מבוייש, בוש (בּוֹשׁ וְנִכְלָם - הִתְבַּיֵּשׁ מאוד), כלימה = בושה",
    ""
  ],
  ["10612", "נֶעֱתַר", "הסכים, השיב בחיוב", ""],
  ["10613", "נִפְעָם", "נרגש", ""],
  ["10614", "נִקְוָוה", "זרם והצטבר במקום כלשהו", ""],
  ["10615", "נִרְגָּן", "רוטן ומתלונן כל עת", ""],
  ["10616", "נִרְפֶּה", "עצלן, רשלן", ""],
  ["10617", "סַוָּר", "סבל בנמל", ""],
  ["10618", "סוֹרֵר", "מרדן, סרבן, חסר משמעת", ""],
  ["10619", "סַיָּס", "עובד המטפל בסוסים", ""],
  ["10620", "סִיקֵּל", "פינה אבנים, ניקה את השטח מאבנים", ""],
  ["10621", "סָנֵגוֹר", "עורך דין המגן על הנאשם", ""],
  ["10622", "סִתֵּת", "הקציע והחליק אבנים", ""],
  ["10623", "סַתָת", "מחליק ומהקצע אבנים, בעל מקצוע שמסתת אבנים", ""],
  ["10624", "עִילֵג", "מדבר בצורה לא ברורה, כבד דיבור", ""],
  ["10625", "עַלְוָוה", "כלל העלים המכסים את העץ", ""],
  ["10626", "עָמַד מִנֶּגֶד / מִן הַצַּד", "לא התערב", ""],
  [
    "10627",
    "עָרֵב",
    "מי שמתחייב לפרוע חוב של אחר והיה והאחר לא יעמוד בהתחייבויותיו",
    ""
  ],
  ["10628", "עָרֵב", "נעים לשמיעה (נאמר גם על אוכל: ערב לחך - טעים)", ""],
  [
    "10629",
    "פִּיר",
    "חוֹר, בוֹר (פיר המעלית - ה'שרוול' בו עוברת המעלית)",
    ""
  ],
  ["10630", "פָּלָה", "הוציא כינים (מהשיער, מהפרווה וכדומה)", ""],
  ["10631", "פָּמַלְיָה", "בני-לוויה", ""],
  ["10633", "פֶּרֶא", "חמור הבר", ""],
  ["10632", "פֶּרֶא", "חסר תרבות", ""],
  ["10635", "צִדֵד", "הביט מן הצד, הביט מזווית בעין", ""],
  ["10634", "צִדֵד", "תמך ב-, דגל ב-", ""],
  ["10636", "צִדֵד", "הפנה דבר מה הצידה, הִטה", ""],
  ["10637", "קוֹרֶט / קַרְטוֹב", "מעט, כמות קטנה", ""],
  ["10638", "קוּשִיָה", "שאלה, בעיה, חידה", ""],
  ["10639", "קוֹשֵׁשׁ", "אסף עצים או קש למדורה", ""],
  ["10640", "קִיטוֹן", "חדר קטן, חדר שינה", ""],
  ["10641", "קָמַל", "נבל (להיפך: לבלב)", ""],
  ["10642", "קְרִיסָה", "התמוטטות, הרס", ""],
  ["10643", "קְרִישָׁה", "התעבות של נוזל והפיכתו לסמיך וקרוש", ""],
  ["10644", "קָשַׁר (לוֹ) כְּתָרִים", "שיבח אותו מאוד", ""],
  ["10645", "קַת", "החלק האחורי של הנשק שבא במגע עם הכתף, ידית", ""],
  ["10646", "רָדָה", "אסף דבש מהכוורת", ""],
  ["10647", "רָדָה", "שלט ביד רמה, משל", ""],
  ["10648", "רָוָוה", "שתה עד שלא הרגיש צמא יותר ", ""],
  ["10649", "רָקִיק", "מאפה שטוח ודק, עוגיה שטוחה", ""],
  ["10650", "רֶשֶף", "הבזק אש, גחלת, ניצוץ", ""],
  [
    "10651",
    "רָשַׁף",
    "התיז רשפים או ניצוצות. בהשאלה: כעס, נמלא חימה ותוכחה",
    ""
  ],
  ["10652", "שָׁאוֹן", "קול, המולה, רעש", ""],
  ["10653", "שְׁאַט נֶפֶשׁ", "תחושת סלידה (שאט - בוז)", ""],
  ["10654", "שׁוֹקֵק (חַיִּים)", "מלא אנשים, פעילות מרובה", ""],
  ["10655", "שִׁיסָה", "גרם למישהו לתקוף אחר, הסית", ""],
  ["10656", "שָׁכִיחַ", "נפוץ, מצוי (להיפך: נדיר)", ""],
  ["10657", "שָׁכַך", "נרגע, שקט, דמם", ""],
  ["10658", "שָׁלָה", "הוציא מתוך נוזל", ""],
  ["10659", "שַׁמַּאי", "מי שתפקידו להעריך את שווי הנכס", ""],
  ["10660", "תֶּלֶם", "החריץ שנוצר בעת חרישת האדמה", ""],

  ["10661", "אֶדֶן", "המדף בתחתית החלון", ""],
  ["10662", "אֲדָנִית", "עציץ מוארך המונח בדרך-כלל על אדן החלון", ""],
  ["10663", "אִסְפְּלָנִית", "תחבושת, פלסטר, אגד מדבק", ""],
  ["10664", "אֵפוֹא", "אם כך, ובכן", ""],
  ["10665", "אֶצְעָדָה", "צמיד לרגל או ליד", ""],
  [
    "10666",
    "אֲרוּכָה",
    "גֶּלֶד, שכבת הבשר הבריא העולה על פצע מבריא (העלה ארוכה, הגליד)",
    ""
  ],
  ["10667", "אֶרֶך אַפַּיִים", "אדם סבלני שאינו ממהר לכעוס", ""],
  ["10668", "אֵרַע", "קרה", ""],
  ["10669", "אַשְׁפָּה", "נרתיק לחיצים", ""],
  ["10670", "בְּדִימוֹס", "שהשתחרר או פרש מעבודתו או תפקידו", ""],
  ["10671", "בּוֹסֶר", "פרי לא בשל", ""],
  ["10672", "בּוֹסֶר", "דבר שעדיין אינו בשל, אינו מוכן", ""],
  ["10673", "בּוֹצֵר", "מי שעובד בבציר (קטיף ענבים)", ""],
  ["10674", "בִּיוֹגְרַפְיָה", "תאור תולדותיו של אדם מסוים", ""],
  ["10676", "בַּלָאט", "בסתר, בחדרי חדרים, בהחבא", ""],
  ["10675", "בַּלָאט", "בשקט, בחשאי", ""],
  [
    "10677",
    "בְּעֶטְיוֹ",
    "בעצתו (הרעה), בשל מזימתו, באשמתו, (עטי = עצה רעה)",
    ""
  ],
  ["10678", "בָּצִיר", "הענבים עצמם", ""],
  ["10679", "בְּרֹאשׁ חוּצוֹת", "בפומבי, בפרהסיה", ""],
  ["10680", "בָּרִי", "ברור, ודאי, ידוע", ""],
  ["10681", "בְּרֵישׁ גְּלֵי", "לעיני כל", ""],
  ["10682", "בַּר-מִינָן", "מת, מנוח", ""],
  ["10683", "בָּרַר", "בחר את מה שמתאים ביותר, סינן", ""],
  ["10684", "גָּדַשׁ", "מִילא עד אפס מקום", ""],
  [
    "10685",
    "גָּדַש אֶת הַסְּאָה",
    "הגזים, הפריז, עבר את הגבול (סאה - מידת נפח עתיקה)",
    ""
  ],
  ["10686", "גֵּז", "פעולת הגזיזה של הצמר", ""],
  ["10687", "גֵּז", "צמר שנגזז מהצאן", ""],
  ["10688", "גָּז", "נעלם, נמוג, חלף", ""],
  [
    "10689",
    "גְּזֵרָה",
    "פקודה או חוק של איסור כלשהו, פסק דין, תקנה, הוראה",
    ""
  ],
  ["10690", "גַּיְא", "שקע צר בין הרים", ""],
  ["10691", "גִּיל / גִּילָה", "שמחה, ששון, אושר", ""],
  ["10692", "גַּלְעִין", "גרעין הפרי, ליבה, חרצן", ""],
  ["10693", "גְּמִילָה", "השתחררות מהרגל מזיק", ""],
  ["10694", "גְּמִילָה", "הבשלת הפרי", ""],
  ["10695", "גַּנְזַך", "ארכיב, ארכיון, מקום לאכסון מסמכים (לרוב ממשלתי)", ""],
  ["10696", "גַּפַּיִם", "שם כולל לרגלים והידיים של בני אדם ובעלי חיים", ""],
  ["10697", "גָּרַע", "החסיר", ""],
  [
    "10698",
    "דָּלָה",
    "שאב, הוציא מהמים (דָּלָה פנינים). בהשאלה: הוציא משהו לאחר חיפוש (לדלות מידע)",
    ""
  ],
  ["10700", "דָלַק", "בער", ""],
  ["10699", "דָלַק", "רדף (אחרי מישהו)", ""],
  ["10702", "דָּרוּך", "מתוח, משוך", ""],
  ["10701", "דָּרוּך", "מוכן לפעולה, ערני, קשוב", ""],
  ["10703", "הֶאֱמִיר", "עלה, התייקר", ""],
  ["10704", "הִבְלִיח", "הבהב באור חלש ובלתי יציב, נצנץ", ""],
  [
    "10705",
    "הִגְדִּישׁ אֶת הַסְּאָה",
    "הגזים, הפריז, עבר את הגבול (סאה - מידת נפח עתיקה)",
    ""
  ],
  ["10706", "הֵגִיף", "סגר, נעל, הבריח", ""],
  ["10707", "הִדְבִּיר", "ריסס נגד מזיקים", ""],
  ["10708", "הִדִּיר רַגְלָיו", "נמנע מלבקר, הגיע לעתים רחוקות", ""],
  ["10710", "הִדִּיר שֵׁינָה מֵעֵינָיו", "לא נרדם", ""],
  ["10709", "הִדִּיר שֵׁינָה מֵעֵינָיו", "גרם לדאגה", ""],
  ["10711", "הֵהִין", "העז", ""],
  [
    "10712",
    "הוֹכִיחַ",
    "הראה ראיות לנכונותו ואמיתותו של דבר מה, אימת, נזף",
    ""
  ],
  ["10713", "הוֹכִיחַ", "הטיף לו מוסר", ""],
  ["10714", "הוֹקִיר רַגְלָיו", "נמנע מלבקר, הגיע לעתים רחוקות", ""],
  ["10715", "הִזְדַּכְּכוּת", "הפיכה לנקי וצלול, התנקות", ""],
  [
    "10716",
    "הִזְדַּכְּכוּת",
    "השתחררות מחטאים או רגשות שליליים, זיכוך הנפש",
    ""
  ],
  ["10717", "הִטְלִיא", "תיקן קרע בבגד על ידי טלאי", ""],
  ["10718", "הִטְמִיעַ", "החדיר מערכת חדשה (למשל מערכת מחשב) לארגון כלשהו", ""],
  ["10719", "הִכְמִין", "הניח במקום מסתור, החביא, הסתיר", ""],
  ["10720", "הָלַם", "הכה, הקיש, דפק", ""],
  ["10721", "הֵפִיס דַעֲתוֹ", "ריצה או הרגיע אותו, השכין שלום", ""],
  ["10722", "הִשְׁכִּין", "נתן מקום מגורים, יישב, אִכלס", ""],
  ["10723", "הִשְׁכִּין", "השליט, השרה (אווירה, שלום וכו')", ""],
  ["10724", "הִשְׁתּוֹקֵק", "רצה מאוד", ""],
  ["10725", "הִתְחַבֵּט", "התלבט", ""],
  ["10726", "הִתְחַסֵּד", "נהג ברחמנות", ""],
  [
    "10727",
    "הִתְמַקֵּחַ",
    "ניהל משא ומתן על מחירו של דבר או על תנאים בעסקה, בחוזה וכדומה",
    ""
  ],
  ["10728", "הִתְעַרְטֵל", "חשף את רגשותיו הכמוסים", ""],
  ["10729", "הִתְפַּעֵם", "התרגש מרוב פליאה, החסיר פעימה, התרשם", ""],
  ["10730", "הִתְקַהֵל", "התאסף, התקבץ לקהל", ""],
  ["10731", "זְמוֹרָה", "ענף, זרד", ""],
  [
    "10732",
    "זָמַר",
    "קצץ ענפים (מַזְמֵרָה - מספרים מיוחדים לקיצוץ ענפים, זְמוֹרָה - ענף של גפן)",
    ""
  ],
  ["10733", "חַבּוּט", "מוכה, נחבט", ""],
  [
    "10734",
    "חַבּוּט",
    "מועב, שחבטו בו. בהשאלה: נדוש, נושא שדנו בו רבות, חסר חידוש",
    ""
  ],
  ["10736", "חִלְחוּל", "הסתננות", ""],
  ["10735", "חִלְחוּל", "חדירה של מים וירידתם למעמקים", ""],
  [
    "10737",
    "חמשיר",
    "שיר היתולי, עוקצני וקצר המורכב מחמש שורות (חמש ושיר)",
    ""
  ],
  ["10738", "חָרִיט", "ארנק", ""],
  [
    "10739",
    "חָרַץ לָשׁוֹן",
    "התחצף, חירף וגידף, השמיע דברים קשים (גם הוציא לשון)",
    ""
  ],
  [
    "10740",
    "חָתָה",
    "גרף, אסף והעביר (לרוב גחלים) (מַחְתָּה - כלי לטיפול בגחלים)",
    ""
  ],
  ["10741", "טָבַע", "חקק צורה כלשהי בתוך חומר", ""],
  ["10742", "טָבַע", "יצר מטבע", ""],
  ["10743", "יָרַד לְסוֹף דַעְתּוֹ", "הבין את כוונתו בדיוק", ""],
  ["10744", "יֵשׁ בְּלִבּוֹ עָלָיו", "לא סלח", ""],
  ["10745", "כַּבִּיר", "גדול, עצום, רב, אדיר", ""],
  [
    "10751",
    "כֶּבֶשׁ",
    "מישור משופע (משמש לחיבור בין מקומות בעלי גבהים שונים, כבש המטוס, כבש הספינה וכד')",
    ""
  ],
  ["10746", "כָּבַשׁ", "שימור של מזון", ""],
  ["10747", "כָּבַשׁ", "התאפקות, הבלגה", ""],
  ["10748", "כָּבַשׁ", "לחיצה, דחיסה, הידוק", ""],
  ["10749", "כָּבַשׁ", "הקסים", ""],
  ["10750", "כָּבַשׁ", "סלל דרך", ""],
  ["10752", "כִּבְשָׁן", "תנור להתכת מתכות וחימום חומרים שונים, כּוּר", ""],
  ["10753", "כַּחַש", "שֶקֶר, כַּזָב", ""],
  ["10754", "כִּיבֵּד", "טאטא, ניקה", ""],
  ["10755", "כֵּרָה", "סעודה, משתה, ארוחה חגיגית", ""],
  ["10756", "כָּרַה", "הוציא מחצבים מהקרקע, חפר, חצב", ""],
  ["10757", "כִּתְמוֹל שִׁלְשוֹם", "כמו תמיד, כמו שהיה עד עכשיו, כרגיל", ""],
  [
    "10758",
    "לֹא שְׁזָפַתּוּ עַיִן",
    "לא נראה על ידי איש, היה חבוי ומוסתר מעיני כול",
    ""
  ],
  ["10759", "לִבֵּן", "חמם מתכת באש חזקה (עד שצבעה הפך לבן)", ""],
  [
    "10760",
    "לִבֵּן",
    "בירר סוגיה לעומק, דן בפרטים עד שהבין והגיע למסקנה / לפתרון",
    ""
  ],
  ["10761", "לַהַק", "קבוצת מטוסים", ""],
  ["10762", "לַהַק", "קבוצה של בעלי חיים, להקה", ""],
  ["10763", "לָקוֹנִי", " קצר, תמציתי, ענייני", ""],
  ["10764", "מִאוּס", "דוחה, מגעיל, בזוי", ""],
  ["10765", "מוּמָר", "מי שהמיר את דתו", ""],
  ["10766", "מוּסַר כְּלָיוֹת", "רגשי אשם, חרטה", ""],
  ["10767", "מחה", "השמיד, השכיח, מחק", ""],
  ["10768", "מָחָה", "ניגב (מחה דמעה)", ""],
  ["10769", "מָחָה", "התנגד, הביע מחאה", ""],
  ["10770", "מֶחֱוָה", "מעשה בלתי מחייב שנועד להראות על רצון טוב וידידות", ""],
  ["10771", "מֶחֱוָה", "תנועה סמלית עם אחד מאיברי הגוף", ""],
  ["10772", "מְטֻלָּא", "מתוקן בטלאי, שניתן עליו טלאי במקום הקרע", ""],
  ["10773", "מִכְמוֹנֶת", "מלכודת, פח יקוש", ""],
  ["10774", "מִכְמָן", "מטמון, אוצר", ""],
  ["10775", "מִכְמַנִּים", "סודות", ""],
  ["10776", "מְפוֹרָז", "שאין בו כוחות צבאיים", ""],
  ["10777", "מְצוּעֲצָע", "מקושט ומעוטר בחוסר טעם ובצורה מוגזמת, קיטש", ""],
  ["10778", "מֵצֵר", "מצטער על משהו", ""],
  ["10779", "מְרוּבָּב", "מוכתם, מלוכלך", ""],
  ["10780", "מִרְקָם", "הרכב של חומר", ""],
  ["10781", "מִרְקָם", "מארג, אופי שנובע מההרכב", ""],
  ["10782", "מָשָׁה", "הוציא מתוך המים", ""],
  ["10783", "מְשׂוּרָה", "במידה מצומצמת, מעט מאוד", ""],
  ["10784", "מְשׂוּרָה", "כלי למדידת נוזלים", ""],
  ["10785", "מָשַׁל בְּרוּחוֹ", "ריסן את עצמו, התאפק", ""],
  ["10786", "נוֹחַ לִכְעוֹס", "מתעצבן מהר, מהיר חֵמה", ""],
  ["10787", "נִחַם", "התנחם", ""],
  ["10788", "נִישָׂא", "רם, גבוה", ""],
  ["10789", "נִישָׂא", "הורם, הונף", ""],
  ["10790", "נִישָׂא", "הושמע, נאמר", ""],
  ["10791", "נָמוֹג", "נעלם בהדרגה, התפוגג", ""],
  ["10792", "נָמוֹג", "מפוחד מאוד, מבוהל", ""],
  ["10793", "נַקִיק", "חריץ בסלע או בהר, סדק", ""],
  ["10794", "נֵתַח", "חתיכת בשר", ""],
  ["10795", "נֵתַח", "חלק מהשלם, פרוסה", ""],
  ["10796", "סָבָא", "שתה לשוכרה (מסבאה - פאב)", ""],
  ["10797", "סַגְפָן", "אדם שמענה את עצמו, מתנזר מהנאות החיים", ""],
  ["10798", "סוּגַר", "כלוב", ""],
  [
    "10799",
    "סֶכֶר",
    "מחסום בפני זרם מים של נהר או תעלה שניתן להרימו ולהורידו לפי הצורך",
    ""
  ],
  ["10800", "סָכַר אֶת פִּיו", "סתם לו את הפה, החריש", ""],
  ["10801", "סָרִיג", "אריג סרוג דמוי רשת", ""],
  ["10802", "סָרִיג", "שבכה, מחיצת רשת", ""],
  ["10803", "סָרִיג", "מתקן לייבוש כלים", ""],
  ["10804", "סָתוּם", "לא ברור, לא מובן, מעורפל", ""],
  ["10805", "עִבּוּי", "הפיכת דבר לעבה, צפוף או סמיך יותר משהיה", ""],
  ["10806", "עִבּוּי", "תהליך הפיכת גז לנוזל", ""],
  ["10807", "עֲדִי", "תכשיט", ""],
  ["10808", "עַל נְקַלָּה", "בקלות, ללא מאמץ", ""],
  ["10810", "עָלוּם", "מוסתר, חסוי", ""],
  ["10809", "עָלוּם", "בלתי מוסבר, לא מובן", ""],
  ["10812", "עֱלִי", "חלק ממערכת הרביה של צמחים", ""],
  ["10811", "עֱלִי", "מכשיר שצורתו גליל המשמש לכתישה", ""],
  ["10813", "עִנְבָּל", "לשון הפעמון", ""],
  ["10814", "עִנְבָּל", "רצועת הבשר הקטנה התלויה בחלל הלוע מעל ללשון", ""],
  ["10815", "עָנֵף", "שיש לו הרבה ענפים רבים", ""],
  ["10816", "עָנֵף", "מסועף, בעל הרבה שלוחות", ""],
  ["10818", "עָסִיסִי", "מלא מיץ", ""],
  ["10817", "עָסִיסִי", "רענן, מעניין", ""],
  ["10819", "עֲשָׁשִׁית", "מנורת נפט", ""],
  ["10820", "פָּדָה", "שילם כסף תמורת החפץ הממושכן", ""],
  ["10821", "פָּדָה", "הציל, שיחרר, חילץ", ""],
  ["10822", "פּוּזְמָק", "גרב", ""],
  ["10823", "פּוּמִית", "פִּיָּה", ""],
  ["10824", "פִּיטּוּם", "האכלת מופרזת", ""],
  [
    "10826",
    "פִּיֵּס",
    "הרגיע מישהו והשכיח את כעסו על ידי בקשת סליחה, פיצוי וכו'",
    ""
  ],
  ["10825", "פִּיֵּס", "השכין שלום בין שני צדדים", ""],
  ["10827", "פִּיק בִּרְכַּיִּים", "רעידת הברכיים מפחד או מחולשה", ""],
  ["10829", "פֶּלֶג", "חלק מקבוצה שחבריו החליטו להיפרד ממנה", ""],
  ["10830", "פֶּלֶג", "חלק מהשלם (לרוב במשמעות חצי)", ""],
  ["10828", "פֶּלֶג", "נחל טבעי קטן, ערוץ", ""],
  ["10831", "פָּרַע", "החזיר חוב", ""],
  ["10832", "פָּרַע", "הרס, בזז", ""],
  [
    "10833",
    "פָשָׂה",
    "התפשט, התרחב, נפוץ (משמש במיוחד לתיאור תופעות שליליות או מזיקות)",
    ""
  ],
  ["10834", "פִּשְפָּש", "דלת קטנה, שער קטן", ""],
  ["10835", "פֶּתִי", "מי שמאמין לכל מה שאומרים לו", ""],
  ["10836", "צְדוּדִית", "פרופיל, מראה דמותו של אדם או עצם מן הצד", ""],
  ["10837", "צוֹהַר", "חלון קטן, אשנב", ""],
  ["10838", "קוּבְלָנָה", "תלונה, טענה", ""],
  ["10839", "קוּלְמוּס", "עט, נוצה לרישום", ""],
  ["10840", "קוּף הַמַּחַט", "החור במחט דרכו משחילים את החוט, חריר המחט", ""],
  ["10841", "קוֹרַת רוּחַ", "שביעות רצון, נחת (להיפך: מורת רוח)", ""],
  ["10842", "קֶסֶת", "כלי לדיו", ""],
  ["10843", "קָפַץ", "סגר בחוזקה איבר כלשהו (שפתיים, אגרוף)", ""],
  ["10844", "קָפַץ אֶת יָדוֹ", "סרב לתת", ""],
  ["10845", "קָרוּא", "מי שהוזמן", ""],
  ["10846", "קְשֵׁה עוֹרֶף", "עקשן", ""],
  [
    "10847",
    "רַאַוְותָן",
    "אוהב להראות את עצמו ולהרשים אחרים (מציג עצמו כאילו היה חלון ראוה)",
    ""
  ],
  ["10848", "רֶגֶב", "גוש עפר", ""],
  ["10849", "רְדִיד ", "צעיף", ""],
  ["10850", "רוֹבֶד", "שכבה", ""],
  ["10852", "רָווּי", "מלא בנוזלים, ששתה מספיק, שהשקו אותו דַיו", ""],
  ["10851", "רָווּי", "מלא, עמוס", ""],
  ["10853", "רֶכֶס", "שרשרת הרים או גבעות", ""],
  ["10854", "רָפֶה", "חלש ומדולדל, רופס", ""],
  ["10855", "רִפְיוֹן יָדַיִּם", "יאוש", ""],
  ["10856", "רִפְיוֹן רוּחַ", "יאוש", ""],
  ["10857", "רִפְיוֹן רוּחַ", "חולשה נפשית, חוסר אונים", ""],
  ["10858", "רִתְמָה", "מערכת רצועות המשמשת לקשירת הבהמה, אפסר, רסן", ""],
  ["10859", "שְׁבָב", "רסיס עץ או מתכת הניתז בעת עיבודם", ""],
  ["10860", "שְׁבָב", "חלק קטן במחשב (צִ'יפ)", ""],
  ["10861", "שַׁבְשֶׁבֶת", "מתקן המשמש לזיהוי כיוון הרוח", ""],
  [
    "10862",
    "שִׁגְעוֹן גַּדְלוּת",
    "סוג של הפרעה נפשית שהלוקה בה חושב שהוא אישיות חשובה ובעלת כוחות מיוחדים",
    ""
  ],
  ["10863", "שְׁדֵמָה", "שדה תבואה או כרם", ""],
  [
    "10864",
    "שׁוֹקֶת",
    "מיכל מים דמוי אמבטיה המשמש להשקיית בהמות (בִּפְנֵי שׁוֹקֶת שְׁבוּרָה - במצב של חוסר אונים)",
    ""
  ],
  ["10865", "שִׁיסּוּי", "הסתה לתקיפה, חירחור", ""],
  ["10866", "שָׁלַט בְּרוּחוֹ", "ריסן את עצמו, התאפק", ""],
  ["10867", "שִׁעֲרוּך", "הערכת שווי מחדש", ""],
  ["10868", "שִׁרְבּוּט", "ציור ללא משמעות, 'קשקוש'", ""],
  ["10869", "תָּאֵב", "רוצה מאוד, משתוקק (תאב בצע - רודף כסף)", ""],
  ["10871", "תַּגְּרָן", "כינוי למי שמרבה להתווכח על המחיר", ""],
  ["10870", "תַּגְּרָן", "רוכל בשוק, סוחר", ""],
  [
    "10872",
    "תּוֹחֶלֶת חַיִים",
    "משך הזמן שאדם (או מכשיר או ארגון) צפויים להתקיים",
    ""
  ],
  ["10873", "תְּלוּלִית", "ערימה קטנה, תלולית", ""],

  ["10874", "אֲבוּקָה", "לפיד, צרור קיסמים בוערים", ""],
  [
    "10875",
    "אֶבֶן מַשְׁחֶזֶת",
    "אבן המשמשת להשחזה ושינון של כלי חיתוך חדים",
    ""
  ],
  ["10876", "אֶגֶד", "תחבושת לפצע, פלסטר (אגד מדבק)", ""],
  ["10877", "אֶגֶד", "חבילה", ""],
  ["10878", "אֶגֶד", "יחידה צבאית גדולה", ""],
  ["10879", "אֵגֶל", "רסיס, טיפה של נוזל (טל, זיעה, דמעה וכדומה)", ""],
  ["10880", "אָהִיל", "כיסוי למנורה", ""],
  [
    "10881",
    "אוֹבְּיֶקְטִיבִי",
    "ענייני, שאינו מושפע מדעות או העדפות אישיות",
    ""
  ],
  ["10882", "אוּמְדָן", "הערכה של גודל", ""],
  ["10883", "אוֹרְחָה", "שיירה של בעלי חיים או רוכבים (בדרך כלל של גמלים)", ""],
  ["10884", "אוֹרְלוֹגִין", "שעון קיר גדול", ""],
  ["10885", "אָחוּז אֵימָה", "מפוחד מאוד", ""],
  ["10886", "אָכַף", "כפה על קיום חוק, תקנה, פסק דין", ""],
  ["10887", "אָנִין", "בעל טעם מעודן ורגיש, בררן", ""],
  ["10888", "אָפִיק", "נחל, ערוץ, נהר", ""],
  ["10889", "אָפִיק", "מסלול, נתיב, כיוון", ""],
  ["10890", "אָפַס", "לא נשאר ממנו דבר, תם, נגמר", ""],
  [
    "10891",
    "בָּא עַל שְׂכָרוֹ",
    "קיבל את מה שמגיע לו על מעשיו הטובים, תוגמל",
    ""
  ],
  [
    "10892",
    "בּוֹסֵס",
    "הלך לאט ובצעדים כבדים, בדרך כלל באדמה טובענית, בבוץ, בשלג וכדומה",
    ""
  ],
  ["10893", "בָּזַק", "פיזר", ""],
  ["10894", "בָּזָק", "מהיר מאוד, מפתיע", ""],
  ["10895", "בְּחַדְרֵי חֲדָרִים", "בצורה לא גלויה, בסתר", ""],
  ["10896", "בִּיעוּת", "פחד גדול, מורא, אימה", ""],
  ["10897", "בִּיעָף", "במהירות", ""],
  ["10898", "בֵּית מֶרְחָץ", "מקום ציבורי שניתן להתרחץ ולהתקלח בו", ""],
  ["10899", "בַּר", "ראוי ל-, בעל... (בר-סמכא - בעל סמכות)", ""],
  ["10900", "בַּר", "בן", ""],
  ["10902", "בַּר", "נקי, טהור", ""],
  [
    "10904",
    "בַּר",
    "חוץ, הצד החיצון (אין תוכו כברו - אין פנימיותו כחיצוניותו)",
    ""
  ],
  ["10901", "בַּר", "תבואה, גרגרי תבואה ללא השיבולים", ""],
  ["10903", "בַּר", "לא מתורבת, פראי", ""],
  ["10905", "בְּרִיּוֹת", "שם כולל לבעלי החיים (כולל בני אדם)", ""],
  ["10906", "בְּרִיחַ", "קורה מעץ או ברזל המשמשת לנעילת השער", ""],
  ["10907", "בְּרִיחַ", "אחד החלקים בנשק קל", ""],
  ["10908", "בַּר-שְׁפִיטָה", "מי שניתן לשפוט אותו", ""],
  ["10909", "בְּשׁוֹגֵג", "לא בכוונה, בטעות, שלא במתכוון, בלא יודעין", ""],
  [
    "10910",
    "גֹּורֶן",
    "מקום בו דשים את התבואה (מפרידים את הגרגרים מהשיבולים)",
    ""
  ],
  ["10911", "גָחַן", "התכופף, שח, גהר", ""],
  ["10912", "גַּלָב", "סַפָּר, אדם שמקצועו לספר אנשים ולגלחם", ""],
  [
    "10913",
    "גַּמְלוֹנִי",
    "גדול, ארוך, מגושם, חסר קואורדינציה, מסורבל (גמלוני - כמו גמל)",
    ""
  ],
  ["10914", "גָּנַז", "העביר מסמכים לארכיב לשם אִחסון", ""],
  ["10915", "גֵּץ", "ניצוץ", ""],
  ["10916", "גֶּשֶׁם זַלְעָפוֹת", "גשם חזק, מבול", ""],
  ["10917", "דּוּמָם", "בשקט, בדממה", ""],
  ["10918", "דִידָה", "צלע, הלך תוך קפיצות קלות ותנועות מצד לצד", ""],
  ["10920", "דָּלִיל", "מימי, נוזלי", ""],
  ["10919", "דָּלִיל", "לא מרוכז, לא דחוס, מפוזר", ""],
  [
    "10921",
    "דִמְעוֹת תַנִּין",
    "דמעות שווא המוזלות רק למראית עין, השתתפות בצער הזולת כלפי חוץ בלבד",
    ""
  ],
  [
    "10922",
    "דֶּשֶׁן",
    "חומר כימי שמוסיפים לאדמה לצורך השבחתה לגידול צמחים",
    ""
  ],
  ["10923", "הִדִּיחַ", "הסית למעשה שלילי", ""],
  ["10924", "הַדְרָן", "קטע נוסף לאחר סיום הופעה, לאור בקשת הקהל", ""],
  ["10925", "הוֹגִיעַ", "עייף", ""],
  ["10926", "הִזְלִיף", "התיז נוזל, טפטף", ""],
  ["10927", "הִטִּיל אֶת חִתּוֹ / חִתָּתוֹ", "הפחיד אותו מאוד, הרתיע", ""],
  ["10928", "הֵטִיל מוֹרָא", "הפחיד מאוד", ""],
  [
    "10929",
    "הִלְאִים",
    "העביר לבעלות הכלל, הפקיע מבעלות פרטית לבעלות לאומית (להיפך: הפריט)",
    ""
  ],
  ["10930", "הָלַךְ רָכִיל", "ריכל", ""],
  [
    "10931",
    "הָלַך שְׁבִי",
    "התפתה אחרי דבר מה שהתברר כשקר ומרמה, הלך שולל",
    ""
  ],
  [
    "10932",
    "הָלַך שְׁבִי",
    "התאהב במישהו או משהו לחלוטין והיה מוכן לעשות הכול למענו",
    ""
  ],
  ["10933", "הָלַך שׁוֹלַל", "האמין לדבר שהתברר כמוטעה, רוּמה", ""],
  ["10934", "הִלְעִיט", "האכיל יותר מידי", ""],
  ["10935", "הֵמִיר", "החליף דבר אחד בשני", ""],
  ["10936", "הֵמֵס", "חימם מוצק עד שהפך לנוזל", ""],
  ["10937", "הֵסֵב", "התיישב לשולחן", ""],
  ["10938", "הֶעְפִּיל", "התקדם בתפקיד או מעמד", ""],
  ["10939", "הֶעְפִּיל", "עלה על מקום גבוהה, טיפס", ""],
  ["10940", "הִפְצִיר", "בקש שוב ושוב, התחנן", ""],
  [
    "10941",
    "הִפְקִיעַ",
    "לקח דבר מה מבעליו בעזרת צו משפטי או בתוקף חוקי אחר",
    ""
  ],
  [
    "10942",
    "הִפְקִיעַ",
    "העלה דבר מעבר למקובל (הפקעת מחירים - העלאה מופרזת של מחירים מעבר למחיר המקובל, פשיטת עור)",
    ""
  ],
  ["10943", "הֵפֵר", "הפסיק, השבית", ""],
  ["10944", "הֵפֵר", "לא קיים", ""],
  ["10945", "הִצְטַנֵּף", "התכרבל בתנוחה עוברית", ""],
  ["10946", "הֵקִיץ הַקֵץ", "הגיע הסוף למשהו, תם", ""],
  ["10947", "הִקְנִיט", "הרגיז מישהו על ידי לעג, עקיצה", ""],
  ["10948", "הִרְעִיף", "העניק דברים טובים (כמו מחמאות) בנדיבות וברוחב לב", ""],
  ["10949", "הִרְעִיף", "הזיל, התיז", ""],
  ["10950", "הִרְפָּה", "הפסיק להחזיק בו, הפסיק להטריד או להציק", ""],
  ["10951", "הִשִּׂיא עֵצָה", "ייעץ", ""],
  ["10952", "הִשְׁרָה", "שם משהו בתוך נוזל, טבל (והשאיר בתוך הנוזל)", ""],
  ["10953", "הִשְׁתַּיֵּר", "נשאר, נותר", ""],
  ["10955", "הִשְׁתַּעְבֵּד", "התמכר", ""],
  [
    "10956",
    "הִשְׁתַּעְבֵּד",
    "השקיע את כל זמנו ומרצו במשהו תוך זניחת דברים אחרים",
    ""
  ],
  ["10954", "הִשְׁתַּעְבֵּד", "קיבל בהכנעה את מרותו של מישהו, דוכא", ""],
  ["10957", "הִתְאַבֵּק בַּעֲפַר רַגְלָיו", "הפך לתלמידו", ""],
  ["10958", "הִתִּיךְ", "העביר חומר ממצב מוצק למצב נוזל", ""],
  ["10959", "הִתְיַמֵּר", "חשב וטען שהוא יכול לעשות משהו שמעבר ליכולתו", ""],
  ["10960", "הִתְלַקֵּחַ", "התפרץ, התעורר והתחזק (על יֶצֶר, רגש וכד')", ""],
  ["10961", "הִתְלַקֵּחַ", "נדלק, התחיל לבעור", ""],
  ["10962", "הִתְלַקֵּחַ", "התלהט, התפשט (על ריב, קטטה וכד')", ""],
  [
    "10963",
    "הִתְעַשֵּׁת",
    "חזר לשלוט במצב, התארגן מחדש, יצא ממצב של בלבול",
    ""
  ],
  ["10964", "הִתְפִּיל", "הפך לתפל, הפך מי ים למי שתיה", ""],
  ["10965", "הִתְקַנֵּא", "התחיל לקנא", ""],
  ["10966", "הִתְרַבְרֵב", "התגאה, השתחצן, 'השוויץ'", ""],
  ["10967", "הִתְרַעֵם", "כעס, התרגז, התמרמר", ""],
  ["10968", "זַאֲטוּט", "ילד קטן, תינוק", ""],
  [
    "10969",
    "זְחוּחַ",
    "מי שהתנהגותו היא שילוב של שביעות רצון מוגזמת וגאוותנות",
    ""
  ],
  ["10970", "זִיקּוּק", "טיהור, ניקוי", ""],
  ["10971", "זַך", "נקי, טהור, צלול", ""],
  ["10972", "זִכּוּך", "טיהור", ""],
  ["10973", "זָרָה", "פיזר, הפיץ (כמו בביטוי - זרה מלח על הפצעים)", ""],
  [
    "10974",
    "זָרָה אֲבָק / חוֹל בַּעֵינַיִים",
    "הונה, ניסה להסתיר את העובדות",
    ""
  ],
  [
    "10975",
    "חוֹשֶׁךְ מִצְרַיִם",
    "אפלה כבדה, חושך מוחלט (מכת החושך במצרים)",
    ""
  ],
  ["10976", "חַיִּץ", "סוג של גדר המשמש להפרדה, מחיצה", ""],
  ["10977", "חִישֵּׁל", "חיזק והקשיח מבחינה פיזית או נפשית", ""],
  ["10978", "חֲפִיר", "תעלה חפורה באדמה מסביב לחומת העיר או מסביב למבצר", ""],
  ["10979", "חֵרֵף נַפְשוֹ", "סיכן את חייו בשביל משהו שהאמין בו", ""],
  ["10980", "חֶרְפָּה", "בושה", ""],
  [
    "10981",
    "חֶשְׁבּוֹנִיָּה",
    "מכשיר לביצוע פעולות פשוטות של חיבור וחיסור (מורכב משורות חרוזים שאפשר להזיזם מצד לצד)",
    ""
  ],
  [
    "10982",
    "חַתְרָנוּת",
    "לחתור תחת מישהו, פעילות שמטרתה לערער מבפנים את הסדר הקיים",
    ""
  ],
  ["10983", "טָחוּב", "רטוב, לח", ""],
  ["10984", "טָמַן ראשׁוֹ בַּחוֹל", "התעלם מהמציאות החמורה", ""],
  ["10985", "טֶרָסָה", "מדרגה שנבנית במדרון ההר כדי למנוע סחף אדמה", ""],
  ["10986", "יָהִיר", "גאוותן, רברבן", ""],
  [
    "10987",
    "יְחִידֵי סְגוּלָה",
    "אנשים שמצטיינים בתחום פעולתם, משכמם ומעלה",
    ""
  ],
  [
    "10988",
    "יְלוּדָה",
    "כלל התינוקות שנולדו בזמן/במקום מסוים (ההפך מתמותה)",
    ""
  ],
  ["10989", "יָצָא מִכֵּלָיו", "התפרץ בכעס עד ששכח את עצמו, התרתח ביותר", ""],
  ["10990", "יֶקֶב", "המקום שבו מייצרים יין", ""],
  ["10991", "יְקַר הַמְּצִיאוּת", "נדיר, שאין רבים כמותו", ""],
  ["10992", "יְשִׁימוֹן", "מדבר, שממה", ""],
  ["10993", "יָשִׁישׁ", "זקן, קשיש", ""],
  ["10994", "כִּיכָּר", "יחידת משקל עתיקה", ""],
  ["10995", "כָּלִיל", "לגמרי, לחלוטין, עד תום", ""],
  ["10996", "כְּלִימָה", "בושה", ""],
  ["10997", "כָּמוּש", "מצומק, בלה, נובל", ""],
  ["10998", "לְבָנָה", "ירח, המאור הקטן", ""],
  ["10999", "לְמוֹתָר", "מיותר", ""],
  ["11000", "מבוֹאָר", "מפורש, מוסבר", ""],
  ["11001", "מַגְבִּית", "גביית כספים בצורה מאורגנת למטרה ציבורית כלשהי", ""],
  ["11002", "מַהְבִּיל", "מעלה אדים, רותח", ""],
  ["11003", "מָהַל", "ערבב נוזלים, הקטין את הריכוז על ידי הוספת מים", ""],
  ["11004", "מֵזַח", "רציף הנכנס אל תוך הים ואליו קושרים את הסירות", ""],
  ["11005", "מֵחוּשִׁים", "כאבים", ""],
  ["11006", "מַחֲלוֹקֶת", "מריבה, חילוקי דעות", ""],
  ["11007", "מַחְלָצוֹת", "בגדים מפוארים, לבוש מהודר", ""],
  ["11008", "מַטְפֶּה", "מיכל לכיבוי אש, מַטָף", ""],
  [
    "11009",
    "מֵי תְּהוֹם",
    "מי גשמים המצטברים מתחת לאדמה ומשמשים מקור למעיינות ולבארות",
    ""
  ],
  ["11010", "מְיוֹעָר", "מכוסה יער", ""],
  ["11011", "מַכּוֹשׁ", "כלי עבודה חקלאי דמוי מעדר", ""],
  ["11012", "מִנְיָן", "עשרה יהודים לפחות הדרושים בכדי לקיים תפילה", ""],
  ["11013", "מַעֲגָן", "נמל, מרינה, מפרץ קטן המשמש לעגינת כלי שיט", ""],
  [
    "11014",
    "מָעַל",
    "עשה שימוש בכספים או ברכוש שהובטח בידו למטרות רווח אישיות, שלח יד לכסף",
    ""
  ],
  ["11015", "מִפְתָּן", "סף, החלק התחתון בכניסה", ""],
  ["11016", "מַקְצֵרָה", "מכונה חקלאית המשמשת לקצירת יבולי דגנים", ""],
  ["11017", "מַרְבָד", "שטיח, שכבה, רובד", ""],
  ["11018", "מִשְׁעֶנֶת קָנֶה רָצוּץ", "דבר או אדם שאין לסמוך עליו", ""],
  ["11019", "מַתְבֵּן", "מחסן תבן, אסם", ""],
  ["11020", "נָא", "צלוי למחצה, מבושל למחצה", ""],
  ["11021", "נִבְצַר מִמֶנּוּ", "נמנע ממנו דבר מה", ""],
  ["11022", "נְהִירָה", "הגעה של הרבה אנשים למקום מסוים", ""],
  ["11023", "נוּגֶה", "עצוב", ""],
  ["11024", "נָטָה חֶסֶד", "עזר לו והתייחס אליו ברוך, סייע", ""],
  ["11025", "נֵכַר", "ארץ זרה ומרוחקת, חוץ לארץ", ""],
  ["11026", "נִלְאֶה", "עייף, תשוש", ""],
  ["11027", "נְמוּך רוּחַ", "צנוע, עניו", ""],
  ["11028", "נָצַר אֶת לְשׁוֹנוֹ / פִּיו", "השתתק, לא הוציא הגה", ""],
  ["11029", "נִקְבָּה", "מחילה, מנהרה", ""],
  ["11030", "נַקְבּוּבִית", "נקב קטן מאוד, חור זעיר", ""],
  ["11031", "סָבוּךְ", "מסובך מאוד (על בעיה), סמיך ועבות (על יער)", ""],
  ["11032", "סוֹכֵךְ", "כיסה לצורך הגנה", ""],
  ["11033", "סִיכֵּל", "ביטל, הכשיל דבר מה", ""],
  ["11034", "סָר חִינוֹ", "הפסיק למצוא חן בעיני מישהו (החן שלו זז)", ""],
  ["11035", "סָתוּר", "לא מסודר, פרוע", ""],
  ["11036", "עָבִיט", "כלי קיבול למי שפכים, סיר לילה", ""],
  ["11037", "עֶגְלוֹן", "מי שנוהג בעגלה רתומה לסוסים", ""],
  [
    "11038",
    "עֹול",
    "כלי רתמה לבהמות עבודה, שני מוטות המחוברים ביתדות לעורף הבהמה",
    ""
  ],
  [
    "11039",
    "עִישֵּׂב",
    "הוציא (בידיים) עשבים שוטים (נִיכּוּש - הוצאת עשבים בעזרת מַכּוֹש)",
    ""
  ],
  ["11040", "עַל רֶגֶל אַחַת", "בצורה קצרה ומתומצתת, ביעף", ""],
  ["11041", "עָלַב", "העליב, פגע", ""],
  [
    "11042",
    "עָמַד עַל טִיבוֹ",
    "חקר את אופיו של משהו וגיבש את דעתו עליו, תהה על קנקנו",
    ""
  ],
  ["11043", "עֲקַלְקָל", "שאינו ישר, מפותל", ""],
  [
    "11044",
    "עֲרוּגָה",
    "תלולית אדמה מוארכת בה זורעים זרעים או שותלים שתילים בגינה",
    ""
  ],
  ["11045", "פּוֹרֶה", "שמניב הרבה פרי, שנותן תפוקה גדולה, יעיל", ""],
  [
    "11046",
    "פְּלָצוּר",
    "חבל ארוך עם לולאה בקצה המשמש את הבוקר ללכידת סוסים, לָאסוֹ",
    ""
  ],
  ["11047", "פְּתַלְתְּל", "שאינו ישר, מפותל", ""],
  ["11048", "פְּתַלְתְּל", "מסובך להבנה, מורכב", ""],
  ["11049", "פְּתַלְתְּל", "הפכפך, ערמומי", ""],
  ["11050", "צִי", "קבוצת אוניות בעלות ייעוד משותף", ""],
  ["11051", "צִיר", "שליח, מיופה כוח, מעין שגריר", ""],
  ["11052", "צִיר", "מיץ, רוטב", ""],
  ["11053", "צְנִים", "טוסט, קלי", ""],
  ["11054", "קוֹצֶר רוּחַ", "חוסר (קצר ב) סבלנות", ""],
  ["11055", "קִידָּה", "השתחוות קלה", ""],
  ["11058", "קֶלַח", "זרם, שטף", ""],
  ["11056", "קֶלַח", "פרי התירס", ""],
  ["11057", "קֶלַח", "קלח תירס, אשכול התירס שבו מצויים הגרגרים", ""],
  [
    "11059",
    "קְלִישָאַה / קְלִישָה",
    "רעיון נדוש ושחוק שכבר הועלה פעמים רבות בעבר והספיק לאבד מעוצמתו",
    ""
  ],
  ["11060", "קָמִין", "תנור, אח", ""],
  ["11061", "קִנְטֵר", "לגלג, הרגיז", ""],
  ["11062", "קְשֵׁה לֵב", "חסר רחמים, אכזרי", ""],
  ["11063", "קְשֵׁה לֵב", "עקשן", ""],
  ["11064", "קְשֵׁה לֵב", "חצוף, חסר בושה, גס רוח", ""],
  ["11065", "רוֹחַב יָד / לֵב", "נדיבות", ""],
  [
    "11066",
    "רִיפָּה אֶת יָדָיו",
    "החליש אותו מורלית (ההיפך מעודד אותו), ייאש אותו",
    ""
  ],
  ["11067", "שְׂבָכָה", "מחיצת רשת, גדר", ""],
  ["11068", "שַׁבְרִיר", "חלק קטן ביותר, קצת", ""],
  [
    "11075",
    "שִׁדֵּל",
    "פיתה, ניסה לשכנע מישהו לעשות משהו בניגוד לרצונו ההתחלתי",
    ""
  ],
  ["11069", "שׁוֹבָךְ", "קן יונים בצורת תיבה", ""],
  ["11072", "שָׁחוּק", "עייף נפשית, מותש וחסר כוח", ""],
  ["11070", "שָׁחוּק", "בלוי ומשומש, מרופט", ""],
  ["11071", "שָׁחוּק", "נדוש, שדוף (כשנאמר על רעיון)", ""],
  ["11074", "שִׁחֵת", "השחית, הרס, חיבל", ""],
  ["11073", "שַׁחַת", "תבואה", ""],
  ["11076", "שִׁיכּוּך", "ריכוך, הרגעה (משככי כאבים)", ""],
  [
    "11077",
    "שִׁכְתּוּב",
    "כתיבת חומר כלשהו בשנית בניסוח או סגנון טוב יותר",
    ""
  ],
  ["11078", "שִׁלְהֵי  ", "סוף, סיום, בשלבים האחרונים", ""],
  ["11079", "שָׁלַח יָד בְּנַפְשוֹ", "התאבד, טָרָף נפש בכַפו", ""],
  ["11080", "שִׂמְחָה לְאֵיד", "שמחה על כשלונו של מישהו", ""],
  ["11082", "שָׁפָל", "נבזה, מנוול", ""],
  ["11081", "שָׁפָל", "נמוך, קטן (שפל רוח - צנוע, רוחו 'קטנה')", ""],
  ["11083", "שַׁרְלְטָן", "נוכל, רמאי", ""],
  ["11084", "תֶּבֶן", "סוג של קש המשמש כמאכל בהמות", ""],
  [
    "11085",
    "תָּדִיר",
    "שכיח, קורה לעתים קרובות (תדיר - בתדירות גבוהה) (להיפך: נדיר)",
    ""
  ],
  ["11086", "תִּיעוּב", "סלידה, מאיסה", ""],
  ["11087", "תַּסְמִין", "סימפטום, סימן היכר", ""],
  ["11088", "תִּפְרַחַת", "מספר פרחים המחוברים יחדיו לאותו הגבעול, אשכול", ""],
  ["11089", "תַּתְרָן", "נטול חוש ריח. בהשאלה: חסר חוש הבחנה", ""],

  ["11090", "אֶבְרָה", "כנף של עוף", ""],
  ["11091", "אַדְוָה", "גל קטן", ""],
  ["11092", "אִוְושָׁה", "רחש קל, רשרוש", ""],
  ["11093", "אָזְלַת יָד", "חוסר אונים, חולשה, חוסר יכולת לבצע דבר מה", ""],
  ["11094", "אֲחִיזַת עֵינַיִים", "תרמית, הטעיה, הולכת שולל", ""],
  ["11095", "אֵיכָה?", "איך? הכיצד?", ""],
  ["11096", "אִיכּוּל", "קורוזיה, הרס מתכת עקב התרכבות עם חמצן", ""],
  [
    "11097",
    "אִימּוּם",
    "תבנית בצורת כף רגל או גוף בעזרתה מייצרים נעליים או בגדים",
    ""
  ],
  ["11098", "אֵימְתָנִי", "מפחיד, מאיים, מבהיל", ""],
  ["11099", "אֵיפָה וְאֵיפָה", "אפליה, יחס לא שווה לשני אנשים שונים", ""],
  ["11100", "אֵיתָן", "חזק ויציב, חסון", ""],
  ["11101", "אֵיתָן בְּדַעְתוֹ", "עומד על דעתו, דבק בעמדתו, משוכנע בצדקתו", ""],
  ["11102", "אָמַד", "העריך", ""],
  ["11103", "אַמַּת מַיִם", "תעלה המשמשת להעברת מים ממקום למקום", ""],
  ["11104", "אֲנָקָה", "צעקה (לרוב כתוצאה מצער או כאב), גניחה", ""],
  [
    "11105",
    "אֲפַרְכֶּסֶת",
    "החלק העליון בשפופרת הטלפון שאותו מצמידים לאוזן",
    ""
  ],
  ["11106", "אִצְטַבָּה", "מדף, רף", ""],
  ["11107", "אַרְכָּה", "הארכת זמן, תוספת זמן", ""],
  ["11108", "בְּדִיעֲבַד", "לאחר מעשה", ""],
  ["11109", "בּוּר", "שדה לא מעובד שלא נחרש או נזרע", ""],
  ["11110", "בּוּר (וְעַם הָאָרֶץ)", "חסר השכלה, אנאלפבית", ""],
  ["11111", "בֵּיבָר", "גן-חיות", ""],
  ["11112", "בְּכִי תַּמְרוּרִים", "בכי מר על אסון, התייפחות", ""],
  ["11113", "בֶּכֶר", "גמל צעיר", ""],
  [
    "11114",
    "בִּמְשוּרָה",
    "כמות קטנה ומצומצמת (לקוח מהביטוי “מים במשורה”)",
    ""
  ],
  ["11115", "בָּעֲרָה חֲמָתוֹ", "זעם, כעס, רגז", ""],
  ["11116", "בַּר-כִּבּוּשׁ", "שאפשר לכבוש אותו", ""],
  ["11117", "גֵּאוּת", "עליית מפלס מי הים", ""],
  [
    "11118",
    "גִּבֵּב",
    "אסף חפצים שונים וערם אותם בערימה, שם דברים זה על גבי זה ללא סדר",
    ""
  ],
  ["11119", "גָּדִיד", "קטיף תמרים", ""],
  ["11120", "גוּמְחָה", "שֶקַע בקיר", ""],
  ["11121", "גִּידֵּף", "קִלֵל", ""],
  ["11122", "גַלְעֶד", "יד, אנדרטה, מצבה", ""],
  ["11123", "דָּווּי", "מיוסר, כואב, מעונה", ""],
  ["11124", "דִּכְדּוּךְ", "דיכאון, עצב, מועקה נפשית", ""],
  ["11125", "דַּש", "קפל בבגד", ""],
  ["11126", "דָּשׁ בַּעֲקֵבָיו", "מזלזל", ""],
  ["11127", "הֵאִיץ", "הוסיף מהירות, זירז", ""],
  ["11128", "הִגְלָה", "גירש מישהו ממולדתו", ""],
  ["11129", "הַדְלִיף", "גילה למישהו דבר שהיה אמור להישאר חסוי", ""],
  ["11130", "הוֹנָה", "רימה, הטעה", ""],
  ["11131", "הוּעַם", "הפך לקהה, טושטש, עורפל", ""],
  ["11132", "הֶחְטִיא", "גרם למישהו לבצע חטא", ""],
  ["11134", "הִלְבִּין פָּנִים", "בייש, העליב", ""],
  [
    "11135",
    "הָלַךְ עִמּוֹ בְּקֶרִי",
    "פעל נגדו, פעל באופן שיעצבן אותו, 'עשה לו דווקא'",
    ""
  ],
  ["11136", "הֲלָצָה", "בדיחה", ""],
  ["11137", "הֵנִיא", "גרם לו לא לעשות את שתכנן, מנע בעדו", ""],
  ["11138", "הִסְבִּיר פָּנִים", "האיר פנים, התנהג בצורה נחמדה", ""],
  ["11139", "הִסְתּוֹדֵד", "התלחש עם מישהו", ""],
  ["11140", "הֶעְתִּיר", "העניק בנדיבות וברוחב לב", ""],
  ["11141", "הִצְנִיעַ", "הניח דבר מה כך שלא יבלוט, החביא, הסתיר", ""],
  ["11142", "הֵצֵר צְעָדָיו", "הגביל, הפריע לו ומנע את התקדמותו", ""],
  ["11133", "הִקָּווּת", "היאספות של מים במקום מסוים (מקווה מים)", ""],
  [
    "11143",
    "הִקְשָה אֶת לִיבּוֹ / עָרְפּוֹ",
    "התעקש, לא וויתר, המשיך לסרב",
    ""
  ],
  ["11144", "הֶרֶף עַיִן", "פרק זמן קצר מאוד, רגע", ""],
  [
    "11145",
    "הִשְׁתַּנֵּק",
    "התקשה לבלוע או לדבר בגלל התרגשות שמנעה מעבר תקין של האוויר, נחנק, מעבר האוויר אליו נחסם",
    ""
  ],
  ["11146", "הִתְוַדֵּעַ", "ערך היכרות עם מישהו/משהו חדש, הכיר לראשונה", ""],
  ["11147", "הִתְפַּלֵּשׁ", "התגלגל בעפר או בלכלוך", ""],
  ["11148", "הִתְרַפֵּס", "התחנף למישהו, לחך את עפר רגליו", ""],
  ["11149", "זָלַג", "זרם במקום בלתי רצוי", ""],
  ["11150", "זָלַג", "נטף, נזלו ממנו טיפות", ""],
  ["11151", "זָעוּם", "מעט מאוד", ""],
  ["11152", "זַרְזִיף", "זרם דק", ""],
  ["11153", "חַבּוּרָה", "סימן כחול / נפיחות כתוצאה ממכה", ""],
  ["11154", "חוּצוֹת", "שטחים פתוחים, רחובות", ""],
  ["11155", "חִיבּוּץ", "תהליך הכנת החמאה והשמנת", ""],
  ["11156", "חַלּוּק נַחַל", "אבן חלקה שנשחקה על ידי זרימת מים", ""],
  ["11157", "חֶנְוָנִי", "בעל מכולת", ""],
  ["11158", "חַרְטוֹם", "החלק הקדמי של חפץ כלשהו, כלי שייט/טייס, בעל-חיים", ""],
  ["11160", "חָשׁ", "הרגיש", ""],
  ["11159", "חָשׁ", "הזדרז, מיהר, נחפז, נבהל, נזעק, אצה לו השעה", ""],
  ["11161", "חֲתִימַת שָׂפָם", "ראשית צמיחתו של השפם", ""],
  ["11162", "טָמִיר", "נסתר, סודי, בלתי מוכר", ""],
  ["11163", "טָף", "שם כולל לילדים קטנים/תינוקות", ""],
  ["11164", "טַרְחָן", "טרדן, נודניק, אדם שמטריח אחרים", ""],
  ["11165", "יְבָבָה", "יללה, בכי ממושך או מקוטע (על פי רוב לא חזק)", ""],
  ["11166", "יְדִיד נֶפֶשׁ", "חבר נפש, חבר טוב", ""],
  ["11167", "יִלּוֹד", "תינוק רך (בשבועות הראשונים לחייו)", ""],
  ["11168", "יָצוּעַ", "מיטה, משכב, כלי מיטה שמציעים לשכיבה", ""],
  ["11169", "יָרֵא", "פוחד, מבועת", ""],
  [
    "11170",
    "יָרַד לַטִּמְיוֹן",
    "הלך לאיבוד, אבד (משמש בהתייחסות לדבר שקרה לאחר כישלון)",
    ""
  ],
  ["11171", "יְשׁוּעָה", "הצלה", ""],
  ["11172", "כּוֹנֵן", "ייסד, הקים", ""],
  ["11173", "כִּחְכּוּחַ", "השמעת קול חיכוך בגרון", ""],
  ["11174", "כִּיסוּפִים", "געגועים, השתוקקות", ""],
  ["11175", "כְּלָיָה", "חיסול, השמדה", ""],
  ["11176", "כְּמִיהָה", "השתוקקות", ""],
  ["11177", "כְרוֹנוֹלוֹגִי", "ערוך בסדר זמנים עוקב", ""],
  ["11178", "כִּתֵּת אֶת רַגְלָיו", "הלך הרבה", ""],
  ["11179", "לַהַב", "החלק החד של כלי חיתוך, סכין, פגיון, חרב", ""],
  ["11180", "לָקָה", "חלה במשהו, נפל למשכב", ""],
  ["11182", "מְאוּבָּן", "נדהם, משותק", ""],
  ["11181", "מְאוּבָּן", "דבר שנתקשה כאבן", ""],
  ["11183", "מְבוֹאָה", "לובי, אולם כניסה", ""],
  ["11184", "מַהַתַלָה", "שובבות, מעשה קונדסות", ""],
  ["11185", "מְזָוֶוה", "ארון או חלל קטן המיועד לאחסון מוצרי מזון", ""],
  ["11186", "מְחִית", "מאכל עשוי מפירות או ירקות מעוכים, פירה", ""],
  ["11187", "מָחַל", "סלח", ""],
  [
    "11188",
    "מַחְצָב",
    "חומר שחוצבים מן האדמה כגון: אבנים, פחם, מתכת וכו'",
    ""
  ],
  ["11189", "מַחְצָבָה", "מקום בו חוצבים אבנים לבניין וכו'", ""],
  ["11190", "מְטִיל", "גוש מתכת יצוקה בצורה כלשהי", ""],
  ["11191", "מַטָף", "מיכל שמשמש לכיבוי אש", ""],
  [
    "11192",
    "מִיכֵּן",
    "התקין מכונות במקום מסוים לצורך ביצוע עבודות שעד כה נעשו באופן ידני",
    ""
  ],
  ["11193", "מְכוּנָף", "שיש לו כנפיים", ""],
  ["11194", "מַכּוֹת נֶאֱמָנוֹת / נִמְרָצוֹת", "מכות חזקות מאוד", ""],
  ["11195", "מִכְסָה", "כמות מסוימת שנקבעה מראש", ""],
  ["11196", "מַעֲטֶה", "כיסוי, כְּסוּת, מעטֶפֶת", ""],
  ["11198", "מְפַעְפֵּעַ", "מעלה בועות משמיע רשע של מים רותחים, מבעבע", ""],
  ["11197", "מְפַעְפֵּעַ", "מחלחל, חודר", ""],
  ["11199", "מַצְבִּיא", "מפקד צבאי בכיר, קצין", ""],
  ["11201", "מַקְרֵן", "רדיאטור", ""],
  ["11200", "מַקְרֵן", "מכשיר להקרנת שקופיות", ""],
  ["11202", "מִקְשָׁה", "שדה דלועים (אבטיחים, דלעות, קישואים או מלונים)", ""],
  ["11203", "מַרְזֵב", "צינור לניקוז מים מגג הבניין לאדמה או לביוב", ""],
  [
    "11204",
    "מַרְשֶה",
    "מי שנתן ייפוי כוח לאדם אחר כדי שייצג אותו (לרוב הכוונה ללקוח של עורך הדין)",
    ""
  ],
  ["11205", "מְשׁוּבָה", "מעשה קונדס, שובבות", ""],
  ["11206", "מְשׁוּבַת נְעוּרִים", "מעשה שנובע מקלות דעת או משובבות", ""],
  ["11207", "מָשַׁח", "מרח (בד'כ שמן)", ""],
  ["11208", "מָשַׁח", "יצק שמן על דבר בכדי לקדשו", ""],
  ["11209", "מִשְׁלַח יָד", "עבודה, עיסוק, מקצוע", ""],
  ["11210", "מִשְׁעוֹל", "דרך עפר צרה, שביל", ""],
  ["11211", "מִשְׁתָּלָה", "מקום בו מגדלים ומוכרים שתילים", ""],
  ["11212", "מִתְרָס", "מחסום מאולתר מחפצים שונים", ""],
  [
    "11213",
    "נְחוּשְתַּיִים",
    "שלשלאות שנועדו למנוע את חופש התנועה של האסירים, כבלים",
    ""
  ],
  [
    "11214",
    "נַיָּח",
    "קבוע, מקובע, שאינו נייד, לא ניתן להזיזו ממקום למקום",
    ""
  ],
  ["11215", "נִיחוֹח", "ריח נעים וטוב", ""],
  ["11216", "נִיתֵּץ", "ניפץ לרסיסים, ריסק", ""],
  [
    "11217",
    "נִכְנַס בָּעֳבִי הַקוֹרָה",
    "התמודד עם הבעיה, ירד לעומקו של עניין",
    ""
  ],
  ["11219", "נָס", "נעלם, התפוגג", ""],
  ["11218", "נָס", "ברח, נמלט", ""],
  [
    "11220",
    "נָפַל עַל אוֹזְנַיים עֲרֵלוֹת",
    "לא הקשיבו למה שאמר, דיבר אל העצים והאבנים",
    ""
  ],
  ["11222", "נִפְתָּל", "עקלקל, פתלתל", ""],
  ["11221", "נִפְתָּל", "ערמומי, תחבולני", ""],
  ["11226", "נִצָּב", "שחקן בתפקיד שולי שמשמש כתפאורה, סטטיסט", ""],
  ["11223", "נִצָּב", "ידית של חרב, קת", ""],
  ["11224", "נִצָּב", "עמד, מוקם", ""],
  ["11225", "נִצָּב", "אנך", ""],
  ["11227", "נְקִי כַּפַּיִים", "ישר, הגון, צדיק", ""],
  ["11228", "נִקְלֶה", "בזוי, נחות, בעל ערך נמוך", ""],
  ["11229", "נְקָפוֹ לִבּוֹ / מַצְפּוּנוֹ", "נתקף ייסורי מצפון, התחרט", ""],
  ["11230", "נִשְׂגָב מִבִּינָתוֹ", "אינו יכול להבין את הדבר", ""],
  ["11231", "סָבַב אוֹתוֹ בְּכַחַשׁ", "שיקר לו, רימה אותו", ""],
  ["11232", "סוֹאֵן", "רועש", ""],
  ["11233", "סוּמָא", "עיוור", ""],
  ["11234", "סִיכָה", "מריחה בשמן, משיחה", ""],
  ["11235", "סִמְטָה", "רחוב צדדי וצר", ""],
  ["11237", "סַף", "התנאי או הכמות המינימאלית להתרחשותו של דבר מה", ""],
  ["11236", "סַף", "מפתן בדלת הכניסה, אדן", ""],
  ["11238", "סִפּוּן", "תקרה, חלקה העליון של האוניה (התקרה של בטן האוניה)", ""],
  ["11239", "סָפוּן", "צפון, נסתר", ""],
  ["11240", "עוּל יָמִים", "צעיר", ""],
  ["11242", "עָכוּר", "עצוב, מדוכדך", ""],
  ["11243", "עָכוּר", "לא תקין, משובש", ""],
  ["11241", "עָכוּר", "לא צלול, דלוח", ""],
  ["11244", "עַל כַּרְעֵי תַּרְנְגוֹלֶת", "רעוע ובלתי יציב", ""],
  ["11245", "עַל מֵי מְנוּחוֹת", "ברוגע, בשלווה", ""],
  ["11246", "עָלַץ", "שמח, התמלא שמחה", ""],
  ["11248", "עָמַד עַל דַּעְתוֹ", "התעקש, המשיך להחזיק בדעתו, עמד על שלו", ""],
  ["11247", "עָמַד עַל דַּעְתוֹ", "הפך לבר דעת, התחיל להבין", ""],
  [
    "11249",
    "עִמְעוּם",
    "גרימה למשהו להיות מעורפל או מטושטש, הפיכתו לפחות ברור משהיה",
    ""
  ],
  ["11250", "עִמְעוּם", "החלשת האור והפיכתו לעמום יותר", ""],
  ["11251", "עָנָיו", "צנוע", ""],
  ["11252", "עֲקֵב בְּצַד אֲגוּדָל", "צעד אחר צעד, בזהירות יתרה", ""],
  ["11253", "עָשָׂה שַׁמּוֹת", "הרס משהו, החריב", ""],
  ["11254", "פַּדַחַת", "מצח", ""],
  ["11257", "פִּדְיוֹן", "המרה של שטר התחייבות בכסף", ""],
  ["11256", "פִּדְיוֹן", "הכנסה המתקבלת בפרק זמן כלשהו", ""],
  [
    "11255",
    "פִּדְיוֹן",
    "שחרור אדם או דבר מה בערבות כספית או שוות ערך אחרת",
    ""
  ],
  ["11258", "פּוֹשֵׁט יָד", "קבצן", ""],
  ["11259", "פִּכְפּוּך", "זרימה, נביעה", ""],
  ["11260", "פִּסְגָּה", "החלק הגבוה ביותר בהר", ""],
  ["11261", "פְּצִירָה", "מכשיר המשמש לשיוף והחלקה (של עץ, מתכת וכו')", ""],
  ["11262", "פְּצִירָה", "כלי לטיפוח הציפורניים", ""],
  ["11263", "פְּרִימָה", "התרת קשרים", ""],
  ["11264", "פְּרַקְדָן", "שכוב על הגב", ""],
  ["11265", "פָּשַׁט יָד", "ביקש נדבה, קיבץ נדבות", ""],
  ["11266", "פְּתִית", "פרור", ""],
  ["11267", "צָחִיחַ", "מחוסר מים, יבש", ""],
  ["11268", "צַחֲנָה", "סירחון, עיפוש", ""],
  [
    "11269",
    "צִיטָטָה",
    "קטע המובא לשם חיזוק או הדגמה (לרוב ממקור כתוב כלשהו), מובאה",
    ""
  ],
  ["11270", "צָלוּל", "לא עכור, שקוף", ""],
  ["11271", "צָפוּן", "נסתר, נעלם", ""],
  ["11274", "קֵהֶה", "שאינו בשל, בוסר", ""],
  ["11273", "קֵהֶה", "עמום, מעורפל", ""],
  ["11272", "קֵהֶה", "לא חד", ""],
  ["11275", "קָטֵגוֹר", "עורך הדין שתובע את הנאשם", ""],
  ["11276", "קְלִיָּה", "חריכה של גרגרי תבואה", ""],
  ["11277", "קָמוּר", "מעוקם בצורת קערה כלפי מעלה, קשתי (להיפך: קעור)", ""],
  ["11278", "קָנָה (לוֹ) שְׁבִיתָה", "התמקם, השתקע", ""],
  ["11281", "קָתֶדְרָה", "במה עליה עומד הנואם, דוכן", ""],
  ["11279", "קָתֶדְרָה", "שמו של הכיסא עליו יושבים הבישופים", ""],
  ["11280", "קָתֶדְרָה", "יחידה מדעית במוסד להשכלה גבוהה", ""],
  ["11282", "רִדֵּד", "שיטח", ""],
  ["11283", "רָדוּד", "לא עמוק", ""],
  ["11284", "רִיק", "וָואקוּם, אֶפֶס", ""],
  ["11286", "רִקֵּעַ", "הפך לשטוח (יצר צורות על ידי שיטוח מתכת)", ""],
  ["11285", "רָקַע", "דרך בחוזקה על הקרקע", ""],
  ["11287", "רָתַח", "כעס מאוד", ""],
  [
    "11288",
    "שְׁאָר רוּחַ",
    "יכולות רוחניות ונפשיות גדולות כגון רגישות, פתיחות וכדומה",
    ""
  ],
  [
    "11289",
    "שְׁבִיתַת נֶשֶׁק",
    "הפסקת אש, הפסקה מוסכמת של כל פעולות הלחימה",
    ""
  ],
  ["11290", "שְׂדֵרָה", "רחוב רחב, סימטה", ""],
  ["11291", "שְׂדֵרָה", "שיירה (בד'כ של כלי רכב), שורה", ""],
  ["11292", "שְׂדֵרָה", "שכבה חברתית, מעמד, סטטוס", ""],
  ["11294", "שַׁלְשֶׁלֶת", "שרשרת כבדה עשויה טבעות מתכת", ""],
  ["11295", "שָׂם לְאַל", "מנע את התגשמותו של משהו, סיכל", ""],
  ["11293", "שִׁנֵּן", "למד היטב, חזר שוב ושוב על משהו כדי לזכור אותו", ""],
  ["11296", "שָׁפַךְ לִבּוֹ", "השתפך, חשף את כל צרותיו בפני מישהו", ""],
  ["11297", "שָׁרָב", "מזג אוויר חם מאוד", ""],
  ["11298", "שְׁרַפְרַף", "כסא נמוך, הדום", ""],
  ["11299", "תָּהָה עַל קַנְקַנּוֹ", "עמד על טיבו, בחן אותו", ""],
  [
    "11300",
    "תּוֹרֶן",
    "מוט גבוה בספינה לקשירת המפרשים, עמוד גבוה לדגל, כינוי לאדם גבוה",
    ""
  ],
  ["11301", "תֵּל", "גבעה מלאכותית, ערמה, גל", ""],
  ["11302", "תָּמַהּ", "הטיל ספק, התחבט לגבי נכונותו של הדבר", ""],
  ["11303", "תָּמַהּ", "התפלא, מוזר ומפתיע", ""],
  ["11304", "תַּסְכִּית", "יצירה דרמתית שעובדה לשידור ברדיו", ""],
  ["11305", "תָּעַה", "איבד את הדרך", ""],
  ["11306", "תַּרְעוֹמֶת", "תחושת כעס, רוגז", ""],
  ["11307", "תְּשׁוּקָה", "רצון עז, תאווה עזה", ""],

  ["11414", " כּוֹרֵם", "בעל מקצוע שמגדל גפנים וענבים", ""],
  ["11309", "אֵבוּס", "כלי להגשת אוכל לבהמות", ""],
  ["11308", "אָבוס", "מפוּטם", ""],
  ["11310", "אֶבֶן בּוֹחַן", "אמת מידה, מדד", ""],
  ["11311", "אוּרְבָּנִי", "עירוני (עירוני - urban)", ""],
  [
    "11312",
    "אוֹתִיוֹת קִדּוּשׁ לְבָנָה",
    "אותיות גדולות במיוחד (להיפך: אותיות טַל וּמָטָר)",
    ""
  ],
  ["11313", "אֵיד", "צרה, אסון", ""],
  ["11314", "אַיֵּה?", "איפה?", ""],
  ["11315", "אֵין-אוֹנִים", "אי יכולת להתמודד, חולשה מוחלטת", ""],
  ["11316", "אִיפּוּל", "איסור פרסום ברבים, צנזורה, עמימות", ""],
  ["11317", "אֲלוּנְטִית", "מגבת", ""],
  ["11318", "אִלְפָּס", "סיר עמוק עם ידית ומכסה, מחבת עמוקה", ""],
  ["11319", "אָמָה", "משרתת, שפחה", ""],
  ["11320", "אָמָה", "מידת אורך קדומה (מהמרפק עד שורש כף היד)", ""],
  ["11321", "אֶמְפִּירִי", "שמבוסס על ניסוי (להיפך: תיאורטי)", ""],
  ["11322", "אִמְרָה", "פתגם", ""],
  ["11323", "אִמְרָה", "שולי האריג, שפת הבגד", ""],
  ["11324", "אַסְדָּה", "סירה קטנה, רפסודה", ""],
  ["11325", "אַסְקופָּה", "סף", ""],
  [
    "11326",
    "אַסְקופָּה הַנִּדְרֶסֶת",
    "כינוי לאדם שכולם מתעמרים בו, אדם חלש אופי",
    ""
  ],
  [
    "11327",
    "אָרְכָה לוֹ הַשָּׁעָה",
    "הזמן נדמה בעניו כנצח, היה חסר סבלנות",
    ""
  ],
  ["11328", "אַרְכּוֹף", "סד עינויים בו נקשרות הרגליים והידיים", ""],
  ["11329", "אַרְכּוֹף", "טבעת לרגלו של הרוכב", ""],
  ["11330", "אַרְכָנִי", "ארוך שלא לצורך", ""],
  ["11331", "אַשְׁמוֹרֶת", "שליש מהלילה, חלק מהלילה", ""],
  [
    "11332",
    "אָתֵאִיסְט",
    "מי שכופר בקיום האל, מי שמביע חוסר אמונה באל עליון כלשהו",
    ""
  ],
  ["11333", "בָּא בַּיָּמִים", "זקן, קשיש", ""],
  ["11334", "בֶּדֶק בַּיִת", "בקרה עצמית, בדיקה לצורך שיפור", ""],
  ["11335", "בְּהֶנֵף יָד", "בקלות, כלאחר יד, ללא מחשבה", ""],
  [
    "11336",
    "בּוֹר סוּד (שֶׁאֵינוֹ מְאַבֵּד טִפָּה)",
    "בעל זיכרון טוב (סוּד - מְסוּיָּד, שהמים לא נספגים בקירותיו ונשארים בתוכו)",
    ""
  ],
  ["11338", "בָּזִיךְ", "סוג של קערה", ""],
  ["11337", "בָּזִיךְ", "סוג של כוס, גביע", ""],
  ["11339", "בִּזְעֵיר אֲנְפִּין", "בקנה מידה קטן, מוקטן", ""],
  ["11340", "בִּזֵּר", "האציל סמכויות, פיזר האחריות", ""],
  ["11341", "בָּחַשׁ בַּקַּלַּחַת", "סכסך", ""],
  ["11342", "בֵּין הַמְּצָרִים", "במצב דחוק, באין מוצא", ""],
  ["11343", "בֵּין הַשִּׁיטִין", "בין השורות, במרומז, לא באופן מפורש", ""],
  ["11344", "בֵּית גְּנָזִים", "מקום אכסון למסמכים", ""],
  ["11345", "בֵּית נְכוֹת", "מוזיאון", ""],
  [
    "11346",
    "בֵּית עֲבוֹט",
    "מקום בו ניתן למשכן חפצים תמורת הלוואה, בית משכונות",
    ""
  ],
  ["11347", "בְּלִיל", "תערובת", ""],
  ["11348", "בֶּן-דְּמוּתוֹ", "דומה לו", ""],
  [
    "11349",
    "בֶּן-כִּלְאַיִים",
    "בעל חיים או צמח שהוא תוצר של שילוב בין שני מינים שונים",
    ""
  ],
  ["11350", "בְּעָלְמָא", "ללא סיבה מיוחדת", ""],
  ["11351", "בְּעָתָה", "פחד פתאומי, בהלה, חרדה", ""],
  ["11352", "בְּצוֹק הָעִתִּים", "בזמנים קשים, בזמן מצוקה", ""],
  ["11353", "בְּשָׂפָה רָפָה", "בקול חלש, במלמול", ""],
  ["11354", "בְּשָׂפָה רָפָה", "אמר את שאמר בחוסר רצון ומתוך הכרח", ""],
  ["11355", "גֶזֶם", "הענפים שנגזמו, הפסולת שנשארה לאחר הגיזום", ""],
  ["11356", "גִּילָּה אֶת אָזְנוֹ", "סיפר לו סוד", ""],
  ["11357", "גָמָא / גָמַע", "לגם, שתה", ""],
  ["11358", "גֶּפֶת", "פסולת הזיתים לאחר הסחיטה", ""],
  ["11359", "גֶּרֶם", "עצם (כמו עצמות השלד)", ""],
  [
    "11360",
    "גֶּרֶם",
    "גוף בעל צורה ונפח (גרם מדרגות - סדרה רצופה של מדרגות, גרם שמים - גוף שְׁמֵימִי)",
    ""
  ],
  ["11361", "דְּבָרִים בְּעָלְמָא", "דברים סתמיים, ללא כוונה מיוחדת", ""],
  ["11362", "דָּחַק אֶת הַקֵּץ", "ביקש לקרב את הסוף קודם זמנו", ""],
  ["11363", "דָּחַק אֶת הַשָּעָה", "עשה משהו לפני זמנו, נהג בפזיזות", ""],
  ["11364", "דָּחַק אֶת רַגְלָיו", "תפס את מקומו, נישל אותו מתפקידו", ""],
  ["11365", "דִּנְדֵּן", "צלצל בפעמון", ""],
  [
    "11366",
    "דַּעְתָּן",
    "מי שיודע להביע את דעתו בצורה נחרצת ויכול לדבוק בעמדותיו, אסרטיבי",
    ""
  ],
  [
    "11367",
    "דָּרְבָּן",
    "גלגל שיניים קטן המחובר לעקב המגף ונועד לדרבן את הסוס (על ידי דקירתו)",
    ""
  ],
  ["11369", "הֶאֱבִיס", "האכיל בעלי חיים", ""],
  ["11368", "הֶאֱבִיס", "נתן אוכל רב מדי", ""],
  ["11370", "הֶאֱנִישׁ", "ייחס תכונות אנושיות למי שאינו אנושי", ""],
  ["11371", "הֶדוֹנִיזְם", "נהנתנות, רדיפת תענוגות", ""],
  [
    "11372",
    "הוֹלֵך עַל קֶרַח דַּק",
    "נמצא במצב קשה ומסוכן תחת נסיבות שאינן לחלוטין בשליטתו",
    ""
  ],
  ["11373", "הִזָּה", "התיז נוזל, טפטף, הזליף", ""],
  ["11374", "הִידֵס", "הלך בצורה לא יציבה כשהוא נוטה מצד לצד", ""],
  ["11375", "הִיפּוֹתֶטִי", "משוער, לא ודאי, תאורטי", ""],
  ["11376", "הֵלִיט", "כיסה, הסתיר (לוֹט - כיסוי)", ""],
  [
    "11377",
    "הָלַך עַל חֶבֶל דַּק",
    "מצוי במצב שבו כל טעות קטנה יכולה להסב לו נזק",
    ""
  ],
  ["11378", "הֲלָנַת שָׂכָר", "עיכוב תשלום השכר לעובד", ""],
  [
    "11379",
    "הִפְטִיר",
    "קרא הפטרה (הפטרה - קטע מספרי הנביאים שקוראים בבית כנסת)",
    ""
  ],
  [
    "11380",
    "הִפְטִיר",
    "אמר משהו ללא תשומת לב מיוחדת, 'זרק' משהו לאוויר",
    ""
  ],
  ["11381", "הִפְרִיט", "העביר בעלות מהמדינה לידיים פרטיות (להיפך: הלאים)", ""],
  ["11382", "הִקְדִּיחַ", "בישל יתר על המידה", ""],
  ["11383", "הַרְרֵי זָהָב", "דמיונות וגוזמאות", ""],
  ["11384", "הִתְחַוֵּר", "התברר ללא ספק, הסתבר", ""],
  ["11385", "הִתַּמֵּם", "עשה עצמו תמים, התנהג כאילו אינו יודע במה מדובר", ""],
  ["11386", "וָאדִי", "אפיק נחל סלעי (מרבית השנה הוא יבש)", ""],
  ["11387", "ווּלְגָרִי", "המוני, גס, פשוט ביותר", ""],
  [
    "11388",
    "זוֹג",
    "החלק החיצון בפעמון, דפנותיו של פעמון (מה שמַכֶּה בזוג הוא העִנְבָּל)",
    ""
  ],
  ["11389", "חֲדַל פֵּרָעוֹן", "מי שלא מסוגל לשלם את חובו", ""],
  ["11390", "חוֹרֵשׁ מְזִמּוֹת", "זומם, מתכנן להרע", ""],
  ["11392", "חֵלֶב", "שומן המצוי בגופם של בעלי חיים", ""],
  ["11393", "חֵלֶב", "שעוות נר", ""],
  ["11391", "חִלּוּפֵי גַּבְרֵי", "החלפת בעלי תפקידים", ""],
  ["11394", "חָלַט", "יצק מים חמים", ""],
  ["11395", "חֲלִיפַת מִכְתָּבִים", "התכתבות בין שני אנשים או גופים", ""],
  ["11396", "חֵמָה", "כעס, זעם, רוגז", ""],
  ["11397", "חָנוּט", "מומיה", ""],
  [
    "11398",
    "חֲנִיטָה",
    "השריית גופת אדם או חיה בחומר מיוחד המונע ממנה להירקב",
    ""
  ],
  ["11399", "חֲפוּי רֹאשׁ", "נבוך, מבויש, נכלם", ""],
  ["11400", "חֻרְשָף", "ארטישוק", ""],
  ["11401", "טִכֵּס עֵצָה", "התייעץ, חיפש תחבולה", ""],
  ["11402", "טְפָחוֹת", "הקורות העליונות של מבנה", ""],
  [
    "11403",
    "טְרוּט עֵינַיִם",
    "בעל עיניים עצומות למחצה בשל עייפות, חוסר שינה או חולי",
    ""
  ],
  ["11404", "טְרוּנְיָה", "תלונה, תרעומת, טענה קשה", ""],
  ["11405", "יָגוֹן", "תסכול, דכדוך, דיכאון", ""],
  ["11407", "יוֹבֵל", "חמישים שנה", ""],
  ["11406", "יוּבַל", "נחל, זרוע של נהר", ""],
  ["11408", "יוֹגֵב", "איכר, עובד אדמה, חקלאי", ""],
  [
    "11409",
    "יוֹשֵׁב קְרָנוֹת",
    "בטלן, הולך בטל (קרן = פינה), אוכל לחם חסד",
    ""
  ],
  ["11410", "יֵש דְּבָרִים בְּגוֹ", "יש טעם בדבר, יש בזה היגיון", ""],
  ["11411", "כָּאוֹטי", "במצב של אי סדר ", ""],
  ["11412", "כָּאוֹס", "ערבוביה, תוהו ובוהו", ""],
  ["11413", "כְּבוּדָּה", "מטען, משא", ""],
  [
    "11416",
    "כּוֹתֶרֶת",
    "אדריכלות - החלק העליון של עמוד אבן, בדרך כלל כותרת העמוד מקושטת במוטיב צמחי",
    ""
  ],
  ["11415", "כּוֹתֶרֶת", "העלים הצבעוניים של הפרח", ""],
  ["11417", "כְּזַיִת", "קצת, מעט, קמעה", ""],
  ["11418", "כִּישוֹר", "קנה שנכרכים עליו חוטי הצמר או הפשתן הנטווים", ""],
  [
    "11419",
    "כִּמְטַחֲוֵי קֶשֶׁת",
    "קרוב, בהישג יד, בקרבת מקום (בטווח ירייה של חץ מקשת)",
    ""
  ],
  ["11420", "כָּפִיס", "קרש, קורת עץ", ""],
  ["11421", "לַבְלָר", "פקיד, מזכיר", ""],
  ["11422", "לוֹיָאלִי", "נאמן, שומר אמונים", ""],
  ["11423", "לָטֶנְטי", "חבוי, כמוס, רדום (להיפך: אוֹבֵרְטִי)", ""],
  ["11424", "לְעֵילָא וּלְעֵילָא", "הטוב ביותר, המובחר", ""],
  ["11425", "מַבּוּעַ", "מעיין, מקור, יסוד", ""],
  [
    "11426",
    "מוֹנוֹגַמְיָה",
    "נשיאת אשה אחת בלבד (להיפך: ביגמיה, פוליגמיה)",
    ""
  ],
  ["11427", "מוֹנוֹתֵאִיזְם", "אמונה באל אחד", ""],
  ["11428", "מַזְלֵף", "משפך", ""],
  ["11429", "מְחַרְחֵר (רִיב)", "סכסכן, איש מדון", ""],
  ["11430", "מִיטַת סְדוֹם", "מקום או מצב שאין בו חופש פעולה", ""],
  ["11431", "מָלַק", "ערף את ראשו של בעל חיים", ""],
  ["11432", "מַסָּד", "בסיס, יסוד", ""],
  ["11433", "מְסוּפָּק", "מלא ספקות, שרוי בספק", ""],
  ["11434", "מְסוּפָּק", "בא על סיפוקו", ""],
  ["11435", "מְעֻקָּר", "שעבר חיטוי מוחלט מכל החיידקים שבו, סטֶרילי", ""],
  ["11436", "מַקְצוּעָה", "אחד מכליו של הנגר המשמש להחלקת עצים", ""],
  ["11437", "מַרְבֵּק", "מקום בו מפטמים בעלי חיים", ""],
  ["11438", "מַרְחֶשֶׁת ", "מחבת לטיגון", ""],
  [
    "11439",
    "מַרְצֵע",
    "מכשיר ניקוב (עבד נרצע - עבד עולם שסומן ע'י ניקוב אוזנו)",
    ""
  ],
  ["11440", "מָשַׁךְ בְּעֵט", "עסק בכתיבה, כתב דברי ספרות", ""],
  ["11442", "מַשְׂכִּית", "קישוט, תכשיט", ""],
  ["11441", "מַשְׂכִּית", "ציור, תמונה", ""],
  ["11444", "נְאָקָה", "אנחת כאב, גניחה", ""],
  ["11443", "נָאקָה", "הנקבה של הגמל", ""],
  ["11445", "נֶחְבָּא אֶל הַכֵּלִים", "מי שאינו יהיר ואינו מתרברב, צנוע", ""],
  ["11446", "נָס לֵחוֹ", "איבד מערכו, איבד מכוחו", ""],
  ["11447", "נֶקֶב / נַקְבּוּבִית", "חור קטן, חריר", ""],
  ["11449", "סַדָן", "הקטע הבולט מן הקרקע של העץ לאחר שנכרת, גדם, גדע", ""],
  [
    "11448",
    "סַדָן",
    "בסיס מעץ או מתכת עליו מניחים חומרים שונים כדי לעבדם (בין הפטיש לסדן - נמצא במצב ללא מוצא)",
    ""
  ],
  ["11450", "עוֹלָל / עוֹלֵל", "תינוק", ""],
  ["11451", "עָטָה", "לבש, התכסה", ""],
  ["11452", "עָיִר", "חמור צעיר", ""],
  ["11453", "עָרַג ", "זעק, התחנן", ""],
  ["11454", "עָרַג ", "רצה מאוד, השתוקק, התגעגע", ""],
  ["11456", "עֲרִירִי", "חסר צאצאים", ""],
  ["11455", "עֲרִירִי", "בודד, גלמוד, מיותם", ""],
  [
    "11457",
    "עֲשָׂבִים שּׁוֹטִים",
    "דברים שליליים שיש להיפטר מהם, עשבים רעים",
    ""
  ],
  [
    "11458",
    "פוֹרְס מָז'וֹר",
    "אירוע שנמצא מעבר לשליטתנו ושלא ניתן לצפות מראש, יד הגורל",
    ""
  ],
  [
    "11459",
    "פּוֹשֵט עוֹרוֹת",
    "מי שגובה מחיר מופקע על מרכולתו או שירותיו, נצלן",
    ""
  ],
  ["11460", "פַּרְגּוֹל", "שוט", ""],
  ["11461", "פְּרֶמְיֵירָה", "הצגת הבכורה, ההופעה הראשונה", ""],
  ["11462", "צִיקְלוֹן", "סופה", ""],
  ["11463", "צִיקְלוֹן", "תרמיל", ""],
  ["11464", "צַיְקָן", "קמצן, צר עין", ""],
  ["11465", "צָפִיר", "תַּיִשׁ", ""],
  ["11466", "קֻבַּעַת", "גביע, כוס או קערה עם 'רגל'", ""],
  ["11467", "קוֹהֶרֶנְטִי", "שיש קשר בין חלקיו, רציף, עקבי", ""],
  ["11468", "קוֹקֵטִי", "גנדרן, מתחנחן", ""],
  ["11469", "קוֹרֶקְטִי", "נאות, ענייני", ""],
  ["11470", "קִלְטֵר", "הפך את האדמה, פורר את האדמה", ""],
  ["11471", "קָפָא עַל שְׁמָרָיו", "היה שמרני, לא התקדם", ""],
  ["11472", "קֹצֶר רוּחַ", "חוסר סובלנות", ""],
  ["11473", "קֶרֶת", "עיר, קריה", ""],
  [
    "11474",
    "קַרְתָן",
    "כינוי גנאי לאדם שמרן (קַרְתָּנוּת = פרובינציאליות)",
    ""
  ],
  ["11475", "קַרְתָּנִי", "בן עיר קטנה", ""],
  ["11476", "רוֹדֵף שְׂרָרָה", "כינוי לתאב כוח והשפעה", ""],
  ["11477", "שִׁבֹּלֶת", "גבעול התבואה עצמו", ""],
  ["11478", "שָׁדוּד", "עייף מאוד, מדוכא,", ""],
  ["11479", "שׁוֹבֵב אֶת נַפְשׁוֹ", "עודד אותו, דרבן אותו", ""],
  ["11480", "שָׂטַם", "שנא, תיעב", ""],
  ["11481", "שֶׂכְוִי", "תרנגול", ""],
  ["11482", "שְׁנָתוֹת", "קווי סימון על כלי למדידת כמות", ""],
  ["11483", "שָׁעָה קַלָּה", "פרק זמן קצר", ""],
  ["11484", "שַׁפִּיר", "טוב, בסדר, מניח את הדעת", ""],
  [
    "11485",
    "שָׂפַת",
    "שם כלי לחימום על האש (שפת את הקומקום - שם את הקומקום על האש)",
    ""
  ],
  ["11486", "שָׁקַט עַל שְׁמָרָיו", "היה שמרני, לא התקדם", ""],
  ["11487", "תּוּגָה", "דכדוך, דכאון", ""],
  ["11488", "תּוֹפֶת", "גיהינום, מקום נורא אשר יש בו סכנת חיים", ""],
  ["11489", "תַּרְוָוד", "כף מבשלים, מצקת", ""],

  [
    "11490",
    "אֶבֶן דֶּרֶךְ",
    "נקודת ציון המסמנת אירוע משמעותי בהשתלשלות של מאורעות",
    ""
  ],
  [
    "11491",
    "אֶבֶן רֵחַיִים עַל צַוָּוארוֹ",
    "אחריות כבדה שמעיקה עליו וממנה לא יכול להשתחרר, קושי רב",
    ""
  ],
  ["11492", "אֲבַק אָדָם", "אדם חסר ערך", ""],
  [
    "11493",
    "אַדֶּרֶת",
    "גלימה, מעיל עליון רחב (בעיקר של איש עשיר או מכובד)",
    ""
  ],
  ["11494", "אוד", "גזיר (חתיכת) עץ שלא נשרף כולו", ""],
  [
    "11495",
    "אוֹפּוֹרְטוּנִיזְם",
    "ניצול הזדמנויות (בדרך כלל בהקשר שלילי) (הזדמנות - opportunity)",
    ""
  ],
  ["11496", "אוֹרֶךְ רוּחַ", "סבלנות, מתינות", ""],
  [
    "11497",
    "אֲחוּז תְּזָזִית",
    "חסר מנוחה, בעל מרץ בלתי נדלה ובלתי נשלט, נסער",
    ""
  ],
  ["11498", "אֵימָתַי", "מתי?", ""],
  [
    "11499",
    "אֵין יָדוֹ מַשֶּׂגֶת",
    "אין ביכולתו להשיג, אינו יכול לרכוש או לממן",
    ""
  ],
  ["11500", "אִישׁ קְּצָווֹת", "אדם קיצוני", ""],
  ["11501", "אִלְחוּשׁ", "גרימה לחוסר תחושה בחלק בגוף, הרדמה", ""],
  ["11502", "אַלְיָה וְקוֹץ בָּהּ", "דבר טוב שיש בו פגם קטן", ""],
  ["11503", "אַמְבָּר", "אסם, מחסן תבואה", ""],
  ["11504", "אִמֵּץ אֶת יָדָיו", "עודד אותו, דרבן אותו", ""],
  ["11505", "אַרְכָאִי", "מיושן, עתיק, קדום, ששייך לעבר", ""],
  ["11506", "אַרְכִיפֶּלָג", "קבוצת איים", ""],
  ["11507", "אֶשְׁנָב", "חלון קטן, פתח", ""],
  ["11508", "אַשְׁפָּרָה", "עיבוד ושיפור האריג", ""],
  [
    "11509",
    "אַשְׁפָּרָה",
    "גימור איכותי ומשופר של מוצר (בדרך כלל טקסטיל או בטון)",
    ""
  ],
  ["11510", "בָּא בִּדְבָרִים", "דיבר עמו, שוחח", ""],
  ["11511", "בָּא כֹּחַ", "מורשה, נציג", ""],
  ["11512", "בָּאוּ לְעֵמֶק הַשָּׁוֶוה", "התפשרו, הסכימו, השתוו", ""],
  ["11513", "בּוּסְתָן", "גן של עצי פרי", ""],
  ["11514", "בַּחֲרוּת", "תחילת תקופת הבגרות (מהמילה בחור)", ""],
  [
    "11515",
    "בֵּין הַעַרְבַּיִם",
    "הזמן בין שקיעת השמש ועד שמחשיך, לפנות ערב",
    ""
  ],
  ["11516", "בֵּין הַשְׁמָשוֹת", "בין ערביים, בזמן דמדומים, לפנות ערב", ""],
  ["11517", "בֵּית מִטְבָּחַיִם", "משחטה", ""],
  ["11518", "בֵּית נְתִיבוֹת", "אולם נוסעים בשדה תעופה או בתחנת רכבת", ""],
  [
    "11519",
    "בֵּית תַּמְחוּי",
    "מקום שמחלק אוכל לעניים בחינם או תמורת תשלום סמלי",
    ""
  ],
  ["11520", "בִּמְחִי יָד", "בלי מאמץ, בהינף יד", ""],
  ["11521", "בֶּן-לְוָיָה", "מי שמתלווה לאדם אחר, שהולך איתו", ""],
  [
    "11522",
    "בַּעֲלֵי שְׂרָרָה",
    "אנשים הנושאים בתפקיד רשמי כלשהו, אנשים רמי דרג בשלטון (בנימת גנאי)",
    ""
  ],
  ["11523", "בַּעֲרוֹב יָמָיו", "בסוף חייו, לעת זקנתו", ""],
  ["11524", "בֵּרָא", "כרת צמחיה, יצר קרחת יער", ""],
  ["11525", "בְּשׂוֹרָה", "ידיעה חשובה", ""],
  ["11526", "גֶּבֶר", "תרנגול", ""],
  ["11527", "גּוּזְמָה", "הגזמה, הפרזה", ""],
  ["11529", "גּוּשְׁפַּנְקָה", "אישור סופי", ""],
  ["11528", "גּוּשְׁפַּנְקָה", "חותמת רשמית של אדם רם מעלה", ""],
  ["11531", "גָּזַר", "פסק, קבע את גזר הדין", ""],
  ["11530", "גָּזַר", "הסיק", ""],
  [
    "11532",
    "גְּזֵרָה שָׁוָוה",
    "השוואה בין שני דברים ולמידה על האחד מן האחר",
    ""
  ],
  [
    "11533",
    "גַּיִס חֲמִישִׁי",
    "כינוי לבוגדים מבית, גורם פנימי המסייע לאויב",
    ""
  ],
  ["11534", "גָּמַר בְּדַעְתּוֹ / בְּלִבּוֹ / אוֹמֶר", "החליט", ""],
  ["11536", "דִּיאָלוֹג", "דו שיח", ""],
  ["11537", "דְּיוֹטָה", "קומה בבית", ""],
  ["11538", "דיוֹתָה", "כלי קיבול לדיו", ""],
  ["11535", "דֹּכִי", "רעש הגלים כשהם מתנפצים", ""],
  ["11539", "דַלְפוֹן", "עני", ""],
  ["11540", "הִבְרִיחַ", "נעל, סגר באמצעות בריח", ""],
  ["11541", "הִבְרִיחַ", "הכניס פנימה בניגוד לחוק", ""],
  ["11542", "הִגִיר", "שפך, הזיל", ""],
  ["11543", "הוֹלֵךְ בָּטֵל", "בטלן", ""],
  ["11544", "הוֹקִיעַ", "תקף מילולית, מתח ביקורת, גינה", ""],
  ["11545", "הוֹקִיעַ", "המית מישהו בפומבי בצליבה או בתלייה", ""],
  ["11546", "הֵזֵם", "הוכיח כי דבריו של אדם אחר אינם נכונים", ""],
  ["11547", "הֶחְפִּיץ", "התייחס לאדם כאילו היה חפץ", ""],
  ["11548", "הִכָּה עַל חֵטְא", "התחרט על מעשה רע, הודה, חזר בתשובה", ""],
  ["11549", "הִלֵּך אֵימִים", "הפחיד אותו מאוד, אִיֵּם, הרתיע", ""],
  ["11550", "הֵנֵץ", "הוציא פרחים או ניצנים, לבלב, פרח", ""],
  [
    "11551",
    "הִשְלִיך אַחֲרֵי גֵּווֹ",
    "התנער ממנו והתכחש לקשר אליו, הפנה לו עורף (גֵּווֹ=גופו)",
    ""
  ],
  ["11552", "הִתְבַּשֵּׂם", "השתכר, התבסם", ""],
  ["11553", "הִתְבַּשֵּׂם", "הזליף בושם על הגוף", ""],
  ["11554", "הִתְפַּקֵּר", "חזר בשאלה, כפר", ""],
  ["11556", "זִבּוּרִית", "החלק הגרוע, סחורה גרועה", ""],
  ["11555", "זִבּוּרִית", "אדמה גרועה שאינה טובה לשימוש חקלאי", ""],
  ["11557", "זַג", "קליפת הפרי (בדרך כלל משמש בהתייחסות לקליפת הענב)", ""],
  ["11558", "זְחִיחוּת דַּעַת", "שביעות רצון עצמית הגובלת ביהירות", ""],
  ["11559", "חָוָוק", "מדרגה, שלב בסולם", ""],
  ["11560", "חִילָּה", "המתיק", ""],
  ["11561", "חִילָּה אֶת פָּנָיו", "התחנן (חילה - המתיק)", ""],
  ["11562", "חִישוּר", "אחת העצמות בכף היד", ""],
  ["11563", "חִישוּר", "מוט המחבר את מרכז הגלגל עם החישוק", ""],
  ["11564", "חָמַד לָצוֹן", "שם מישהו לצחוק, התלוצץ", ""],
  ["11565", "חֲמַרְמֹרֶת", "האנג-אובר", ""],
  ["11566", "חָרֵד", "מפחד, מבוהל, מזועזע", ""],
  [
    "11567",
    "חֶרְמֵשׁ",
    "כלי לקצירת תבואה המורכב מסכין בצורת קשת המחוברת למקל ארוך",
    ""
  ],
  ["11568", "חָתוּל בַּשַּׁק", "כינוי לדבר שטיבו לא ידוע", ""],
  ["11569", "טַס", "מגש", ""],
  ["11570", "טַפְסָן", "פועל בניין מומחה בהתקנת תבניות ליציקת בטון", ""],
  ["11572", "טַפְסָר", "דרגת קצונה במכבי-אש", ""],
  ["11571", "טַפְסָר", "פקיד", ""],
  ["11573", "טָרַף", "ערבב (טרף קלפים)", ""],
  ["11574", "יָגוֹר", "פוחד, חושש (אשר יגורתי בא - הדבר ממנו חששתי קרה)", ""],
  ["11575", "יוֹשֵׁב עַל הַגָּדֵר", "נמנע מלנקוט עמדה, נמנע מלצדד", ""],
  ["11576", "יִחֵל", "קיווה", ""],
  ["11577", "יִצְהָר", "שמן זך", ""],
  [
    "11578",
    "יָשַׁב עַל הַמְּדוֹכָה",
    "התלבט, התחבט (מדוכה - קערה לכתישת תבלינים בעזרת עֵלִי - 'כתש' את הבעיה)",
    ""
  ],
  ["11579", "כְּבָרָה", "מסננת, כלי שתשתיתו עשויה רשת", ""],
  ["11580", "כִּיוּל", "כיוון, איפוס", ""],
  ["11581", "כִּיפֵחַ", "גבוה מאוד", ""],
  ["11582", "כִּלְאַחַר יָד", "בלי כוונה מיוחדת, כבדרך אגב", ""],
  ["11583", "לֹא בִּכְדִי", "לא לשווא, לא לחינם", ""],
  ["11584", "לְהַוָּתִי", "לצערו הרב, למזלו הרע", ""],
  ["11585", "לוֹקָלִי ", "מקומי (local), אזורי", ""],
  [
    "11586",
    "לֵיל שִמּוּרִים",
    "לילה לבן, לילה שערים בו (בדרך כלל מתוך דאגה)",
    ""
  ],
  ["11587", "מָאוֹר", "גוף מפיץ אור, זוהר", ""],
  ["11588", "מַגְלֵב", "שוט", ""],
  ["11589", "מְדוּשָן עוֹנֶג", "מרוצה מאוד, מלא נחת, מסופק", ""],
  ["11590", "מוּג לֵב", "פחדן, רַך לֵב", ""],
  ["11591", "מוֹנוֹלוֹג", "נאום, שיח יחיד", ""],
  ["11592", "מוֹץ", "קליפת גרגירי הדגנים", ""],
  [
    "11593",
    "מוּשְׂכָּל רִאשׁוֹן",
    "הנחת יסוד שאינה ניתנת להוכחה, אולם מתייחסים אליה כאילו היא נכונה ולא ניתנת לערעור",
    ""
  ],
  ["11594", "מָט", "זז במקומו בחוסר יציבות", ""],
  ["11595", "מָט", "מטושטש, עמום", ""],
  ["11596", "מַט לִנְפּוֹל", "לא יציב, רעוע", ""],
  ["11597", "מַכְלֵב", "מהדק, שדכן", ""],
  ["11598", "מִלְּגוֹ / מִלְגַו", "מבפנים, מתוך (להיפך: מִלְבַּר)", ""],
  ["11599", "מִלְּגוֹ וּמִלְבַּר", "מבפנים ומבחוץ", ""],
  ["11600", "מֶלוֹדִי", "בעל מנגינה נעימה", ""],
  ["11602", "מְלִיצָה", "דיבור גבוה, לעתים תוך ציטוט פסוקים", ""],
  ["11601", "מְלִיצָה", "דברי חכמה, לעתים בסגנון פיוטי עם חריזה", ""],
  ["11603", "מַמְגוּרָה", "אסם תבואה גדול", ""],
  ["11604", "מְסוּקָס", "מחוספס, מיובל", ""],
  ["11605", "מִסְרוֹן", "אס-אם-אס, הודעת טקסט", ""],
  ["11606", "מְעוּשֶׂה", "לא אמיתי, מזויף, מלאכותי", ""],
  ["11607", "מָרָה שְחוֹרָה", "דיכאון, ראיית שחורות, פסימיות", ""],
  ["11608", "מָרִית", "כלי מריחה הדומה לסכין עם להב קהה, שפכטל", ""],
  ["11609", "מַשׂוֹא פָּנִים", "העדפה של אחד הצדדים, אפליה", ""],
  ["11610", "מַשְכּוּכִית", "כינוי לאייל המושך את העדר אחריו", ""],
  ["11611", "נָבוּב", "חלול, ריק. בהשאלה: ריקני, שטחי", ""],
  ["11612", "נִיבוֹן", "אוסף ניבים ופתגמים, מילון ניבים", ""],
  ["11614", "נִמְלַך בְּדַעֲתוֹ", "שינה את דעתו, התחרט", ""],
  ["11613", "נִמְלַך בְּדַעֲתוֹ", "חשב והתלבט לגבי סוגיה כלשהי", ""],
  ["11615", "נִמְלַךְ בּוֹ", "התייעץ איתו, נועץ בו", ""],
  ["11616", "נָפִיל", "ענק", ""],
  ["11617", "נִצְרָך", "אדם עני שזקוק לעזרה", ""],
  [
    "11618",
    "נֶקְטָר",
    "משקה האלים, משקה פירות 'איכותי' יותר ממשקאות תרכיז למיניהם",
    ""
  ],
  ["11619", "סְגַלְגַל", "אליפטי, בצורת ביצה", ""],
  ["11621", "סָר", "פקד, בא, לבקר", ""],
  ["11620", "סָר", "פנה, זז, יצא", ""],
  ["11623", "סִרְהֵב", "עמד על התנגדותו למשהו, לא הסכים", ""],
  ["11622", "סִרְהֵב", "הפציר, ביקש", ""],
  ["11624", "עוֹקֵר הָרִים", "בעל שכל חריף, כינוי לאדם חריף בדברי תורה", ""],
  ["11625", "עוּר!", "קום! התעורר!", ""],
  ["11626", "עִזָבוֹן", "ירושה", ""],
  ["11627", "עִידִּית", "אדמה משובחת. בהשאלה: דבר משובח, החלק המובחר", ""],
  [
    "11628",
    "עֵינַיִים טְרוּטוֹת",
    "עיניים סגורות למחצה (לרוב עקב מחסור בשינה)",
    ""
  ],
  ["11629", "עָלֶה נִידָּף", "חלש וחסר אונים, חדל ישע", ""],
  ["11630", "עַפְרָה", "סלע המכיל מתכת, מחצב", ""],
  ["11631", "עַפְרוֹת זָהָב", "שכבות של אדמה המכילה זהב", ""],
  ["11635", "עִקֵּר", "חיטא, טיהר", ""],
  ["11637", "עִקֵּר", "גרם לו להיות עקר (חסר יכולת להביא צאצאים)", ""],
  ["11633", "עָקַר", "תלש, שלף, קרע אותו ממקומו", ""],
  ["11636", "עָקַר", "היגר, ירד (מהארץ)", ""],
  ["11632", "עָקָר", "שאינו פורה, לא מסוגל להוליד צאצאים", ""],
  ["11634", "עָקָר", "חסר טעם או תועלת", ""],
  ["11638", "עָקַר אֶת רַגְלָיו", "הלך", ""],
  ["11639", "עַרְדָלַיים", "נעליים שנועלים על נעליים כדי שלא יירטבו", ""],
  [
    "11640",
    "פֶּה מֵפִיק מַרְגָּלִיוֹת",
    "כינוי לאדם בעל כושר ביטוי יוצא מן הכלל",
    ""
  ],
  ["11641", "פּוֹלִיגַמְיָה", "נישואי גבר למספר נשים (פולי - הרבה)", ""],
  ["11642", "פָּלוּש", "פרוזדור, מסדרון", ""],
  ["11643", "פָּסַח עַל שְׁתֵּי הַסְּעִיפִּים", "היסס, לא היה עקבי", ""],
  ["11644", "פַרְהֶסְיָה", "לעיני כל, בפומבי", ""],
  ["11645", "פְּרַקְלִיט", "עורך דין, טוען", ""],
  ["11646", "צִחְצוּחַ חַרָבוֹת", "התנצחות, ויכוח סוער", ""],
  ["11647", "צִינָּה", "מגן", ""],
  ["11648", "צִינָּה", "קור", ""],
  ["11649", "צִלְצָל", "כידון המחובר לחבל ומשמש לציד ימי", ""],
  ["11650", "קִבּוֹרֶת", "שריר הזרוע", ""],
  ["11651", "קְדִימוֹן", "פרומו", ""],
  [
    "11652",
    "קוֹטֵל קָנִים",
    "אדם פשוט, חסר השכלה (מופיע בעיקר בביטוי: אינו קוטל קנים - מומחה)",
    ""
  ],
  ["11653", "קוּרְנָס", "פטיש כבד", ""],
  ["11654", "קְטַן אֱמוּנָה", "ספקן, סקפטי", ""],
  [
    "11656",
    "קִיצֵּץ אֶת כְּנָפָיו",
    "הפריע לו במשהו ומנע את חופש פעולתו, הגביל",
    ""
  ],
  ["11655", "קִיצֵּץ אֶת כְּנָפָיו", "הפיג את התלהבותו", ""],
  ["11657", "קִיתוֹן", "כד, כלי לנוזלים", ""],
  ["11658", "קָץ", "מאס, נמאס עליו", ""],
  ["11660", "קִרְקֵעַ", "ריתק לבית", ""],
  ["11659", "קִרְקֵעַ", "אסר להמריא, ריתק לקרקע", ""],
  [
    "11661",
    "רֵחַיִים",
    "מתקן משתי אבנים לטחינת גרגרי תבואה (רֶכֶב ושֶכֶב - האבן העליונה והאבן התחתונה)",
    ""
  ],
  [
    "11662",
    "רֵיחַיִים עַל צַוָּארוֹ  ",
    "אחריות כבדה שמעיקה עליו וממנה לא יכול להשתחרר, קושי רב",
    ""
  ],
  [
    "11663",
    "שְבִיב",
    "ניצוץ. בהשאלה: מעט מאד, שמץ (שביב תקווה - ניצוץ של תקווה, תקווה מועטה אך קיימת)",
    ""
  ],
  ["11664", "שִׁבֹּלֶת", "סוג של תפרת המורכבת מגרגרים צמודים", ""],
  ["11665", "שִׁבֹּלֶת", "זרם מים חזק מאוד, מערבולת", ""],
  ["11667", "שָׁדוּף", "ריק, חסר תוכן", ""],
  ["11666", "שָׁדוּף", "דק, רזה", ""],
  ["11668", "שׁוּר", "הבט, ראה, הסתכל (בציווי)", ""],
  ["11670", "שָׁח / שָׁחָה", "קד קידה, השתחווה, השתטח", ""],
  ["11669", "שָׁח / שָׁחָה", "התכופף (שחוח - כפוף)", ""],
  ["11671", "שִׂחֲקָה לוֹ הַשָּׁעָה", "התמזל מזלו", ""],
  ["11672", "שִיוָּוה (בְּנַפְשוֹ)", "דמיין", ""],
  [
    "11673",
    "שִׂיחוֹן",
    "ספרון המכיל משפטים ותרגומם לצורך לימוד שפה או ככלי עזר לתיירים",
    ""
  ],
  ["11674", "שְׂרָרָה", "שלטון, סמכות", ""],
  ["11675", "תּוּפִין", "מאפה, עוגיה", ""],
  ["11676", "תְּזָזִית", "שיגעון, טירוף", ""],

  ["11677", "אֶבְיוֹן", "עני, חסר-כל", ""],
  ["11679", "אִבֵּק", "ניקה מאבק, הוציא אבק", ""],
  ["11678", "אִבֵּק", "פיזר אבקה על משהו", ""],
  [
    "11680",
    "אוּרִים וְתֻמִּים",
    "מומחה, מקור מוסמך שאין לערער עליו, סמכות עליונה",
    ""
  ],
  ["11681", "אֶזוֹטֶרִי", "שולי, חסר עניין לציבור", ""],
  ["11682", "אֶזוֹטֶרִי", "פנימי, כמוס, ידוע רק לחוג מצומצם", ""],
  ["11683", "אִילוּחַ", "זיהום, מוגלה", ""],
  ["11684", "אִינְטֶגְרָלִי", "שלם, שכל חלקיו נמצאים", ""],
  ["11685", "אִינְטֶגְרָלִי", "חלק חיוני ובלתי נפרד, שאי-אפשר בלעדיו", ""],
  ["11686", "אֶפִּילוֹג", "סוף, סיום", ""],
  ["11687", "אֶפִּילוֹג", "קטע קצר שנוסף לספר לאחר סיומו", ""],
  ["11689", "אִצְטְלָה", "כבוד, גדולה או משרה חשובה שהם בדרך כלל מדומים", ""],
  [
    "11688",
    "אִצְטְלָה",
    "גלימה, בגד עליון רחב שנלבש לרוב על ידי אנשים מכובדים",
    ""
  ],
  ["11690", "אִשְׁרוּר", "אישור סופי", ""],
  [
    "11691",
    "אֶתְנָן",
    "במקור: שכר יצאנית. בהשאלה: שכר בתמורה לדבר לא מוסרי, מתנה או טובת הנאה בכדי להשיג רווח לא הוגן",
    ""
  ],
  [
    "11692",
    "בְּאִיבּוֹ",
    "בראשית דרכו, בצעירותו (נקטף באיבו - מת בגיל צעיר)",
    ""
  ],
  ["11693", "בְּדוּתָה", "שקר, דבר בדוי", ""],
  ["11694", "בְּדַיָה", "שקר (בָּדָה - המציא)", ""],
  ["11695", "בּוּלְמוּס", "רעב חזק", ""],
  ["11696", "בּוּלְמוּס", "תאווה, חשק עז", ""],
  ["11698", "בַּחֲטָף", "ללא סימן מוקדם, לפתע", ""],
  ["11697", "בַּחֲטָף", "בחופזה, במהירות", ""],
  [
    "11699",
    "בִּיגַמְיָה",
    "נשואי גבר אחד לשתי נשים בו זמנית, נישואים כפולים",
    ""
  ],
  ["11700", "בָּלוּם", "סתום, אטום, סגור", ""],
  ["11701", "בָּלוּם", "מלא עד הסוף, עמוס, עשיר ב-", ""],
  [
    "11702",
    "בַּרְדָס",
    "כיסוי ראש המחובר אל הבגד מאחור, 'קפוצ'ון'",
    ""
  ],
  [
    "11703",
    "גָחוֹן",
    "בטן (בעיקר של בעלי חיים, מקובל גם בכלי רכב, גחון הטנק - החלק התחתון של הטנק)",
    ""
  ],
  ["11704", "גִילְדָה", "קבוצת אנשים בעלי אינטרס משותף", ""],
  ["11706", "דְּבוּקָּה", "קבוצת צנחנים הצונחים יחדיו", ""],
  ["11705", "דְּבוּקָּה", "צרור פצצות המשוגרות יחדיו", ""],
  ["11707", "דְּבֵלָה", "גוש של תאנים מיובשות", ""],
  ["11708", "דָּגָן", "כל תבואת השדה", ""],
  ["11709", "דוּאָלִי", "בעל שני צדדים", ""],
  ["11710", "דּוֹרוֹן", "מתנה", ""],
  ["11711", "דֶּחִי", "כישלון, מעידה", ""],
  ["11712", "דָּלוּחַ", "לא צלול, עכור", ""],
  ["11713", "דִּמְדּוּמִים", "אור חיוור שניתן לראות לאחר השקיעה", ""],
  ["11714", "דִמְעוֹת שָׁלִישׁ", "בכי גדול, בדמעות ובתחנונים רבים", ""],
  ["11715", "דֹּפִי", "פגם, ליקוי", ""],
  ["11716", "דַפְתָר", "פנקס, מחברת", ""],
  ["11717", "דֵרָאוֹן", "בושה, חרפה", ""],
  ["11718", "דַרְגָש", "שרפרף, כסא נמוך, ספסל נמוך לשכיבה", ""],
  ["11719", "הָבִיל", "ספוג הבל, מלא אדי מים", ""],
  ["11721", "הִבְלִיעַ", "אמר דברים בצורה מרומזת", ""],
  ["11720", "הִבְלִיעַ", "הסתיר דבר בתוך דבר, הכמין", ""],
  ["11722", "הָגִיג", "מחשבה, הרהור, שרעפים, חקרי לב", ""],
  ["11723", "הִגִּיהַּ", "תיקן שגיאות בטקסט כתוב", ""],
  ["11724", "הִגְלִיד", "התאחה, נרפא", ""],
  ["11725", "הִדִּיר", "מנע, שלל, אסר", ""],
  ["11726", "הַזָחָה", "הרווח שבתחילת פיסקה חדשה", ""],
  ["11727", "הֵזִיחַ", "הזיז משהו ממקום למקום, הסיט", ""],
  ["11728", "הִילֵּךְ", "שוטט, הלך לאיטו ללא מטרה מסוימת", ""],
  ["11729", "הִכָּה גַּלִּים", "הביא לשינוי משמעותי בחשיבה המקובלת", ""],
  ["11730", "הִכָּה גַּלִּים", "עורר עניין, התפרסם", ""],
  ["11731", "הִכָּה שׁוֹרֵש", "נעשה נפוץ ורווח, התקבל", ""],
  ["11732", "הֶעֱתִיק", "הזיז, העביר ממקום למקום", ""],
  ["11733", "הֶקְהִיל", "גרם לאנשים להתאסף, גם להם להפוך לקהל", ""],
  ["11734", "הֶרֶף", "הפסק! די! (ללא הרף - ללא הפסקה, כל הזמן)", ""],
  ["11735", "הִתִּיק", "הזיז, העביר ממקום למקום, הסיט", ""],
  ["11736", "זָב", "מטפטף, נוזל", ""],
  ["11737", "זָב חֹטֶם", "אדם פחות ערך", ""],
  ["11738", "זָב חֹטֶם", "כינוי גנאי לאדם חסר ניסיון, צעיר", ""],
  ["11739", "זֶבֶד", "מתנה, שי", ""],
  ["11740", "זוֹט", "קרקעית, תחתית של כלי", ""],
  ["11741", "זוּטָא", "קטן מאוד, זעיר, פעוט, שולי", ""],
  ["11742", "זוּטוֹת", "דברים של מה בכך, אפסי, זניח, חסר משמעות", ""],
  ["11744", "זוּלָת", "אדם או בני אדם, שאר האנשים", ""],
  ["11743", "זוּלָת", "חוץ מ-, פרט ל-", ""],
  ["11745", "זוּלָתָן", "אדם המעדיף את טובת האחר על פני טובתו שלו", ""],
  ["11746", "זָח", "זז, זע, נע", ""],
  ["11747", "חֵבֶל", "ייסורים, מכאוב, בציפייה לדבר חיובי (חבלי לידה)", ""],
  ["11748", "חֶבֶל", "אזור, מחוז", ""],
  ["11749", "חֲבַרְבָּר", "בעל חברבורות, מנומר", ""],
  ["11750", "חָג", "נע במעגלים סביב דבר מה, הסתובב", ""],
  ["11751", "חָג", "צייר עיגול (מחוגה - מכשיר לסרטוט מעגל)", ""],
  ["11752", "חוֹזֵר", "הודעה המופצת ברבים, תצהיר", ""],
  ["11753", "חֲטָף", "מהירות גבוהה (מבט חטוף - מבט מהיר)", ""],
  ["11754", "חִיגֵּר", "בעל מום ברגליו, צולע", ""],
  [
    "11755",
    "חֲכִירָה",
    "השכרת אדמה או נכס כלשהו למשך תקופת זמן ידוע מראש בתמורה לתשלום כספי",
    ""
  ],
  ["11756", "חֵלְכָּאִי", "עני, אדם חלש ועלוב", ""],
  ["11757", "חָרֵב", "הרוס, שומם, נטוש", ""],
  ["11758", "חָרֵב", "יבש", ""],
  ["11759", "חָרָבָה", "אדמה שאינה מכוסה מים, יבשה", ""],
  [
    "11760",
    "טַבּוּלָה רָאסָה",
    "אינו יודע דבר בתחום מסוים, חסר דעה בנושא מסוים",
    ""
  ],
  ["11761", "יוֹקֵב", "בעל יקב", ""],
  ["11762", "יוֹרֶה", "הגשם הראשון", ""],
  ["11763", "יַנוּקָא", "תינוק", ""],
  ["11764", "יַעֲרָה", "טבלת דונג שהדבורים בנו בה תאים ומילאו אותה בדבש", ""],
  ["11765", "כְּסִיל", "טיפש, שוטה", ""],
  ["11766", "כְּפִיר", "אריה צעיר", ""],
  ["11767", "כָּפַן", "רעב", ""],
  ["11768", "כָּפַת", "קשר, כבל", ""],
  ["11769", "לַאֲשוּרוֹ", "כהלכתו, במדויק", ""],
  ["11770", "לָבִיא", "אריה", ""],
  ["11771", "לִבֵּן", "ברר סוגיה מסוימת כדי להגיע לפתרון או למסקנה", ""],
  ["11772", "לִבֵּן", "הלהטת מתכת עד שצבעה נהיה ללבן", ""],
  ["11773", "לִפְרָקִים", "מדי פעם, מעת לעת", ""],
  ["11775", "מַאְפֵּלְיָה", "מקום משכנו של הרשע", ""],
  ["11774", "מַאְפֵּלְיָה", "חושך מוחלט, אפלה", ""],
  ["11776", "מִבְדוֹק", "מבנה מיוחד המיועד לתיקון כלי שייט", ""],
  ["11777", "מְבוּזָּר", "מפוזר", ""],
  ["11778", "מָגוֹר", "פחד, אימה", ""],
  ["11779", "מִגֵּר", "הביס את האויב, ניצח", ""],
  ["11780", "מִגֵּר", "השמיד משהו לחלוטין, חיסל", ""],
  ["11782", "מְהֻקְצָע", "מסוגנן, אלגנטי", ""],
  ["11781", "מְהֻקְצָע", "מלוטש, עבר החלקה בעזרת מקצועה", ""],
  ["11783", "מוּבָאָה", "ציטוט", ""],
  ["11784", "מוֹרַג", "כלי דַּיִשׁ קדום (דיש - הפרדת גרגירי תבואה)", ""],
  ["11786", "מָזוֹר ", "חולי, מכאוב", ""],
  ["11785", "מָזוֹר ", "מרפא, תרופה", ""],
  ["11787", "מִזְקָקָה", "מקום לזיקוק ולהכנת משקאות חריפים", ""],
  ["11788", "מַחְבּוֹש", "עונש של כליאה", ""],
  [
    "11789",
    "מַחְבֵּצָה",
    "כלי מיוחד ששימש להכנת מוצרי חלב כמו גבינה או חמאה",
    ""
  ],
  ["11790", "מְחֻטָּט", "מלא בצלקות שנגרמו ממחלת עור כלשהי", ""],
  ["11791", "מָךְ", "עני", ""],
  ["11792", "מָלֵא תְּכוּנָה", "שוקק חיים, מלא פעילות", ""],
  ["11794", "מֵלִיץ", "מי שממליץ על מישהו ומבר בזכותו", ""],
  ["11793", "מֵלִיץ", "מתורגמן", ""],
  ["11795", "מַנְפֵּטָה", "מכונה מיוחדת המשמשת להפרדת סיבי הכותנה מהזרעים", ""],
  ["11796", "מְסוֹאָב", "מושחת מוסרית, מנוון", ""],
  ["11797", "מְסוֹאָב", "מלוכלך מאוד, מטונף", ""],
  ["11798", "מִסּוּך", "יצירת הפרדה, יצירת חציצה", ""],
  ["11799", "מִסּוּך", "שיבוש והחלשת שדות חשמליים", ""],
  ["11800", "מָסַךְ ", "מזג, ערבב משקה במשקה", ""],
  ["11801", "מַעֲרֶה הַיַּעַר", "אזור נטול עצים ביער, קרחת היער", ""],
  ["11802", "מַפְסֶלֶת", "כלי עבודה המשמש לסיתות אבן או גילוף עץ, אזמל", ""],
  ["11803", "מַק", "ריקבון", ""],
  ["11804", "מַרְזֵחַ", "משתה (בית מרזח - פאב)", ""],
  ["11805", "מֹרֶך לֵב", "הרגשת פחד וזעזוע, בעתה, אימה", ""],
  ["11806", "מַרְנִין", "משמח", ""],
  ["11807", "מַרְנִין", "מהנה ביותר, נעים", ""],
  ["11808", "מִשְׂגָּב", "מקום גבוה שמשמש כמחסה, מצודה", ""],
  ["11809", "מִשְׁוֶרֶת", "טבעת לרגל בצד האוכף", ""],
  ["11810", "מַשְׂטֵמָה", "איבה, שנאה עזה", ""],
  ["11811", "נֹאד", "שק עור לנוזלים, מימיה", ""],
  ["11812", "נָאוֹר", "מתורבת, משכיל", ""],
  ["11813", "נֶאֱלָם", "השתתק, נדם", ""],
  ["11814", "נְאָצָה", "גידוף, דברי בלע", ""],
  ["11815", "נֶבֶט", "צמח בראשית צמיחתו", ""],
  ["11816", "נִבְעָר", "בּוּר, חסר השכלה", ""],
  ["11817", "נָבַר", "חיטט, פשפש", ""],
  ["11818", "נִגַר", "נזל, זרם", ""],
  ["11819", "נָהָה", "רצה מאוד, השתוקק", ""],
  ["11820", "נְהִי", "בכי מר, יללה", ""],
  ["11821", "נוֹאָל", "טיפשי, אווילי", ""],
  ["11822", "נוֹקֵד", "מגדל צאן, רועה צאן", ""],
  ["11823", "נוֹשֶה", "מלווה", ""],
  ["11824", "נְחִירָה", "המתת בעלי חיים (בדרך כלל חזיר) על ידי דקירה", ""],
  ["11825", "נִיר", "אחו, שדה שנחרש", ""],
  ["11826", "נַכְלוּלִי", "רמאי, נוכל, מעורפל בכוונותיו", ""],
  ["11828", "נָפָה", "אזור, אחוז", ""],
  ["11827", "נָפָה", "כלי בעל נקבים קטנים לניפוי קמח, מסננת", ""],
  ["11830", "נֵצֶר", "ענף רך הצומח מהשורש", ""],
  ["11831", "נֵצֶר", "בן, צאצא", ""],
  ["11829", "נָצַר", "שמר, הגן", ""],
  ["11832", "נָשִׂיא", "ענן נושא גשם", ""],
  ["11833", "נֶשֶׁךְ", "ריבית", ""],
  ["11834", "נִשְפֶּה", "חלק ותלול", ""],
  ["11836", "סַד", "לוח מיוחד המשמש לקיבוע ותמיכה באיבר פצוע", ""],
  ["11835", "סַד", "מכשיר עינוי קדום", ""],
  ["11837", "סַהַר", "ירח, לבנה", ""],
  ["11838", "סְחִי", "אשפה, זוהמה, לכלוך", ""],
  ["11839", "סֵיפָא", "סוף, החלק האחרון (להיפך: רֵישָא)", ""],
  ["11840", "סְפּוֹרָדי", "בלתי סדיר, אקראי, ללא חוקיות קבועה", ""],
  [
    "11841",
    "סְפָר",
    "גבול, קצה, רצועת אדמה החוצצת בין מדינות (ישובי ספר - ישובי גבול)",
    ""
  ],
  ["11842", "סְתַגְּלָן", "מי שמתאים עצמו בקלות למצבים חדשים, אדפטיבי", ""],
  ["11843", "עָב", "ענן", ""],
  ["11844", "עֲבוֹתוֹת", "חבלים עבים", ""],
  ["11845", "עֲזוּבָה", "מצב של הרס והזנחה", ""],
  ["11846", "עֲלוּמִים", "ימי הנעורים", ""],
  ["11847", "עֲלָטָה", "חושך, אפלה", ""],
  ["11848", "עָשָׂה נְפָשׁוֹת", "גייס תומכים למשהו", ""],
  ["11849", "פּוּלְמוּס", "ויכוח, מחלוקת", ""],
  ["11850", "פּוּר", "מזל, גורל", ""],
  ["11851", "פּוּרְתָּא", "מעט, קצת (נחמה פּוּרְתָּא - נחמה מועטה)", ""],
  ["11852", "פִּיד", "אסון, צרה", ""],
  [
    "11853",
    "פִּיל לָבָן",
    "כינוי לפרויקט ענק שהושקע בו רבות אך אין בו שימוש",
    ""
  ],
  ["11854", "פִּילֵּל", "ציפה, קיווה", ""],
  ["11855", "פִּיסֵח", "צולע", ""],
  ["11856", "פַּרְגּוֹד", "סוג של מחיצה ניידת", ""],
  ["11857", "פְּרוֹלוֹג", "מבוא, הקדמה (להיפך: אפילוג)", ""],
  ["11858", "פִּתְחוֹן פֶּה", "אפשרות לומר דבר מה, הזדמנות לטעון משהו", ""],
  ["11859", "צְבִיר", "קבוצה (צביר עננים - קבוצת עננים)", ""],
  ["11860", "צְדִיָּה ", "כוונה רעה, זדון", ""],
  ["11861", "צַדְקָנוּת", "תכונתו של הצדקן, גלגול עיניים", ""],
  ["11862", "צָמִיג", "סמיך, דביק", ""],
  [
    "11863",
    "צִקְצוּק",
    "קול הנוצר על ידי הנעת הלשון בין השיניים לחניכיים (לרוב מציין חוסר אישור או שלילה)",
    ""
  ],
  ["11864", "צָרוּף", "מזוקק, ברור וטהור (תענוג צרוף - הנאה מוחלטת)", ""],
  [
    "11865",
    "צָרַר",
    "התנכל, התנהג באיבה כלפי מישהו, היה אויבו (צר - אויב)",
    ""
  ],
  ["11866", "צָרַר", "קשר יחד לחבילה", ""],
  ["11867", "קְבָסְתָּן", "טרחן, מישהו שמעורר בחילה וגועל", ""],
  ["11868", "קוֹבַע", "קסדה", ""],
  ["11869", "קוֹלָן", "מכשיר המשמש לכיוון כלי נגינה", ""],
  ["11870", "קִטְרֶג", "האשים, הביא לדין", ""],
  ["11871", "קִיקְיוֹנִי", "זמני, ארעי", ""],
  ["11872", "קָלוֹן", "בושה, חרפה (אות קלון - סימן גנאי)", ""],
  ["11873", "קְלוֹקֵל", "שטיבו גרוע, איכותו ירודה", ""],
  ["11874", "קִלֵּס", "הילל, שיבח", ""],
  ["11875", "קִלֵּס", "קִילֵל, לעג, השפיל, ביזה", ""],
  ["11876", "קַנְטְרָן", "סכסכן, נוהג להתגרות ולהרגיז", ""],
  ["11877", "קִנְיָן", "רכוש, נכס", ""],
  ["11878", "קַפֶּנְדַרְיָא / קַפַּנְדְּרִיָּה", "דרך קיצור", ""],
  ["11879", "קַרְדּוֹם", "מכשיר לחטיבת עצים, גרזן", ""],
  ["11880", "קַרְפִּיף", "שטח מגודר", ""],
  ["11881", "רְבָב", "כתם, פגם, מום מוסרי (ללא רבב - מושלם)", ""],
  ["11882", "רָבִיד", "שרשרת לצוואר, תליון", ""],
  ["11883", "רָגַן", "התלונן", ""],
  ["11884", "רַהַב", "התרברבות, גאווה", ""],
  ["11885", "רָהוּי", "הססני, חסר בטחון", ""],
  ["11886", "רֵישָא", "התחלה, החלק הראשון (להיפך: סֵיפָא)", ""],
  ["11887", "רַכָּב", "עֶגְלוֹן, 'נהג' של כרכרה", ""],
  ["11888", "רִנֵּן", "שר", ""],
  ["11889", "רִנֵּן", "ריכל", ""],
  ["11890", "רַפְרֶפֶת", "סוג של קינוח אוורירי, פודינג", ""],
  ["11891", "רָצַץ", "שבר, ריסק, ניפץ", ""],
  ["11892", "רָקַח", "המציא (סיפור לא אמיתי), הכין תכנית (בהקשר שלילי)", ""],
  ["11893", "רָקַח", "הכין תרופה או ריבה (מרקחת - ריבה, מרקחה - בֹּשֶׂם)", ""],
  ["11894", "רָש", "עני", ""],
  ["11895", "שִׁגָיוֹן", "מחשבה שאינה מרפה", ""],
  ["11896", "שִׁהוּי", "עיכוב, איחור", ""],
  ["11897", "שׁוֹחֵר", "חובב, מתעניין ב-, (למשל: שוחר מדע)", ""],
  ["11898", "שׁוֹחֵר", "נאמן, ידיד (למשל: שוחר שלום)", ""],
  ["11899", "שׁוֹעַ", "בעל הון, עשיר", ""],
  ["11901", "שִׁוֵּעַ", "התחנן, זעק", ""],
  ["11900", "שִׁוֵּעַ", "היה זקוק למשהו נואשות", ""],
  ["11902", "שָׁזַף", "שרף, צרב", ""],
  ["11903", "שָׁזַף", "ראה, הביט", ""],
  ["11904", "שָׁחוּחַ", "כפוף", ""],
  ["11905", "שָׁחוּן", "חם ויבש", ""],
  ["11906", "שַׁחַל", "אריה צעיר", ""],
  ["11907", "שִׁיפּוּי", "תשלום כספי (לרוב כפיצוי)", ""],
  ["11908", "שִׁיפּוּי", "ליטוש והחלקה של דבר מה בעזרת שופין, השחזה", ""],
  [
    "11909",
    "שְׂכַלְתָן",
    "אדם שחיי את חייו על פי השכל וההיגיון במקום רגשות או אמונות, רציונליסט",
    ""
  ],
  ["11910", "שָׁנָה", "עשה משהו שוב ושוב, חזר על פעולה מסוימת", ""],
  ["11911", "שָׁנָה", "למד, שינן", ""],
  [
    "11912",
    "שְׁנִיוֹנִי",
    "בעל חשיבות פחותה מעט מדבר אחר שהוא החשוב ביותר, משני",
    ""
  ],
  [
    "11913",
    "שִׁסֵּעַ",
    "קָּרָע (שִׁסֵּעַ את דברי חברו - קטע את דברי חברו)",
    ""
  ],
  ["11914", "שַׁעַל", "פסיעה, צעד", ""],
  ["11915", "שְׁתַדְלָן", "מי שמנסה לשכנע אנשים לעשות דבר מה", ""],
  ["11916", "תַּאֲנִיָּה וַאֲנִיָּה", "בכי מר על אסון", ""],
  ["11917", "תּוֹעַמְלָן", "מי שעוסק בתעמולה, מי שמפיץ מסרים ברבים", ""],
  ["11918", "תִּחוּחַ", "פירור האדמה בעזרת כלי כלשהו", ""],
  [
    "11919",
    "תִּיתוֹרָה / תִּיתוֹרֶת",
    "שולי הכובע, שוליים בולטים של חפץ כלשהו",
    ""
  ],
  ["11920", "תַּכְרִיכִים", "בדים לבנים בהם עוטפים את המתים לפני הקבורה", ""],
  ["11921", "תִּכְתּוֹבֶת", "השם לכלל המכתבים שנשלחו מאיש לרעהו", ""],
  ["11922", "תִּכְתּוֹבֶת", "החלפת מכתבים הדדית בין אדם למוסד כלשהו", ""],
  ["11923", "תִּלֵי תִּילִים", "שפע רב", ""],
  ["11924", "תְּמוּרָה", "שינוי, מפנה, תהפוכות", ""],
  ["11925", "תַּמְלִיל", "טקסט", ""],
  ["11926", "תְּנוּבָה", "יבול, תוצרת חקלאית", ""],
  ["11927", "תְּעוּדַת עֲנִיוּת", "עדות לחוסר יכולת", ""],
  ["11929", "תִּעֵל", "חפר תעלות לניקוז או השקיה", ""],
  ["11930", "תִּעֵל", "הפנה לאפיק מסוים, כִּיוֵן", ""],
  ["11928", "תַּעַל", "רפואה", ""],
  ["11931", "תַּשׁ", "הפך לחלש, התעייף", ""],
  ["11932", "תַּשְׁלִיל", "סרט צילום ממנו מפיקים את התמונות, נגטיב", ""],

  ["11934", "אִבְחָה", "ברק, נצנוץ", ""],
  ["11933", "אִבְחָה", "תנופה/הנפה מהירה של חרב", ""],
  ["11935", "אֶבֶן הָרֹאשָׁה", "מרכזי, חיוני", ""],
  ["11936", "אֶבֶן הָרֹאשָׁה", "האבן שבמרכז הקשת (בבנין)", ""],
  ["11937", "אֶבֶן נֶגֶף", "מכשול, הפרעה (נגף - מחלה)", ""],
  ["11939", "אֶבֶן פִּינָּה", "דבר יסודי, עיקרי", ""],
  ["11938", "אֶבֶן פִּינָּה", "אבן שמניחים ביסוד בניין חדש", ""],
  ["11941", "אֶבֶן שׁוֹאֶבֶת", "מגנט", ""],
  ["11940", "אֶבֶן שׁוֹאֶבֶת", "מוקד משיכה, מוקד התעניינות המושך תשומת לב", ""],
  ["11942", "אֶבֶן שָׂפָה", "האבנים שבשולי הכביש, תחילת המדרכה", ""],
  ["11943", "אַגַּב אוֹרְחָא", "דרך אגב", ""],
  ["11944", "אִדְרָה", "עצם של דג", ""],
  ["11945", "אוֹטוֹקְרַטְיָה", "שלטון יחיד", ""],
  ["11946", "אוּנְקְיָה", "יחידת משקל, כ-29 גרם", ""],
  [
    "11947",
    "אָזַר מָתְנָיו / חַלְצָיו / חַיִל",
    "התכונן למאבק (אזר - התמלא)",
    ""
  ],
  ["11948", "אַחֲרִית", "קץ, סוף, סיום", ""],
  ["11949", "אִיטֵּר (יַד יְמִינוֹ)", "שמאלי", ""],
  ["11950", "אַמְתַּחַת", "תיק, תרמיל, ילקוט, שק, נרתיק, ארנק", ""],
  ["11951", "אַסְפַּקְלַרְיָה", "מַרְאָה", ""],
  ["11952", "אַסְפַּקְלַרְיָה", "נקודת מבט, נקודת השקפה", ""],
  ["11953", "אָפוּף", "מוקף, עטוף", ""],
  ["11955", "אַפִּרְיוֹן", "חופה עשויה אריג, המשמשת בעיקר לקישוט", ""],
  ["11956", "אַפִּרְיוֹן", "כינוי לבית הקדש", ""],
  [
    "11954",
    "אַפִּרְיוֹן",
    "מושב כבוד שמעליו חופה ועליו נשאו בימי קדם אנשים נכבדים",
    ""
  ],
  ["11958", "אָצַר", "הכיל בתוכו, נשא בחובו, החזיק, הכיל", ""],
  ["11957", "אָצַר", "העלים, החביא, הסתיר", ""],
  ["11959", "אָצַר", "שמר", ""],
  ["11960", "אֲרוֹמָה", "ניחוח, ריח קל", ""],
  ["11961", "אֹרַח", "אופן התנהגות, דרך חיים, הליכות", ""],
  ["11962", "אַרְכֻּבָּה", "ידית כפופה בצורת ברך המשמשת להפעלת מכונה/מנוע", ""],
  ["11963", "אַרְכֻּבָּה", "בֶּרֶך", ""],
  ["11964", "אֲרֶשֶׁת (פָּנִים)", "הבעת פנים", ""],
  ["11965", "אַשְׁמַאי", "חוטא (לרוב בהקשר של חטא מיני)", ""],
  [
    "11966",
    "בַּד",
    "קורת עץ המשמשת לכתישת זיתים (בית בד - מקום בו כותשים את הזיתים)",
    ""
  ],
  ["11967", "בַּד", "ענף, זרד, חוטר", ""],
  ["11968", "בְּחֵרוּק שִׁינַּיִים", "בזעם וברוגז תוך איפוק עצמי, בקושי", ""],
  ["11969", "בְּיֶתֶר שְׂאֵת", "בעוצמה רבה יותר", ""],
  ["11970", "בִּכְפִיפָה אַחַת", "ביחד, באותו המקום", ""],
  ["11971", "בִּלְתִּי אֶמְצָעִי", "ללא תיווך, ישיר", ""],
  ["11972", "בִּלְתִּי נִדְלֶה", "שאינו נגמר, אינסופי", ""],
  [
    "11973",
    "בְּמֵישָׁרִין",
    "באופן ישיר ללא תיווך, בלי הקדמות, ישר ולעניין",
    ""
  ],
  ["11974", "בַּצַּר לוֹ", "בזמן מצוקתו", ""],
  ["11975", "גָּאָה", "עָלַה, התרומם", ""],
  ["11976", "גֵּב", "גומה באדמה או בסלע שבה נקווים מי גשמים", ""],
  ["11977", "גֹּב", "בּוֹר, מאורה (גוב אריות - מאורת האריות)", ""],
  ["11978", "גִּבֵּן", "בעל חטוטרת, אדם שגבו מעוקם", ""],
  ["11979", "גִּינּוּי", "מתיחת ביקורת על מעשה כלשהו", ""],
  ["11980", "גָּלְמִי / גּוֹלְמִי", "ברוטו", ""],
  ["11981", "גָּלְמִי / גּוֹלְמִי", "במצבו הטבעי לפני שעבר עיבוד, ראשוני", ""],
  [
    "11982",
    "גַּרְדוֹם",
    "במה עליה מוציאים להורג (טעות נפוצה: קַּרְדוֹם - גרזן)",
    ""
  ],
  ["11983", "גָרוּם", "רזה מאוד, בעל עצמות בולטות", ""],
  ["11984", "דְּאָבוֹן", "צער גדול", ""],
  ["11985", "דוֹמֶן", "זבל אורגני (בעיקר צואה של אדם או בעל חיים)", ""],
  ["11986", "הִבְכִּיר", "הבשיל בתחילת העונה", ""],
  ["11987", "הֲוָיָה", "מציאות, ממשות", ""],
  [
    "11988",
    "הוֹלֵל",
    "החי חיי הוללות/שובבות לא מוסרית, החי את הרגע ומרבה בתענוגות ובבילויים פרועים",
    ""
  ],
  [
    "11989",
    "הֵחִישׁ (אֶת) צְעָדָיו / פְּעָמָיו",
    "הגביר את קצב הליכתו, מיהר",
    ""
  ],
  ["11991", "הֶחְרִישׁ", "שתק, נאלם, דמם", ""],
  ["11990", "הֶחְרִישׁ", "השתיק, גרם לדממה", ""],
  ["11992", "הִכָּה בְּאֶצְבַּע צְרֵדָה", "השמיע נקישה בעזרת האמה והאגודל", ""],
  ["11993", "הִכְפִּישׁ", "דיבר בגנות", ""],
  ["11994", "הָלַך בְּדֶרֶך כָּל בָּשָר / הָאָרֶץ", "מת, נפטר", ""],
  ["11995", "הִסְכִּין", "התרגל, הסתגל", ""],
  ["11996", "הֶעֱלָה עַל נֵס", "פרסם, הפך דבר למופת, ציין לטובה", ""],
  ["11997", "הָפַךְ אֶת עוֹרוֹ", "שינה את דרך התנהגותו מקצה לקצה", ""],
  ["11998", "הִרְכִּין", "כפף, הטה כלפי מטה", ""],
  [
    "11999",
    "הִשְׁלִיך נַפְשׁוֹ מִנֶּגֶד",
    "סיכן את חייו בשביל משהו שהאמין בו",
    ""
  ],
  ["12000", "הִתְגַּלֵּעַ רִיב", "הסתכסכו, התקוטטו, התעמתו", ""],
  ["12002", "הִתְוָוה", "שרטט, רשם, סימן", ""],
  ["12001", "הִתְוָוה", "תכנן בקווים כלליים מבלי להיכנס לדקויות", ""],
  ["12003", "הִתְנַגֵּחַ", "ניהל ויכוח תקיף וחריף", ""],
  ["12004", "הִתְרִיס", "מחה, התנגד", ""],
  ["12005", "זֵד", "רשע, זדון", ""],
  ["12007", "חָבוּש", "מי ששמו עליו תחבושת", ""],
  ["12006", "חָבוּש", "כלוא, אסור", ""],
  ["12008", "חָבוּש", "מי שיש לו כיסוי על הראש", ""],
  ["12009", "חִדָלוֹן", "חורבן, הרס", ""],
  ["12010", "חוּט הַשָּׁנִי", "קו יסודי החוזר ונשנה לאורכו של עניין כלשהו", ""],
  ["12011", "חַט", "שן השנהב הבולטת של הפיל", ""],
  ["12012", "חֲטוֹטֶרֶת", "גִבְנוּן בליטה בגב, דבשת", ""],
  ["12013", "חֹסֶן", "כֹּח", ""],
  ["12014", "חֲסַר פְּנִיּוֹת", "אובייקטיבי, הגון, לא משוחד", ""],
  ["12015", "חֲפָתִים", "כפתורים לרכיסת קצה השרוול", ""],
  ["12016", "חֲפָתִים", "כינוי לקפל בשרוול", ""],
  ["12017", "חֲפָתִים", "החלק הקיצוני בשרוול", ""],
  ["12018", "חָרוֹן (אַף)", "כעס, זעם", ""],
  ["12019", "חַת", "נבהל, התמלא פחד", ""],
  ["12020", "טֶלֶף", "פרסה", ""],
  ["12021", "טְרַנְסְפוֹרְמַצְיָה", "שינוי צורה, שינוי תכונות", ""],
  ["12022", "יָצוּל", "מוט בקצה של מחרשה או עגלה שרותמים אליו את הבהמה", ""],
  ["12023", "כָּל אֵימַת שֶׁ-", "בכל זמן ש-", ""],
  ["12024", "כְּפִיפוּת", "מצבו של מי שנמצא תחת סמכותו ופיקודו של אחר", ""],
  ["12025", "כֹּפֶר", "תשלום שנדרשים לשלם על מנת להציל חיי אדם", ""],
  [
    "12026",
    "לָא מִנֵּיהּ וְלָא מִקְּצָתֵיהּ",
    "שגוי לחלוטין (לא ממנו ולא קצת ממנו)",
    ""
  ],
  ["12027", "לַבִּירִינְתְּ", "מבוך", ""],
  [
    "12028",
    "להד'ם",
    "בדיוני, ראשי התיבות של 'לא היו דברים מעולם'",
    ""
  ],
  ["12029", "לִיבְּרָה", "יחידת משקל", ""],
  ["12030", "לַיִּש", "אריה צעיר", ""],
  ["12031", "לְמַכְבִּיר", "הרבה, בשפע", ""],
  ["12032", "לְמִצְעָר", "לפחות", ""],
  ["12033", "לְמַרְאִית עַיִן", "לכאורה", ""],
  ["12034", "לָעַז", "רכילות", ""],
  ["12035", "מַאֲוַיִּים", "משיכה עזה, תאווה", ""],
  ["12036", "מְבֻדָּר", "מפוזר", ""],
  [
    "12037",
    "מֻבְלַעַת",
    "שטח אדמה שנמצא בשליטת מדינה אחת ומוקף בשטחי מדינה אחרת",
    ""
  ],
  ["12038", "מִבְּעוֹד מוֹעֵד", "בזמן", ""],
  ["12039", "מְדוּכָה", "גליל המשמש לשיגור מטעני נפץ", ""],
  ["12040", "מְדוּכָה", "קערה המשמשת לכתישה בעזרת עלי (מוט בצורת גליל)", ""],
  ["12041", "מְהַדְּרִין", "מי שמקפיד על כל פרט ופרט, דקדקן", ""],
  ["12043", "מַחַט", "עלה צר, קשה ומחודד", ""],
  ["12042", "מָחַט", "קינח (את האף)", ""],
  ["12044", "מַחְתָּה", "כלי להעברת גחלים חמים ממקום למקום", ""],
  [
    "12045",
    "מַחְתָּה",
    "כל אחד מהכלים המשמשים לטיפול האח (יעה, מגרפה וכו')",
    ""
  ],
  ["12046", "מַטְרוֹנָה / מַטְרוֹנִית", "גברת נכבדה", ""],
  ["12047", "מִיָּמִים יָמִימָה", "מזה זמן רב, מאז ומעולם", ""],
  ["12048", "מִנִּי אָז", "מאז", ""],
  ["12049", "מְסוֹבָב", "פועל יוצא, תוצאה", ""],
  ["12050", "מִקַּדְמַת דְּנָא", "מאז ומעולם, מאז ומתמיד", ""],
  ["12051", "מְרוּפָּט", "קרוע ובלוי, משומש", ""],
  ["12052", "מִשְׁבָּר", "גל", ""],
  ["12053", "מַשְׁמִים", "משעמם, שגרתי", ""],
  ["12054", "נִבְכֵי נפְשוֹ", "תוך תוכו (נאמר על אדם)", ""],
  ["12055", "נִדְרַשׁ", "עסק בעניין מסוים", ""],
  ["12056", "נְהָרָה", "אור, זוהר", ""],
  ["12057", "נַחְתּוֹם", "אופה, בעל מאפיה", ""],
  ["12058", "נִיכָּר", "נראה לעין, בולט", ""],
  ["12059", "נְסִיקָה", "התרוממות, המראה", ""],
  [
    "12060",
    "נִעֵר (אֶת) חָצְנוֹ מ-",
    "התנער מאחריות, התנער מהקשר אליו (חצנו = שולי הבגד)",
    ""
  ],
  ["12061", "נֶעְתְּקוּ מִילִּים מִפִּיו", "לא השמיע קול, שתק", ""],
  ["12062", "נַפְשׁוֹ יָצְאָה", "רצה מאוד, השתוקק", ""],
  ["12063", "סִדְקִית", "שם כולל לחפצים קטנים (ביחוד כלי תפירה)", ""],
  ["12064", "סִילּוּף", "עיוות הדברים כך שיראו בצורה שונה מן המציאות", ""],
  ["12065", "סָנַט", "לעג, הִקְנִיט, התגרה", ""],
  ["12066", "סְפּוֹנְטָנִי", "ללא תכנון", ""],
  [
    "12067",
    "עֹל",
    "זוג מוטות המחוברים לבהמה ומשמשים ככן לשאר אביזרי הרתמה",
    ""
  ],
  ["12068", "עֹל", "נטל, מעמסה", ""],
  ["12069", "עֶלֶם / עַלְמָה", "בחור צעיר / בחורה צעירה", ""],
  ["12070", "פֵּאָה", "דופן של גוף תלת-מימדי, פינה", ""],
  ["12071", "פֵּאָה", "קווּצת שיער מצידי הראש (פאות לחיים)", ""],
  ["12072", "פַּח (יָקוּשׁ)", "מלכודת, מכמונת", ""],
  ["12073", "פִּיתוֹם", "מי שמדבר מהבטן ללא הנעת השפתיים", ""],
  ["12074", "פָּכַר", "שבר, לחץ, מעך", ""],
  [
    "12075",
    "פָּכַר אֶת יָדָיו / אֶצְבְּעוֹתָיו",
    "שילב את אצבעותיו בחוזקה כביטוי לכעס או לצער רב",
    ""
  ],
  ["12076", "פְּלוּגְתָּא", "מחלוקת, ריב, חילוקי דעות", ""],
  ["12077", "פְּלוֹנִי", "מישהו בלתי ידוע, אלמוני", ""],
  ["12078", "פַּלָּצוּת", "זעזוע עמוק, פחד עז", ""],
  ["12080", "פָּרִיץ", "מושחת, חסר מוסר", ""],
  ["12079", "פָּרִיץ", "אציל, בעל אחוזה שיש לשלם לו מיסים", ""],
  ["12081", "פַּרְסָה", "חומר דמוי ציפורן המכסה את טלפי הבהמה", ""],
  ["12082", "פֵּשֶׁר", "משמעות, פירוש (חסר פשר - לא מובן)", ""],
  ["12083", "צוֹרֵף", "אמן שעוסק בעיצוב מתכות יקרות לתכשיטים, תכשיטן", ""],
  ["12084", "צַלְיָן", "עולה לרגל", ""],
  ["12085", "צָפוּד", "מקומט, יבש ומצומק, מכווץ", ""],
  [
    "12086",
    "צַרְכָנִיָּה",
    "חזות המנוהלת ע'י ארגון חברים ללא מטרת רווח (מוכרת בעיקר מזון)",
    ""
  ],
  ["12087", "קְבָס / קֶבֶס", "בחילה, גועל", ""],
  ["12088", "קִטּוּב", "ניגוד", ""],
  ["12089", "קִיפֵּחַ", "הפלה לרעה", ""],
  ["12090", "קִיפֵּחַ", "שָׁכל, איבד (קיפח את חייו - מת)", ""],
  ["12091", "קִיפֵּחַ", "גבוה מאוד, ענק", ""],
  [
    "12092",
    "קַלּוּת רֹאשׁ",
    "התייחסות מזלזלת שלא נותנת את הכבוד הראוי לנושא, קלות דעת",
    ""
  ],
  [
    "12093",
    "קְנוֹקֶנֶת",
    "איבר בצמח הדומה לחוט אשר נכרך סביב עצמים ומאפשר לצמח לטפס",
    ""
  ],
  [
    "12094",
    "קַצְרָנוּת",
    "שיטת כתיבה מקוצרת ומהירה לרישום פרוטוקולים (בעיקר בבית-משפט)",
    ""
  ],
  ["12095", "קֶרֶן יַעַר", "כלי נשיפה ממתכת", ""],
  ["12098", "רַחַשׁ", "תנועה, רִשרוש", ""],
  ["12097", "רָחַשׁ", "הרגיש, חש (רוחש כבוד - מכבד)", ""],
  ["12096", "רָחַשׁ ", "שקק, היה מלא חיים", ""],
  ["12099", "רֶמֶץ", "אפר חם שיש בו ניצוצות אש, גחלים", ""],
  ["12100", "רֶפוֹרְמָה", "שינוי יסודי ומקיף, תיקון הדברים מיסודם", ""],
  ["12101", "שָׁבִיס", "כיסוי ראש לנשים", ""],
  ["12102", "שִׁוָּוה", "הקנה לו", ""],
  ["12103", "שַׁוֵּוה בְּנַפְשֵׁך", "תאר לעצמך", ""],
  ["12104", "שְׁוֵוה נֶפֶשׁ", "אדיש", ""],
  ["12105", "שַׁחֲרִית", "שעות הבוקר, זריחה", ""],
  ["12106", "שַׁחֲרִית", "אחת משלושת התפילות שיהודי חייב בהן מדי יום", ""],
  ["12107", "שִׁנֵּס", "קשר חגורה סביב המותניים, חגר", ""],
  [
    "12108",
    "שָׁעָה",
    "התייחס, שם לב (לא בהכרח הסכים לבקשה) (להיפך: התעלם)",
    ""
  ],
  ["12109", "שָׁפוּף", "כפוף", ""],
  ["12110", "שָׁרִיר וְקַיָם", "ידוע וברור מעבר לכל ספק, ודאי", ""],
  ["12111", "שַׂרְעַפִּים", "מחשבות, הרהורים", ""],
  ["12113", "תַּבְלִיט", "סוג של פסל הבולט מהמשטח החלק עליו הוא מפוסל", ""],
  ["12112", "תַּבְלִיט", "צורת פני האדמה", ""],
  ["12114", "תּוֹחֶלֶת", "תקווה, סיכוי (חסר תוחלת - חסר סיכוי, חסר טעם)", ""],
  ["12115", "תּוֹחֶלֶת", "ממוצע משוקלל של מספר תוצאות (מוכח בסטטיסטיקה)", ""],
  ["12116", "תִּינָּה", "התאבל", ""],
  ["12117", "תִּינָה (אֶת צָרוֹתָיו)", "השמיע את צרותיו באוזני מישהו", ""],
  ["12118", "תַּמְלוּגִים", "תשלום עבור שימוש בזכויות יוצרים", ""],
  ["12119", "תַּעֲרִיף", "המחיר שיש לשלם בתמורה לדבר מה, שער", ""],
  ["12120", "תִּקְרוֹבֶת", "כיבוד", ""],

  ["12121", "אֶבֶן יְסוֹד", "דבר מרכזי במשהו, מרכז הכובד", ""],
  ["12122", "אַבְּסוּרְדִי", "חסר הגיון, שטותי, דבר שאינו מתקבל על הדעת", ""],
  ["12123", "אַדְּרַבָּה", "ההיפך הוא הנכון", ""],
  ["12124", "אַדְּרַבָּה", "למה לא? יופי!", ""],
  ["12125", "אוֹב", "קסם, כישוף", ""],
  ["12126", "אוֹטוֹרִיטָה", "סמכות הנובעת מידע בתחום מסוים", ""],
  ["12127", "אוֹרַח", "מנהג, אופן התנהגות, דרך חיים", ""],
  ["12128", "אֵין תּוֹכוֹ כְּבָרוֹ", "צבוע, שקרן", ""],
  ["12129", "אִיֵּשׁ", "הציב אנשים למילוי תפקיד מסוים", ""],
  ["12130", "אִישׁ אֶשְׁכּוֹלוֹת", "אדם הבקי בתחומים רבים ושונים", ""],
  ["12131", "אֶנִיגְמָה", "תעלומה, חידה", ""],
  ["12132", "אַפְּרַקְדָן", "שכוב על הגב", ""],
  ["12133", "אֶשְׁתָּקַד", "בשנה שעברה", ""],
  ["12134", "אֶתְנַחְתָּא", "הפסקה, פסק זמן", ""],
  ["12135", "בְּחוּבּוֹ", "בו, בתוכו", ""],
  ["12136", "בִּיזָארִי", "מוזר, לא נורמאלי", ""],
  ["12137", "בִּכָּה", "ספד, קונן והתאבל על מת", ""],
  ["12138", "בַּלְדָּר", "שליח, דוור, רץ", ""],
  ["12139", "בָּלוּי", "שחוק, מרופט, ישן וקרוע", ""],
  [
    "12140",
    "בְּנַפְשׁוֹ הַדָּבָר",
    "עבורו מדובר בדבר חיוני וגורלי בבחינת ייהרג ובל יעבור",
    ""
  ],
  ["12141", "בִּקֵּשׁ עַל נַפְשׁוֹ", "התחנן על חייו", ""],
  ["12142", "גְּדִיל", "קצוות חוטים היוצאים משולי הבגד", ""],
  ["12144", "גִּדֵם / גִּדֵעַ", "קטוע יד", ""],
  [
    "12145",
    "גֶּדֶם / גֶּדַע",
    "גזע העץ שנשאר לאחר כריתה, חלק מאיבר שנשאר לאחר קטיעה",
    ""
  ],
  ["12143", "גָּדַם / גָּדַע", "כרת, חטב, קטע", ""],
  ["12146", "גְּלִימָה", "בגד עליון ארוך", ""],
  ["12147", "גָּמַר עָלָיו אֶת הַהַלֵּל", "שיבח אותו", ""],
  ["12148", "דִּבְרֵי חֲלָקוֹת", "דברי חנופה, לשון חלקות", ""],
  ["12149", "דָּחָה אוֹתוֹ בְּקַש", "התחמק מלתת תשובה בתירוץ קלוש", ""],
  ["12150", "דֹּחַק", "צפיפות", ""],
  ["12151", "דֹּחַק", "מצב של מחסור ומצוקה", ""],
  ["12152", "דָּמַם", "השתתק, לא הוציא הגה", ""],
  ["12153", "דָּמַם", "הפסיק לזוז, נרגע, שקט", ""],
  ["12154", "הֶאֱפִיל", "החשיך, הקדיר", ""],
  ["12155", "הֶאֱפִיל", "עלה עליו ביכולתו", ""],
  ["12156", "הֲגַם שֶׁ-", "למרות שֶׁ-, אף על פי, אם כי", ""],
  ["12157", "הֵחִישׁ", "האיץ, זירז", ""],
  ["12158", "הֶחֱשָה", "לא השמיע קול, שתק, דמם לא התערב", ""],
  ["12159", "הִכְלִים", "בייש, הביך, הלבין את פניו, עלב", ""],
  ["12160", "הִכְתִּיר", "הסמיך מלך חדש", ""],
  ["12161", "הִמְרָה אֶת פִּיו", "נהג בניגוד להוראתו", ""],
  ["12162", "הֵסִיר אֶת הַלּוֹט", "חשף, גילה, פרסם (לוט - כיסוי)", ""],
  [
    "12163",
    "הִסְתּוֹפֵף",
    "שהה באופן ממושך במקום מסוים או בחברת אנשים מסוימים",
    ""
  ],
  [
    "12164",
    "הִסְתּוֹפֵף בְּצִלּוֹ",
    "היה לתלמידו (הסתופף - שהה במחיצת אנשים)",
    ""
  ],
  ["12165", "הֶעֱרָה", "גילה, חשף", ""],
  ["12166", "הֶעֱרָה", "שתה, לגם", ""],
  ["12167", "הִצְמִית", "השמיד, חיסל, החריב", ""],
  ["12168", "הִקְצָה", "ייעד משהו למטרה מסוימת, הקציב, הפריש", ""],
  ["12170", "הִרְעִים", "השמיע קול רעם, הרעשה", ""],
  ["12169", "הִרְעִים", "הרגיז, הביא לידי כעס", ""],
  ["12171", "הִתְאוֹנֵן", "התלונן", ""],
  ["12172", "הִתְגַּפֵּף", "התחבק מתוך אהבה, התרפק, התלטף", ""],
  ["12173", "הִתְנַגֵּחַ", "כשנאמר על בעל חיים: נאבק בעזרת קרניו", ""],
  ["12174", "התקין", "קבע תקנה, חוקק", ""],
  ["12175", "התקין", "הכין, סידר, יצר", ""],
  ["12176", "ווַרְיַאצְיָה", "נוסח, גירסה", ""],
  [
    "12177",
    "וִירְטוּאוֹז",
    "המצטיין בשלמות במקצועו, בעל רמת ביצוע גבוה במיוחד",
    ""
  ],
  ["12178", "זִכָּיוֹן", "זכות מיוחדת לדבר מה", ""],
  ["12179", "זַלְזַל", "ענף", ""],
  ["12180", "חָפַן", "תפס דבר מה בכף ידו", ""],
  ["12181", "חֹפֶן", "מידת נפח המסמלת את הכמות שניתן להחזיק בכף יד קמוצה", ""],
  ["12182", "חָרַץ", "שרט, עשה חריץ במשטח", ""],
  ["12183", "חָרַץ", "גזר, פסק, קבע", ""],
  ["12184", "טְפִיפָה", "הליכה בצעדים קלים תוך התנדנדות, צעידה מרחפת", ""],
  ["12185", "טַרְזָנִי ", "גנדרני, מתלבש בהידור", ""],
  ["12187", "יִעוּד", "שליחות, כוונה, תכלית", ""],
  ["12186", "יִעוּד", "הקצבת דבר או אדם למטרה מסוימת, הקדשה, יחוד", ""],
  ["12188", "יָצָא חוֹצֵץ", "התנגד בתוקף", ""],
  ["12189", "יָצָא לָשׂוּחַ", "יצא לטייל", ""],
  ["12190", "כִּילַי", "קמצן", ""],
  ["12191", "כָּמוּס", "מוסתר, חסוי, מוחבא", ""],
  ["12194", "כָּפַר", "לא הודה בהאשמות המיוחסות לו", ""],
  ["12193", "כָּפַר", "לא הסכים עם דבר מקובל", ""],
  ["12192", "כָּפַר", "התכחש לקיום האל", ""],
  ["12195", "כְּרוּב", "מלאך", ""],
  ["12196", "לָאַט", "לחש, דיבר לאט ובלחש", ""],
  ["12199", "לוֹט", "כיסוי, מעטה", ""],
  ["12198", "לוּט", "מכוסה, עטוף", ""],
  ["12197", "לוּט", "מצורף, נספח", ""],
  ["12200", "לוּט בָּעֲרָפֶל", "שאינו ברור, מעורפל", ""],
  ["12201", "לָחַך", "שרף לחלוטין, כילה", ""],
  ["12202", "לָחַך", "נגע קלות (כשנאמר על גלים, אש וכו')", ""],
  ["12203", "לָחַך", "כשנאמר על חיה: אכל עשב", ""],
  [
    "12204",
    "לְלֹא כָּחָל וְסָרָק",
    "כמו שהוא מבלי להוסיף או לגרוע, ללא קישוטים ותוספות",
    ""
  ],
  ["12205", "לְמַפְרֵע", "לאחר מעשה, על מה שהיה", ""],
  ["12206", "לְמִשְעִי", "באופן נקי ביותר, באופן חלק", ""],
  ["12207", "לִסְטִים", "שודד, גנב", ""],
  ["12208", "לִפְנַי וְלִפְנִים", "באופן יסודי ומעמיק", ""],
  ["12209", "מִבְּעוֹד מוֹעֵד", "מספיק זמן לפני, לא ברגע האחרון", ""],
  ["12210", "מִגְדָּנוֹת", "דברי מתיקה (אפשר גם: מגדנים)", ""],
  ["12211", "מָגוֹף / מְגוּפָה", "ברז, פקק, שסתום ויסות", ""],
  ["12213", "מוּבְהָק", "ניכר בברור, בולט", ""],
  ["12212", "מוּבְהָק", "אופייני, טיפוסי, ייצוגי", ""],
  ["12214", "מוּפְשָט", "ערטילאי, נטול גשמיות (להיפך: מוחשי)", ""],
  ["12215", "מִינוּת", "כפירה בדת, כפירה בעיקר", ""],
  ["12216", "מְלוּבָּן", "מובהר, מוסבר, מפורש", ""],
  ["12217", "מִנְשָר", "הצהרה, כרוז", ""],
  ["12218", "מַעֲקָשׁ", "מכשול, סיבוך", ""],
  ["12219", "מַעֲקָשׁ", "מפותל, שאינו מישורי", ""],
  ["12220", "מְשׁוֹבֵב נֶפֶשׁ", "מהנה ביותר, נעים", ""],
  ["12221", "מִשְׁנֶה", "כפול ומכופל, רב מאוד (משנה תוקף, משנה חשיבות)", ""],
  ["12222", "מִתְוֶוה", "שרטוט או רישום בקווים כלליים, סקיצה", ""],
  ["12224", "נֵאוֹת", "הסכים", ""],
  ["12223", "נָאוֹת", "מתאים, ראוי, הולם", ""],
  ["12225", "נֹגַהּ", "אור, זוהר", ""],
  ["12226", "נָד / הִתְנוֹדֵד", "נע, מתנועע", ""],
  ["12228", "נִדְבָּך", "דרגה בהתפתחותו של דבר מה, שלב", ""],
  ["12227", "נִדְבָּך", "שכבה", ""],
  ["12229", "נִטַּשׁ", "התחולל, התפשט", ""],
  ["12230", "נִכְמַר לִבּוֹ", "ריחם, חס", ""],
  ["12231", "נָסַךְ", "השרה, יצר אוירה מסויימת", ""],
  ["12232", "נָסַךְ", "התיך", ""],
  ["12233", "נָסַךְ", "יצק נוזלים לצורך פולחן דתי", ""],
  ["12234", "נִקְהַל", "נקבץ, נאסף", ""],
  [
    "12235",
    "נִקְרָה",
    "הזדמן, הגיע למקום או נפגש עם מישהו באקראי, באופן לא מתוכנן (נקרה בדרכו)",
    ""
  ],
  ["12236", "נִקְרָה", "סדק רחב בסלע או בהר, נַקִיק", ""],
  ["12237", "סְבָרָה", "דעה רווחת, השערה", ""],
  ["12238", "סוֹלְלָה", "גבעה מלאכותית מוארכת", ""],
  ["12239", "סוֹלְלָה", "יחידת תותחים", ""],
  ["12240", "סָלַד", "הרגיש דחייה כלפי משהו, נגעל", ""],
  ["12241", "סָעַד", "סייע, טיפל", ""],
  ["12242", "סָעַד (אֶת) לִבּוֹ", "אכל, השביע רעבונו", ""],
  ["12243", "סָפַק אֶת כָּפָּיו", "מחא כף מתוך צער או אכזבה", ""],
  ["12244", "עַבְדְקָן", "בעל זקן עבה ומגודל", ""],
  ["12245", "עֲבוֹט", "חפץ בעל ערך הניתן למִשכון בתמורה להלוואה, ערבון", ""],
  [
    "12246",
    "עִוְעִים",
    "תעתועים, שיגעון (רעיונות עִוְעִים - רעיונות מטורפים)",
    ""
  ],
  ["12247", "עוֹפֶר", "כשנאמר על אדם: עלם חמד, צעיר", ""],
  ["12248", "עוֹפֶר", "אייל או צבי צעיר", ""],
  ["12249", "עַמוּד הַתָּוֶוך", "דבר מרכזי במשהו, מרכז הכובד", ""],
  ["12250", "עֵרָה", "מזג, שפך נוזל", ""],
  ["12252", "עֵרָה", "חשף, גילה", ""],
  ["12251", "עֵרָה", "הרס, החריב", ""],
  ["12253", "פֹּארָה", "ענף", ""],
  ["12254", "פּוֹחֵז", "חסר אחריות, פזיז, נמהר", ""],
  ["12255", "פֶּלֶך", "כְּישוֹר, מוט המשמש לטווית חוטים", ""],
  ["12256", "פֶּלֶך", "אזור, מחוז", ""],
  ["12257", "פִּנְכָּה", "קערה, צלוחית, פלטה (מְלַחֵךְ פִּנְכָּה - מתחנף)", ""],
  ["12258", "פַּס", "עבר מן העולם, תם", ""],
  ["12259", "פָּקַד", "סָפַר, מנה", ""],
  ["12260", "פָּקַד", "ציווה, הורה", ""],
  ["12261", "פָּקַד", "בא, ביקר מישהו", ""],
  ["12262", "פַּרְסָה", "מידת אורך קדומה", ""],
  ["12263", "צָבָא", "התאסף, התקהל", ""],
  ["12264", "צוֹעֵר", "עוזר, סגן", ""],
  ["12265", "צוֹעֵר", "חניך בית ספר לקצינים", ""],
  ["12266", "צוּר", "סלע גדול", ""],
  [
    "12267",
    "צוּר מַחְצַבְתּוֹ",
    "מקורו, מולדתו, המקום שממנו בא ואליו הוא משתייך",
    ""
  ],
  ["12268", "צִיַה", "מדבר, שממון", ""],
  ["12270", "צָנוּף", "כרוך סביב הראש", ""],
  ["12269", "צָנוּף", "מגולגל, מכורבל, מכווץ", ""],
  ["12271", "צַר", "אויב, שונא", ""],
  ["12272", "צַר", "חֲבָל, מצער", ""],
  ["12273", "צַר", "לא רחב, מצומצם", ""],
  ["12274", "קַב", "מידת נפח קדומה", ""],
  ["12275", "קְדֵרָה", "כלי לבישול, סיר", ""],
  ["12276", "קוֹלֶגָה", "חבר למקצוע, עמית", ""],
  ["12277", "קָטוּם", "שנכרת ונקצץ ראשו", ""],
  ["12279", "קָם", "התעורר, פקח עינים", ""],
  ["12278", "קָם", "נבנה, יוסד", ""],
  ["12280", "קָם", "אויב", ""],
  [
    "12281",
    "קַנְקַן זָפוּת",
    "בעל זיכרון טוב (כמו קנקן שחוריו סתומים בזפת ואינו דולף)",
    ""
  ],
  ["12282", "קְסַרְקְטִין / קְסַרְקָט", "מחנה צבאי, מגורי חיילים", ""],
  ["12283", "קָרוּץ", "עשוי מ-", ""],
  ["12284", "רָבַץ לְפִתְחוֹ", "ארב לו", ""],
  ["12285", "רָבַץ לְפִתְחוֹ", "נמצא בסביבתו הקרובה", ""],
  ["12287", "רֵיאָלִי", "מציאותי", ""],
  ["12286", "רֵיאָלִי", "ישים, אפשרי", ""],
  ["12288", "רַעֲמָה", "שיער ארוך הגדל על העורף של בעל חיים", ""],
  ["12289", "רַעֲמָה", "המון שיער", ""],
  ["12290", "רָצַע", "עשה חור (מַרְצֵע - מכשיר לניקוב חורים)", ""],
  [
    "12291",
    "שָׁוְא",
    "שקר וכזב (לרוב מופיע בצירופי סמיכות כגון “תלונת שווא”)",
    ""
  ],
  ["12292", "שָׁוֶוה לְכֹל נֶפֶשׁ", "מתאים לכל אחד, פשוט", ""],
  ["12293", "שָׁוֶוה לְכֹל נֶפֶשׁ", "כל אחד יכול להרשות לעצמו, זול", ""],
  ["12294", "שֶׁנֶץ", "רצועה, שרוך", ""],
  ["12296", "שֶׁצֶף", "זרם חזק, שטף", ""],
  ["12295", "שֶׁצֶף", "כעס, זעם", ""],
  [
    "12297",
    "שְׁרִירוּת",
    "אופן התנהגותו של מי שפועל לפי גחמותיו גם אם הן פוגעות באחרים",
    ""
  ],
  ["12298", "שְׁרִירוּת לֵב", "עקשנות, התנהגות חסרת הגיון והתחשבות", ""],
  [
    "12299",
    "שְׁרִירוּתִי",
    "בנוי על דעה בלתי מבוססת או רצון עיקש שאין לו הצדקה אמיתית",
    ""
  ],
  ["12300", "שָׂרָף", "מלאך", ""],
  ["12301", "שָׂרָף", "כינוי לנחש ארסי", ""],
  ["12302", "תּוֹאֲנָה", "סיבה, תירוץ", ""],
  ["12303", "תּוֹכָחָה / תּוֹכֵחָה", "דברי מוסר, עונש", ""],
  ["12304", "תּוֹלָדָה", "תוצאה, מסקנה, דבר שהסתעף מדבר עיקרי", ""],
  ["12305", "תְּכִיפוּת", "תדירות, נפיצות", ""],
  ["12306", "תָּכַף", "התרחש לעתים קרובות, בתדירות גבוהה", ""],
  ["12307", "תָּר", "סייר, טייל", ""],
  ["12309", "תָּר", "ריגל, בלש אחרי, עקב", ""],
  ["12308", "תָּר", "חיפש, ביקש", ""],
  ["12310", "תְּשׁוּרָה", "מתנה, מנחה, שי", ""],

  ["12311", "אַבְנֵט", "חגורה רחבה", ""],
  ["12312", "אַבְקָן", "חלק בפרח המכיל אבקה המשמשת להפריית פרח", ""],
  [
    "12313",
    "אַבְרֵך",
    "כינוי לאיש צעיר, כיום נפוץ ככינוי לבחור הלומד בישיבה (אַבְרֵך - אָב בַּחָכְמָה רַךְ בְּשָׁנִים)",
    ""
  ],
  ["12314", "אוֹטוֹבִּיוֹגְרַפְיָה", "תולדות אדם הכתובות בידי עצמו", ""],
  ["12315", "אוֹמְנִיפּוֹטֶנְטִי", "כל-יכול", ""],
  ["12317", "אָזַר עֹז / כֹּחַ / אֹמֶץ", "הֵעֵז (אזר - התמלא)", ""],
  ["12318", "אָחַז אֶת הַשּׁוֹר בְּקַרְנָיו", "פנה מיד לטפל בעניין", ""],
  ["12319", "אִידֵאָל", "שיא השלמות, דבר שרוצים להשיגו", ""],
  ["12320", "אִיּוּשׁ", "הצבת אנשים במקום מסוים על מנת שיבצעו מטלה מסוימת", ""],
  ["12321", "אִינְהֶרֶנְטִי", "פנימי, טבוע (בתוך)", ""],
  ["12322", "אֵיפָה", "מידת נפח עתיקה", ""],
  ["12323", "אִישׁ מָדוֹן / מְדָנִים", "סכסכן", ""],
  ["12324", "אִכּוּל", "שריפה, בערה", ""],
  ["12325", "אֲנֶקְדּוֹטָה", "בדיחה, קוריוז", ""],
  ["12326", "אֲסוּפָה", "מקבץ מאמרים בנושא מסוים", ""],
  ["12327", "אַפְסָר", "רסן, רתמה", ""],
  ["12328", "אָקוּטִי", "מהותי, חריף, חמוּר", ""],
  [
    "12329",
    "אָרִיחַ",
    "לוח אבן בעל צורה מלבנית המשמש לכיסוי הרצפה או הקירות, מִרְצֶפֶת, בלטה",
    ""
  ],
  [
    "12330",
    "אָרִיס",
    "אדם המקבל לידיו חזקה על אדמה ומעבד אותה בתמורה לאחוז מסוים מהיבול",
    ""
  ],
  ["12316", "אָשְׁיוֹת", "מסד, בסיס, יסוד", ""],
  ["12331", "אֶשְׁכָּר", "מתנה, שי", ""],
  ["12332", "בְּחִישָׁה", "ערבוב", ""],
  ["12333", "בִּיכֵּר", "העדיף", ""],
  [
    "12334",
    "בִּכּוּרִים",
    "ראשית התבואה, היבול הראשון (פירות ראשונים ואף ספר ראשון של סופר)",
    ""
  ],
  ["12335", "בְּלִי מֵשִׂים", "בלי לשים לב, כבדרך אגב", ""],
  [
    "12336",
    "בִּלְתִּי נִלְאֶה",
    "נאמר על אדם שעושה משהו במרץ והתמדה ולא מראה סימני עייפות",
    ""
  ],
  ["12337", "בִּמְבֵּם", "פיזם מנגינה ללא מילים, זמזם, המהם", ""],
  ["12338", "בְּצַוְותָּא", "ביחד, עם עוד אנשים", ""],
  ["12339", "בַּר-חֲלוֹף", "זמני וחולף, עראי", ""],
  ["12340", "בְּרַם", "אולם, אבל, אך, ועם זאת", ""],
  ["12341", "גָבִין", "גַּבָּה (גבינים - גבות)", ""],
  [
    "12342",
    "גַּחֲמָה",
    "קפריזה, שיגעון לדבר מסוים, דחף פתאומי למשהו (גחמני = קפריזי)",
    ""
  ],
  ["12343", "גְּלָלִים", "צואת בעלי החיים", ""],
  ["12344", "גָלְמוּד", "בודד, עזוב", ""],
  [
    "12345",
    "גִנּוּנִים",
    "כללי התנהגות טקסית ומנומסת המקובלים בחברה כלשהי",
    ""
  ],
  [
    "12346",
    "גַשְׁמִי",
    "שניתן לתפיסה בעזרת החושים, חומרי, מוחשי, ממשי, ארצי",
    ""
  ],
  ["12347", "דָּא עָקָא", "זו הצרה, זו הבעיה", ""],
  ["12348", "דָּהוּי", "שצבעו נחלש והחוויר", ""],
  ["12349", "דּוֹבְרָה", "סירה להעברת משאות, רפסודה", ""],
  [
    "12350",
    "דְפוּס",
    "תבנית אופיינית להתנהגות אדם או תהליך שיטתי כלשהו, מודל, טיפוס",
    ""
  ],
  ["12351", "דֹק", "שכבה דקה, קרום, ציפוי, כיסוי, מעטה", ""],
  ["12352", "הֶאֱצִיל", "העביר סמכות לאחר, העביר תכונה לאחר", ""],
  ["12353", "הֶגְמוֹנְיָה", "שליטה, שלטון עליון, עליונות", ""],
  ["12354", "הוּדְרְכָה מְנוּחָתוֹ", "היה טרוד, משהו הפריע לו", ""],
  [
    "12355",
    "הוֹלֵך בַּתֶּלֶם",
    "הולך לפי המקובל, נוהג בדיוק כפי שציוו עליו",
    ""
  ],
  ["12356", "הֵחִיל", "העניק תוקף לחוק או תקנה", ""],
  [
    "12357",
    "הָיָה בְּעוֹכְרָיו  ",
    "הדבר היה לרעתו, הכשיל אותו",
    ""
  ],
  ["12358", "הִכְפִּיף", "שם מישהו תחת מרותו של מישהו אחר", ""],
  ["12359", "הַלָּה", "ההוא, המדובר", ""],
  ["12360", "הִלִּין", "התלונן", ""],
  ["12361", "הַלְמוּת", "דפיקה בקצב קבוע, מכה, נקישה", ""],
  ["12362", "הִלְעִיז", "השמיץ, פרסם שקרים הפוגעים בשמו של מישהו", ""],
  ["12364", "הִנְפִּיק", "המציא ומסר לידי מישהו תעודה רשמית כלשהי", ""],
  [
    "12363",
    "הִנְפִּיק",
    "הוציא שטרי כסף או איגרות חוב לציבור הרחב (לרוב נאמר על בנק)",
    ""
  ],
  ["12365", "הִסְתַּיֵּג", "לא הסכים עם משהו, טען נגד, דחה", ""],
  ["12366", "הִשְׁלִיך יְהָבוֹ עַל", "תלה תקוותו ב-", ""],
  ["12368", "הִתְבַּדְּרוּת", "התפזר (שיערה התבדר ברוח)", ""],
  ["12367", "הִתְבַּדְּרוּת", "השתעשע (מהמילה בִּידוּר)", ""],
  ["12369", "הִתְחַקָּה", "עקב אחריו, ריגל", ""],
  ["12370", "הִתְחַקָּה", "ניתח ובחן, חקר", ""],
  ["12371", "הִתְלַהֵם", "התכתש, השתולל, התנהג בתוקפנות", ""],
  ["12372", "הִתְנוֹדֵד", "נע מצד לצד בחוסר יציבות", ""],
  ["12373", "הִתְנוֹפֵף", "התנועע ברוח מצד לצד", ""],
  [
    "12374",
    "הִתְקַשֵּׁט בְּנוֹצוֹת זָרִים",
    "התפאר בדבר מה שלא הוא עשה, או בדבר שאינו שלו",
    ""
  ],
  ["12375", "זִבְדָה", "שמנת", ""],
  ["12376", "זְמָם", "מחסום ששמים בפי בהמה בכדי למנוע ממנה לאכול או לנשוך", ""],
  ["12377", "חֲגָוִים", "נקיקים, סדקים, חריצים", ""],
  ["12378", "חֲוִילָה", "ווילה, קוטג'", ""],
  ["12379", "חוֹסֶה", "מי שנמצא במוסד טיפולי (בשל נכות, זקנה וכו')", ""],
  ["12380", "חִיוַּורְיָין", "מעט חיוור, לבנבן", ""],
  ["12381", "חִיל (וּרְעָדָה)", "פחד גדול, אימה", ""],
  ["12382", "חַכְלִילִי", "אדמדם, אדמוני, סמוק", ""],
  ["12383", "חָמָס", "שוד, גניבה, גזל", ""],
  ["12384", "חָרוּל", "קוץ", ""],
  ["12385", "חֲרוּשׁ", "שיש בו, שיש בו סימנים של (חרוש קמטים, חרוש דאגה)", ""],
  ["12386", "חֲרִיץ", "נתח, חתיכה, פרוסה (חָרִיץ גְבִינָה - חתיכת גבינה)", ""],
  ["12387", "חִשּׁוּל", "חיזוק אדם מבחינה מנטאלית, חיסון, הקשחה", ""],
  ["12388", "חִשּׁוּל", "עיצוב תכונות המתכת על ידי ליבונה", ""],
  ["12389", "טָרוּף", "חסר שקט (שינה טרופה - שינה לא שלווה)", ""],
  ["12390", "יְהָב", "תקווה, סיכוי (השליך יהבו על מישהו - תלה בו תקוות)", ""],
  ["12391", "יוֹרֵד יָם", "יַמַּאי, סַפָּן, מַלָּח", ""],
  ["12392", "כּוֹחוֹ יָפֶה", "יש לו תוקף", ""],
  ["12393", "כִּיּוּר", "חריטה על הקיר לשם נוי", ""],
  ["12394", "כִּיּוּר", "עיצוב צורות מחומר רך כלשהו (פלסטלינה, חמר.. . )", ""],
  ["12395", "כִּינוּן", "הקמה, ביסוס", ""],
  ["12396", "כְּלוֹנָס", "מוט, קורה ארוכה ודקה", ""],
  ["12397", "כִּמֵּת", "מלשון כמות - ביצע מדידת כמות, מדד", ""],
  ["12398", "כִּתֵּר", "הקיף מכל הצדדים", ""],
  ["12399", "לְאַלְתַּר", "באופן מיידי, על המקום, תכף ומיד", ""],
  ["12400", "לִבָּה אֶת הַיְּצָרִים", "עורר סערת רגשות, הלהיט את הרוחות", ""],
  ["12402", "לִבְלוּב", "התפתחות מבטיחה של רגשות חיוביים", ""],
  ["12401", "לִבְלוּב", "פריחת ניצנים (להיפך: קמל)", ""],
  ["12403", "לְמֵישָׁרִין", "כמו שצריך, ללא כל בעיה", ""],
  ["12404", "לְסֵרוּגִין", "פעם כך ופעם אחרת, לא באופן רציף", ""],
  [
    "12405",
    "מַבְכִּירָה ",
    "אישה או בהמה המביאה לעולם את צאצאה הראשון (הבכור)",
    ""
  ],
  ["12406", "מִגְבַּעַת", "כובע רחב שוליים", ""],
  ["12407", "מַדְוֶה", "כאב", ""],
  ["12408", "מַדְמֵנָה", "מזבלה, אדמה בוצית", ""],
  [
    "12409",
    "מַהֲדִיר",
    "מי שמוציא ספר ישן במהדורה חדשה, מי שעורך ספר לדפוס",
    ""
  ],
  ["12411", "מוֹעֵד", "חג", ""],
  ["12410", "מוֹעֵד", "זמן", ""],
  ["12412", "מוֹרֶך (לֵב)", "חולשה, פחד", ""],
  ["12413", "מִזְכָּר", "מכתב קצר ולא רשמי בנוגע לעניין מסוים", ""],
  [
    "12414",
    "מַחְמָל",
    "דבר המעורר חמלה ואהבה (מַחְמַל נַפְשׁוֹ = יקירו, אהובו)",
    ""
  ],
  ["12416", "מְטוּרְזָן", "הדור בלבושו, מגונדר", ""],
  ["12415", "מְטֻלָּל", "רטוב מטל", ""],
  ["12417", "מְכוּרְכָּם", "בצבע צהוב-כתום (כצבע הצמח כורכום)", ""],
  ["12418", "מִלְטָשָׁה", "בית מלאכה לליטוש שיש, אבני חן וכו'", ""],
  ["12419", "מִסְדָּר", "התאחדות של אנשים על יסוד רקע משותף", ""],
  ["12420", "מַסָּה ", "חיבור עיוני קצר", ""],
  ["12421", "מָש", "זז, נע", ""],
  ["12422", "מַשְׂאַת לֵב / נֶפֶש", "שאיפתו ותקוותו של אדם, חלום", ""],
  ["12423", "מִשֶּׁכְּבָר הַיָּמִים", "מלפני זמן רב", ""],
  ["12425", "מַתְמִיהַּ", "מעורר פליאה, מדהים", ""],
  ["12424", "מַתְמִיהַּ", "מוזר, תמוה", ""],
  ["12426", "נֶאֱלָח", "מתועב, בזוי", ""],
  ["12427", "נָגַשׂ", "דיכא, שיעבד", ""],
  ["12428", "נָגַשׂ", "דרש שהנושה (האדם שחייב כסף) יחזיר את חובו", ""],
  ["12429", "נוֹחַ לַבְּרִיּוֹת", "שקל להסתדר איתו, טוב מזג", ""],
  ["12430", "נוֹפֶך", "אחת מאבני החושן", ""],
  [
    "12431",
    "נוֹפֶך",
    "תוספת קלה ועדינה למשמעות או למראה של דבר מה, ניואנס",
    ""
  ],
  ["12432", "ניוּאַנְס", "הבדל דק", ""],
  ["12433", "נִיחָר", "יבש, בעיקר בהקשר של גרון יבש מדיבור", ""],
  ["12434", "נִכְסָף", "דבר רצוי מאוד, שמשתוקקים אליו, נחשק", ""],
  ["12435", "נִפְרַע", "נקם (במישהו)", ""],
  ["12436", "נִפְרַע", "שולם", ""],
  ["12437", "נִצַּחַת", "נחרצת, מכרעת", ""],
  ["12438", "סִדּוּר", "ספר המכיל את התפילות", ""],
  ["12439", "סָדוּר", "מסודר, מאורגן", ""],
  ["12440", "סוּרֵיאָלִיסְטִי", "לא מציאותי", ""],
  ["12441", "סָמוּך עַל שוּלְחָנוֹ", "נהנה מחסדיו, נתמך על ידו", ""],
  ["12442", "סָמַךְ יָדוֹ על (משהו)", "אישר, הסכים", ""],
  ["12443", "סָר מֵרַע", "מי שנמנע מלחטוא", ""],
  ["12444", "סְרָק", "ריקנות, חוסר תועלת (רעיון סרק - רעיון בלתי מעשי)", ""],
  ["12445", "עָלָה בְּקָנֶה אֶחָד", "תאם, השתלב", ""],
  ["12446", "עָמִיל", "סוכן", ""],
  ["12447", "עֲנָק", "תכשיט לצוואר, מחרוזת", ""],
  [
    "12448",
    "עַרְכָּאָה",
    "שם לדרגה בבית המשפט (שלום, מחוזי או עליון), שלב, רמה",
    ""
  ],
  ["12449", "פַּג", "תינוק שנולד לפני הזמן המיועד", ""],
  ["12450", "פָּג", "התנדף, הסתיים (פג תוקף - עבר התוקף, לא בתוקף), נעלם", ""],
  [
    "12451",
    "פּוֹרֵק עֹל",
    "מי שנוהג בהפקרות ואינו מקבל מרות, כללים וחוקים",
    ""
  ],
  ["12452", "פִּזּוּר דַּעַת / נֶפֶש", "בלבול, חוסר ריכוז", ""],
  ["12453", "פִּכָּה", "התפרץ וזרם החוצה, נבע", ""],
  ["12454", "פְּעָמָיו", "צעדיו (פַּעַם=צעד=שַׁעַל)", ""],
  ["12455", "פָּרָדוֹקְס", "סתירה פנימית", ""],
  ["12456", "פְּרִישוּת", "התנזרות מתענוגות, חיי צניעות", ""],
  ["12457", "פֶּרְמָנֶנְטִי", "קבוע", ""],
  ["12458", "פָּרַף", "סגר באמצעות כפתורים או רוכסן", ""],
  ["12459", "פַּשְׁטָנִי", "שטחי ולא מעמיק, רדוד", ""],
  ["12460", "צָבָה", "תפח, התנפח", ""],
  [
    "12461",
    "צֶדַע / צְדָעַיִם / צְדָעוֹת",
    "רקה/רקות, כל אחד מצדי המצח ליד האוזן",
    ""
  ],
  ["12462", "צוֹנֵן", "קריר, לא חם", ""],
  ["12463", "צָלַח", "הצליח", ""],
  ["12464", "צָלַח", "חצה תעלת מים או נהר", ""],
  ["12465", "צְנִיפָה", "צהלת הסוס", ""],
  ["12466", "קָבַל", "התלונן, רטן", ""],
  ["12467", "קֳבָל עַם וְעֵדָה", "בגלוי, לעיני כל", ""],
  ["12468", "קוּנְדֵּסוּת", "מעשה שובבות", ""],
  ["12469", "קִילּוּחַ", "זרם דק, טפטוף", ""],
  ["12470", "קַל דַּעַת", "נמהר, פזיז", ""],
  ["12471", "קַל תְּפִיסָה", "כינוי למי שמבין דברים במהירות", ""],
  [
    "12472",
    "קָפַח ",
    "הכה, הלם (שמש קופחת - שמש מחממת מאוד, שמש 'מכה')",
    ""
  ],
  ["12473", "קְרֵי", "דהיינו, כלומר", ""],
  ["12474", "קָרַם עוֹר וְגִידִים", "החל להיעשות ממשי, התממש", ""],
  ["12475", "קָשִׁישׁ", "זקן מאוד, ישיש", ""],
  ["12476", "רְבָבָה", "עשרת אלפים", ""],
  ["12477", "רָמָה / עָלְתָה קַרְנוֹ", "נחל הצלחה וזכה לכבוד", ""],
  ["12478", "רִפְרֵף", "ריחף, גלש", ""],
  [
    "12479",
    "רִפְרֵף",
    "בחן משהו באופן שִטחי ומהיר מבלי להתעמק בו, העיף מבט",
    ""
  ],
  ["12480", "שְׁאֵר בָּשָׂר", "קרוב, בן משפחה", ""],
  ["12481", "שׁוּמָה", "בליטה בגוף (דומה לנקודת חן גדולה)", ""],
  ["12482", "שׁוּמָה", "הערכה, אומדן (פקיד שומה - פקיד מס)", ""],
  ["12483", "שְׁכִיב מְרַע", "חולה אנוש, גוסס", ""],
  ["12484", "שָׂם נַפְשוֹ בְּכַפּוֹ", "סיכן את עצמו", ""],
  ["12485", "שָׁנוּן", "חריף, פיקח", ""],
  ["12486", "שָׁנוּן", "חד, מושחז", ""],
  ["12487", "שָנַק / שִנֵק", "חנק", ""],
  ["12488", "שִׁעוּר", "גודל, מידה", ""],
  [
    "12489",
    "שִׁפּוּל",
    "תחתית, החלק הנמוך (שיפולי ההרים - המדרונות התחתיים)",
    ""
  ],
  ["12490", "שָׁת", "שם, הניח", ""],
  ["12492", "תִּמּוּכִין", "מקור מוסמך כתוב, אסמכתא", ""],
  ["12491", "תִּמּוּכִין", "גיבוי, חיפוי", ""],
  ["12493", "תָּמִיר", "גבוה, זקוף", ""],
  ["12494", "תַּעַר", "סכין גילוח", ""],
  ["12495", "תָּפֵל", "סתמי, חסר משמעות", ""],
  ["12496", "תָּפֵל", "חסר טעם עקב מחסור בתבלינים, סר טעם", ""],
  [
    "12497",
    "תֶּקֶר",
    "חור בצמיג הגורם לבריחת האוויר ממנו ולהשבתת הגלגל, פנצ'ר",
    ""
  ]
];

module.exports = words;
